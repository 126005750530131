import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';

/* THIS FILE IS AUTO-GENERATED */
/* DO NOT EDIT */
/* eslint-disable */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type AdditionalInfo = {
  __typename?: 'AdditionalInfo';
  memberId: Maybe<Scalars['Float']['output']>;
};

export type AdditionalInfoInput = {
  schema?: InputMaybe<Scalars['JSON']['input']>;
  uiSchema?: InputMaybe<Scalars['JSON']['input']>;
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String']['output'];
  address2: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  county: Scalars['String']['output'];
  postalCode: Maybe<Scalars['String']['output']>;
  town: Scalars['String']['output'];
};

export type AddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  county: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  town: Scalars['String']['input'];
};

/** Android gradle tasks, assemble for APK or bundle for AAB */
export enum AndroidGradleTasks {
  assemble = 'assemble',
  bundle = 'bundle'
}

export type AppConfig = {
  __typename?: 'AppConfig';
  apnToken: Maybe<Scalars['String']['output']>;
  apnTokenKeyId: Maybe<Scalars['String']['output']>;
  appWelcomeText: Maybe<Scalars['String']['output']>;
  appleAppId: Maybe<Scalars['String']['output']>;
  appleCompanyName: Maybe<Scalars['String']['output']>;
  appleContentProviderId: Maybe<Scalars['String']['output']>;
  appleDeveloperTeamId: Maybe<Scalars['String']['output']>;
  appleTeamName: Maybe<Scalars['String']['output']>;
  appstoreConnectApiKey: Maybe<Scalars['String']['output']>;
  appstoreConnectKeyId: Maybe<Scalars['String']['output']>;
  fcmId: Maybe<Scalars['String']['output']>;
  firebaseAndroidAppId: Maybe<Scalars['String']['output']>;
  firebaseIosAppId: Maybe<Scalars['String']['output']>;
  firebaseProjectId: Maybe<Scalars['String']['output']>;
  firebaseServiceKey: Maybe<Scalars['String']['output']>;
  gradleTask: Maybe<AndroidGradleTasks>;
  legacyFcmId: Maybe<Scalars['String']['output']>;
  legacyFirebaseAndroidAppId: Maybe<Scalars['String']['output']>;
  legacyFirebaseProjectId: Maybe<Scalars['String']['output']>;
  legacyFirebaseServiceKey: Maybe<Scalars['String']['output']>;
  newKeyStore: Maybe<Scalars['Boolean']['output']>;
  packageIdentifier: Maybe<Scalars['String']['output']>;
  packageName: Maybe<Scalars['String']['output']>;
  playStoreServiceKey: Maybe<Scalars['String']['output']>;
  sha256CertFingerprints: Maybe<Scalars['String']['output']>;
};

export type Article = {
  __typename?: 'Article';
  banner: Maybe<Scalars['String']['output']>;
  club: Club;
  content: Scalars['String']['output'];
  coverImageSource: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated The excerpt field is no longer used in the superadmin and in the application from version 1.28.0 */
  excerpt: Scalars['String']['output'];
  facebookPostId: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  instagramPostId: Maybe<Scalars['String']['output']>;
  pinned: Maybe<Scalars['Boolean']['output']>;
  publishType: Maybe<PublishType>;
  published: Scalars['Boolean']['output'];
  publishedAt: Scalars['DateTime']['output'];
  schedules: Array<Schedule>;
  socialMediaShareSettings: SocialMediaShareArticleSettings;
  team: Maybe<Team>;
  title: Scalars['String']['output'];
  twitterTweetId: Maybe<Scalars['String']['output']>;
  viewedAlready: Scalars['Boolean']['output'];
};

export type ArticleInput = {
  content: Scalars['String']['input'];
  coverImageSource: Scalars['String']['input'];
  pinned?: InputMaybe<Scalars['Boolean']['input']>;
  publishType: PublishType;
  published: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type ArticleSearchDateRange = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ArticleView = {
  __typename?: 'ArticleView';
  user: User;
};

export type AttendeeList = {
  __typename?: 'AttendeeList';
  additionalInfo: Maybe<Scalars['String']['output']>;
  additionalInfoRaw: Maybe<Scalars['JSON']['output']>;
  price: Scalars['Float']['output'];
  purchaseDate: Scalars['String']['output'];
  purchaserEmail: Scalars['String']['output'];
  purchaserFirstName: Scalars['String']['output'];
  purchaserLastName: Scalars['String']['output'];
  ticketHolderFirstName: Scalars['String']['output'];
  ticketHolderLastName: Scalars['String']['output'];
  ticketHolderPhoneNumber: Scalars['String']['output'];
  ticketType: Scalars['String']['output'];
};

/** Types of audience group to which notification can be sent */
export enum AudienceGroup {
  all = 'all',
  expiredTicket = 'expiredTicket',
  ticketForLastRound = 'ticketForLastRound',
  withoutTicketForFutureDraws = 'withoutTicketForFutureDraws'
}

export type AudienceOptions = {
  __typename?: 'AudienceOptions';
  excludeOneTimePlayers: Scalars['Boolean']['output'];
};

export type AudienceOptionsInput = {
  excludeOneTimePlayers: Scalars['Boolean']['input'];
};

export type AutocompleteItem = {
  __typename?: 'AutocompleteItem';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type Badge = {
  __typename?: 'Badge';
  count: Scalars['Float']['output'];
  route: MobileScreens;
};

export type BlacklistedContactData = {
  __typename?: 'BlacklistedContactData';
  contact: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
};

export type BlacklistedContactDataWithType = {
  channel: Channel;
  contact: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type Bookmark = {
  __typename?: 'Bookmark';
  club: Club;
  icon: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BookmarkInput = {
  icon: Scalars['String']['input'];
  iconName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Float']['input']>;
  label: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type Branding = {
  __typename?: 'Branding';
  appIconBackgroundColor: Maybe<Scalars['String']['output']>;
  appScheme: Maybe<Scalars['String']['output']>;
  appleAppId: Maybe<Scalars['String']['output']>;
  clubName: Scalars['String']['output'];
  codeOfConductUrl: Maybe<Scalars['String']['output']>;
  facebookPixelId: Maybe<Scalars['String']['output']>;
  features: FeaturesWithClosedFundraiser;
  id: Scalars['Float']['output'];
  logo: Maybe<Scalars['String']['output']>;
  lottoRulesUrl: Maybe<Scalars['String']['output']>;
  mainColor: Scalars['String']['output'];
  packageName: Maybe<Scalars['String']['output']>;
  primaryColor: Scalars['String']['output'];
  privacyPolicyUrl: Maybe<Scalars['String']['output']>;
  suffix: Scalars['String']['output'];
  termsOfServiceUrl: Maybe<Scalars['String']['output']>;
  timezone: Timezone;
};

export type BugReportMessage = {
  body: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type BuildDetails = {
  branch: Scalars['String']['input'];
  buildNotes: Scalars['String']['input'];
  clubId: Scalars['Float']['input'];
  platform: MobileAppBuildPlatform;
};

/** Filter app build versions */
export enum BuildVersionFilter {
  inProgress = 'inProgress',
  latest = 'latest',
  notStarted = 'notStarted',
  old = 'old'
}

export type BulkActionInput = {
  action: MobileAppBuildBulkAction;
};

export type BulkTicketInfo = {
  drawsLeft: Scalars['Float']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  numbers: Array<Scalars['Float']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type BulkUploadArg = {
  input: Array<BulkTicketInfo>;
};

export type BundledFundraiser = {
  __typename?: 'BundledFundraiser';
  name: Scalars['String']['output'];
  ticketTypeName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type BundledFundraiserData = {
  __typename?: 'BundledFundraiserData';
  fundraiserName: Scalars['String']['output'];
  fundraisingType: Scalars['String']['output'];
  ownerEmail: Scalars['String']['output'];
  ticketTypeId: Scalars['Float']['output'];
};

export type BuyTicketCombinations = {
  combinations: Array<Array<Scalars['Int']['input']>>;
};

export type Cart = {
  event?: InputMaybe<CartEvent>;
  lotto?: InputMaybe<CartLotto>;
  membership?: InputMaybe<CartMembership>;
  raffle?: InputMaybe<CartRaffle>;
};

export type CartEvent = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['Float']['input'];
  tickets: Array<EventTicketToPurchase>;
};

export type CartLotto = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  combinations: Array<Array<Scalars['Int']['input']>>;
  ticketTypeId: Scalars['Float']['input'];
};

export type CartMembership = {
  membershipTypeId: Scalars['Float']['input'];
  subtypes?: InputMaybe<Array<GroupMembershipCart>>;
};

export type CartRaffle = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  ticketTypeId: Scalars['Float']['input'];
};

export type ChangeMembershipTypeActiveInput = {
  active: Scalars['Boolean']['input'];
};

/** Types of channels where users can be reached */
export enum Channel {
  email = 'email',
  push = 'push',
  sms = 'sms'
}

export type Club = {
  __typename?: 'Club';
  appConfig: AppConfig;
  articles: Array<Article>;
  blacklistedContacts: Array<NotificationBlacklist>;
  bookmarks: Maybe<Array<Bookmark>>;
  clubType: Clubtype;
  codeOfConductUrl: Maybe<Scalars['String']['output']>;
  colors: Colors;
  contactEmail: Maybe<Scalars['String']['output']>;
  country: Country;
  eventTicketSalesData: ClubSalesData;
  events: Array<Event>;
  facebookPixelId: Maybe<Scalars['String']['output']>;
  forms: Array<Form>;
  fundraisers: Array<Fundraiser>;
  groups: Array<Group>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  lastMembershipExportDate: Maybe<Scalars['DateTime']['output']>;
  lastUnregisteredMembersNotificationDate: Maybe<Scalars['DateTime']['output']>;
  logoSource: Maybe<Scalars['String']['output']>;
  lottoRulesAppleUrl: Maybe<Scalars['String']['output']>;
  lottoRulesUrl: Maybe<Scalars['String']['output']>;
  lottoSalesData: ClubSalesData;
  memberSiteUrl: Scalars['String']['output'];
  membershipSalesData: ClubSalesData;
  membershipTypes: Array<MembershipType>;
  mobileBuilds: Array<MobileBuild>;
  name: Scalars['String']['output'];
  payments: Array<Payment>;
  payouts: Array<Payout>;
  privacyPolicyUrl: Scalars['String']['output'];
  raffleSalesData: ClubSalesData;
  schedules: Array<Schedule>;
  settings: Settings;
  shortName: Maybe<Scalars['String']['output']>;
  signupDate: Scalars['DateTime']['output'];
  smsMessages: Array<SmsMessage>;
  socialMediaConnections: Maybe<Array<SocialMediaConnection>>;
  sponsorships: Maybe<Array<Sponsorship>>;
  statementDescriptor: Maybe<Scalars['String']['output']>;
  status: ClubStatus;
  stripeConfig: StripeConfig;
  suffix: Scalars['String']['output'];
  termsOfServiceAppleUrl: Scalars['String']['output'];
  termsOfServiceUrl: Scalars['String']['output'];
  timeZone: Timezone;
  tixserveSecret: Maybe<Scalars['String']['output']>;
  tixserveServerKey: Maybe<Scalars['String']['output']>;
  twilioSubAccountId: Maybe<Scalars['String']['output']>;
  users: Array<User>;
};


export type ClubeventTicketSalesDataArgs = {
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ClublottoSalesDataArgs = {
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ClubmembershipSalesDataArgs = {
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ClubraffleSalesDataArgs = {
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ClubContactMessage = {
  body: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type ClubInput = {
  appIconBackgroundColor: Scalars['String']['input'];
  clubType?: InputMaybe<Clubtype>;
  contactEmail: Scalars['String']['input'];
  countryId: Scalars['Float']['input'];
  facebookPixelId?: InputMaybe<Scalars['String']['input']>;
  logoSource: Scalars['String']['input'];
  mobileApp?: InputMaybe<MobileAppInput>;
  name: Scalars['String']['input'];
  primaryColor: Scalars['String']['input'];
  scheme: ColorScheme;
  secondaryColor: Scalars['String']['input'];
  settings: InputSettings;
  shortName?: InputMaybe<Scalars['String']['input']>;
  statementDescriptor?: InputMaybe<Scalars['String']['input']>;
  suffix: Scalars['String']['input'];
  timeZone: Timezone;
  tixserveSecret?: InputMaybe<Scalars['String']['input']>;
  tixserveServerKey?: InputMaybe<Scalars['String']['input']>;
};

export type ClubListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ClubListPaginationOrder>;
  orderDirection?: InputMaybe<OrderDirection>;
};

/** The possible variations of the "order" prop when listing a club */
export enum ClubListPaginationOrder {
  name = 'name',
  signupDate = 'signupDate',
  status = 'status'
}

export type ClubOwnerInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type ClubSalesData = {
  __typename?: 'ClubSalesData';
  month: Maybe<SalesData>;
  week: Maybe<SalesData>;
  year: Maybe<SalesData>;
};

/** Club onboarding status */
export enum ClubStatus {
  completed = 'completed',
  invited = 'invited',
  loggedIn = 'loggedIn'
}

/** It can be changed in the superadmin portal at "2. features" tab */
export enum Clubtype {
  basketball = 'basketball',
  gaaLgfaCamogie = 'gaaLgfaCamogie',
  louthGAA = 'louthGAA'
}

export type CodeOfConductData = {
  __typename?: 'CodeOfConductData';
  fileUrl: Maybe<Scalars['String']['output']>;
  lastModified: Maybe<Scalars['DateTime']['output']>;
};

/** It can be changed in the superadmin portal by the toggle switch at "3. brand" tab */
export enum ColorScheme {
  primary = 'primary',
  secondary = 'secondary'
}

export type Colors = {
  __typename?: 'Colors';
  appIconBackgroundColor: Maybe<Scalars['String']['output']>;
  primary: Scalars['String']['output'];
  scheme: Maybe<ColorScheme>;
  secondary: Scalars['String']['output'];
};

export type CombinationInput = {
  winningNumbers: Array<Scalars['Float']['input']>;
};

export type ContactDetails = {
  __typename?: 'ContactDetails';
  deviceTokens: Array<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  type: ContactType;
};

export type ContactDetailsBlacklisted = {
  __typename?: 'ContactDetailsBlacklisted';
  deviceTokens: Maybe<Array<Scalars['String']['output']>>;
  email: Maybe<BlacklistedContactData>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Maybe<BlacklistedContactData>;
  type: ContactType;
};

export type ContactDetailsPaginationInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Order>;
};

/** The possible variations of the list contact details query "order" prop */
export enum ContactOrderByParams {
  email = 'email',
  name = 'name',
  phoneNumber = 'phoneNumber',
  type = 'type'
}

/** Places where the contact detail can come from */
export enum ContactType {
  member = 'member',
  player = 'player',
  user = 'user'
}

export type Country = {
  __typename?: 'Country';
  clubs: Array<Club>;
  currency: Currency;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  twilioConfig: Maybe<TwilioConfig>;
  vat: Scalars['Float']['output'];
};

export type CreateEventTicketData = {
  color: TicketColor;
  id?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  tixserveTicketTypeCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEventTicketInput = {
  eventTicketTypes: Array<CreateEventTicketData>;
  maxTickets: Scalars['Float']['input'];
  ticketingType?: InputMaybe<TicketingType>;
  tixserveEventCode: Scalars['String']['input'];
};

export type CreateFormInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  schema: Scalars['JSON']['input'];
  uiSchema: Scalars['JSON']['input'];
};

export type CreateGroupInput = {
  name: Scalars['String']['input'];
  users: Array<GroupUserInput>;
};

export type CreateGroupMembershipInput = {
  active: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  enableInstalmentPayment?: InputMaybe<Scalars['Boolean']['input']>;
  fundraiserTicketTypeId?: InputMaybe<Scalars['String']['input']>;
  includeFundraiserTicket?: InputMaybe<Scalars['Boolean']['input']>;
  interest?: InputMaybe<Scalars['Float']['input']>;
  maxGroupSize: Scalars['Float']['input'];
  minGroupSize: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfInstalments?: InputMaybe<Scalars['Float']['input']>;
  publishType: PublishType;
  types: Array<CreateGroupMembershipSubtypeInput>;
  validFrom: Scalars['DateTime']['input'];
  validTo: Scalars['DateTime']['input'];
};

export type CreateGroupMembershipSubtypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formId?: InputMaybe<Scalars['Float']['input']>;
  maxNumberOfMembers: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  orderNumber: Scalars['Float']['input'];
  prices: Array<Scalars['Float']['input']>;
  typeGAA?: InputMaybe<TypeGAA>;
};

export type CreateMembershipTypeInput = {
  active: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  enableInstalmentPayment?: InputMaybe<Scalars['Boolean']['input']>;
  enableRenewableMembership?: InputMaybe<Scalars['Boolean']['input']>;
  formId?: InputMaybe<Scalars['Float']['input']>;
  fundraiserTicketTypeId?: InputMaybe<Scalars['String']['input']>;
  includeFundraiserTicket?: InputMaybe<Scalars['Boolean']['input']>;
  interest?: InputMaybe<Scalars['Float']['input']>;
  linkMembershipTypeIds: Array<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfInstalments?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  publishType: PublishType;
  renewableMembershipDefault?: InputMaybe<Scalars['Boolean']['input']>;
  typeGAA?: InputMaybe<TypeGAA>;
  validFrom: Scalars['DateTime']['input'];
  validTo: Scalars['DateTime']['input'];
};

/** Currency to be used in all entities */
export enum Currency {
  AUD = 'AUD',
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD'
}

export type CurrentFundraiserRoundSales = {
  __typename?: 'CurrentFundraiserRoundSales';
  count: Scalars['Float']['output'];
  sum: Scalars['Float']['output'];
};

export type CustomerCard = {
  __typename?: 'CustomerCard';
  brand: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  expireMonth: Scalars['Float']['output'];
  expireYear: Scalars['Float']['output'];
  funding: Scalars['String']['output'];
  hasSubscribedItems: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastFour: Scalars['String']['output'];
  purchases: Maybe<Array<RecurringPurchase>>;
  tickets: Maybe<Array<FundraiserTicket>>;
  user: Maybe<User>;
};

export type DeviceId = {
  __typename?: 'DeviceId';
  deviceToken: Scalars['String']['output'];
  user: User;
};

export type EditGroupInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<GroupUserInput>>;
};

export type EditGroupMembershipInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableInstalmentPayment?: InputMaybe<Scalars['Boolean']['input']>;
  includeFundraiserTicket?: InputMaybe<Scalars['Boolean']['input']>;
  interest?: InputMaybe<Scalars['Float']['input']>;
  maxGroupSize?: InputMaybe<Scalars['Float']['input']>;
  minGroupSize?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfInstalments?: InputMaybe<Scalars['Float']['input']>;
  publishType?: InputMaybe<PublishType>;
  subtypes: Array<EditGroupMembershipSubtypeInput>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EditGroupMembershipSubtypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formId?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxNumberOfMembers: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  orderNumber: Scalars['Float']['input'];
  prices: Array<Scalars['Float']['input']>;
  toActivate?: InputMaybe<Scalars['Boolean']['input']>;
  toDelete?: InputMaybe<Scalars['Boolean']['input']>;
  toInactivate?: InputMaybe<Scalars['Boolean']['input']>;
  typeGAA?: InputMaybe<TypeGAA>;
};

export type EditLottoInput = {
  additionalInfoInput?: InputMaybe<FundraiserAdditionalInfoInput>;
  drawDate: Scalars['DateTime']['input'];
  jackpotAmount: Scalars['Float']['input'];
  matchThreeNumbers: Scalars['Boolean']['input'];
  notifications: Array<NotificationInput>;
  randomGeneration: Scalars['Boolean']['input'];
  ticketTypes: Array<EditTicketTypesInput>;
};

export type EditMembershipTypeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Currency>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableInstalmentPayment?: InputMaybe<Scalars['Boolean']['input']>;
  enableRenewableMembership: Scalars['Boolean']['input'];
  formId?: InputMaybe<Scalars['Float']['input']>;
  fundraiserTicketTypeId?: InputMaybe<Scalars['String']['input']>;
  includeFundraiserTicket?: InputMaybe<Scalars['Boolean']['input']>;
  interest?: InputMaybe<Scalars['Float']['input']>;
  linkMembershipTypeIds: Array<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfInstalments?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  publishType?: InputMaybe<PublishType>;
  renewableMembershipDefault?: InputMaybe<Scalars['Boolean']['input']>;
  typeGAA?: InputMaybe<TypeGAA>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EditRaffleInput = {
  additionalInfoInput?: InputMaybe<FundraiserAdditionalInfoInput>;
  drawDate: Scalars['DateTime']['input'];
  notifications: Array<NotificationInput>;
  prizes: Array<RafflePrizeInput>;
  ticketTypes: Array<EditTicketTypesInput>;
};

export type EditTicketTypesInput = {
  entries: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  numberOfRounds: Scalars['Float']['input'];
  price: Scalars['Float']['input'];
};

export type Event = {
  __typename?: 'Event';
  additionalInfoEnabled: Maybe<Scalars['Boolean']['output']>;
  club: Club;
  content: Scalars['String']['output'];
  coverImageSource: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  eventTicketPurchaseStatus: EventTicketPurchaseStatusType;
  eventTicketStatus: EventTicketStatusType;
  facebookPostId: Maybe<Scalars['String']['output']>;
  form: Maybe<Form>;
  id: Scalars['Float']['output'];
  inactiveTicketTypes: Array<EventTicketType>;
  instagramPostId: Maybe<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  maxTickets: Scalars['Float']['output'];
  pinned: Maybe<Scalars['Boolean']['output']>;
  published: Scalars['Boolean']['output'];
  schedules: Array<Schedule>;
  socialMediaShareSettings: SocialMediaShareSettings;
  startDate: Scalars['DateTime']['output'];
  ticketTypes: Array<EventTicketType>;
  ticketingType: Maybe<TicketingType>;
  tickets: Array<EventTicket>;
  title: Scalars['String']['output'];
  tixserveEventCode: Maybe<Scalars['String']['output']>;
  twitterTweetId: Maybe<Scalars['String']['output']>;
};

export enum EventFilter {
  ANY_TIME = 'ANY_TIME',
  NEXT_WEEK = 'NEXT_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  THIS_WEEK = 'THIS_WEEK',
  TODAY = 'TODAY'
}

export type EventInput = {
  additionalInfoEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  content: Scalars['String']['input'];
  coverImageSource?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  location: Scalars['String']['input'];
  pinned?: InputMaybe<Scalars['Boolean']['input']>;
  published: Scalars['Boolean']['input'];
  startDate: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
};

export type EventTicket = {
  __typename?: 'EventTicket';
  email: Scalars['String']['output'];
  event: Event;
  firstName: Scalars['String']['output'];
  formData: Maybe<FormData>;
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  payment: Maybe<Payment>;
  phoneNumber: Scalars['String']['output'];
  ticketType: EventTicketType;
  user: Maybe<User>;
  userEventTicketStatus: EventTicketPurchaseStatusType;
};

export type EventTicketInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  tickets: Array<EventTicketToPurchase>;
};

export type EventTicketOwner = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

/** Types of the status of event ticket purchase */
export enum EventTicketPurchaseStatusType {
  inProgress = 'inProgress',
  purchased = 'purchased'
}

/** Types of the status of event tickets */
export enum EventTicketStatusType {
  available = 'available',
  notAvailable = 'notAvailable',
  soldOut = 'soldOut'
}

export type EventTicketToPurchase = {
  count: Scalars['Float']['input'];
  owners?: InputMaybe<Array<EventTicketOwner>>;
  ticketTypeId: Scalars['Float']['input'];
};

export type EventTicketTransactionDetails = {
  __typename?: 'EventTicketTransactionDetails';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  ticketTypeName: Scalars['String']['output'];
};

export type EventTicketType = {
  __typename?: 'EventTicketType';
  color: TicketColor;
  currency: Currency;
  event: Event;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  numberOfTickets: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  tickets: EventTicket;
  tixserveTicketTypeCode: Maybe<Scalars['String']['output']>;
};

/** Search input possibilities of ticket categories */
export enum ExtendedFundraiserEnum {
  all = 'all',
  lotto = 'lotto',
  raffle = 'raffle'
}

export type ExtendedMembershipType = {
  __typename?: 'ExtendedMembershipType';
  active: Scalars['Boolean']['output'];
  autoRenewMembersCount: Scalars['Float']['output'];
  bundledFundraiser: Maybe<BundledFundraiser>;
  club: Club;
  currency: Currency;
  daysLeft: Scalars['Float']['output'];
  description: Maybe<Scalars['String']['output']>;
  enableInstalmentPayment: Scalars['Boolean']['output'];
  enableRenewableMembership: Scalars['Boolean']['output'];
  form: Maybe<Form>;
  fundraiserTicketTypeId: Scalars['String']['output'];
  groupDescription: Maybe<GroupMembershipDescription>;
  id: Scalars['Float']['output'];
  inactiveSubtypes: Array<MembershipGroupSubtype>;
  includeFundraiserTicket: Scalars['Boolean']['output'];
  interest: Maybe<Scalars['Float']['output']>;
  isAboutToExpire: Scalars['Boolean']['output'];
  isSingle: Scalars['Boolean']['output'];
  membershipGroupSubtypes: Array<MembershipGroupSubtype>;
  membershipStatus: Scalars['String']['output'];
  membershipTypeFundraiserTicketTypes: Maybe<Array<MembershipTypeFundraiserTicketType>>;
  memberships: Array<Membership>;
  name: Scalars['String']['output'];
  numberOfInstalments: Maybe<Scalars['Float']['output']>;
  price: Scalars['Float']['output'];
  publishType: PublishType;
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  purchaseCount: Scalars['Float']['output'];
  renewableMembershipDefault: Maybe<Scalars['Boolean']['output']>;
  renewalMembershipType: Maybe<MembershipType>;
  renewedMembershipTypes: Maybe<Array<MembershipType>>;
  schedules: Array<Schedule>;
  typeGAA: Maybe<TypeGAA>;
  validFrom: Scalars['DateTime']['output'];
  validTo: Scalars['DateTime']['output'];
};

/** Search input possibilities of transaction category */
export enum ExtendedPaymentItemType {
  all = 'all',
  event = 'event',
  lotto = 'lotto',
  membership = 'membership',
  raffle = 'raffle'
}

/** Status of a payment for search input */
export enum ExtendedPaymentStatus {
  all = 'all',
  complete = 'complete',
  failed = 'failed',
  pending = 'pending',
  received = 'received'
}

/** Search input possibilities of transaction payment type */
export enum ExtendedPaymentType {
  all = 'all',
  instalment = 'instalment',
  refund = 'refund',
  single = 'single'
}

export type Features = {
  __typename?: 'Features';
  fundraisers: Scalars['Boolean']['output'];
  fundraisersOnMemberSite: Maybe<Scalars['Boolean']['output']>;
  membership: Scalars['Boolean']['output'];
  tickets: Scalars['Boolean']['output'];
};

export type FeaturesWithClosedFundraiser = {
  __typename?: 'FeaturesWithClosedFundraiser';
  fundraisers: Scalars['Boolean']['output'];
  fundraisersOnMemberSite: Maybe<Scalars['Boolean']['output']>;
  haveClosedFundraiser: Scalars['Boolean']['output'];
  membership: Scalars['Boolean']['output'];
  tickets: Scalars['Boolean']['output'];
};

export type FileUploadRequestResponse = {
  __typename?: 'FileUploadRequestResponse';
  fields: Array<FileUploadRequestResponseField>;
  url: Scalars['String']['output'];
};

export type FileUploadRequestResponseField = {
  __typename?: 'FileUploadRequestResponseField';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Number of servers */
export enum FileUploadType {
  appleTermsAndConditions = 'appleTermsAndConditions',
  articleCover = 'articleCover',
  clubLogo = 'clubLogo',
  codeOfConduct = 'codeOfConduct',
  eventCover = 'eventCover',
  googleTermsAndConditions = 'googleTermsAndConditions',
  memberImportFile = 'memberImportFile',
  profileImage = 'profileImage',
  sponsorshipBannerImage = 'sponsorshipBannerImage',
  websiteAssociations = 'websiteAssociations'
}

export type FilterCountTransactionsInput = {
  category?: InputMaybe<ExtendedPaymentItemType>;
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FilterFundraiserEventStatisticsInput = {
  fundraiserEventId?: InputMaybe<Scalars['Float']['input']>;
  fundraiserId?: InputMaybe<Scalars['Float']['input']>;
  fundraiserName?: InputMaybe<Scalars['String']['input']>;
  fundraiserType?: InputMaybe<FundraiserType>;
};

export type FilterFundraiserTicketsInput = {
  buyerName?: InputMaybe<Scalars['String']['input']>;
  eventId?: InputMaybe<Scalars['Float']['input']>;
  fundraiserId?: InputMaybe<Scalars['Float']['input']>;
  fundraiserType?: InputMaybe<ExtendedFundraiserEnum>;
  ticketTypeId?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterMembersInput = {
  birthDateMax?: InputMaybe<Scalars['DateTime']['input']>;
  birthDateMin?: InputMaybe<Scalars['DateTime']['input']>;
  gender?: InputMaybe<Gender>;
  memberOrNot?: InputMaybe<Scalars['Boolean']['input']>;
  membershipStatus?: InputMaybe<MembershipStatus>;
  membershipType?: InputMaybe<Scalars['String']['input']>;
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
};

export type FilterMobileBuildStatusInput = {
  organisation?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<MobileAppBuildPlatform>;
  version?: InputMaybe<BuildVersionFilter>;
};

export type FilterPayoutsInput = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FilterTransactionsInput = {
  category?: InputMaybe<ExtendedPaymentItemType>;
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<ExtendedPaymentType>;
  purchaserOrPrice?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ExtendedPaymentStatus>;
};

export type FirstNameLength = {
  __typename?: 'FirstNameLength';
  count: Scalars['Int']['output'];
  length: Scalars['Int']['output'];
};

export type Form = {
  __typename?: 'Form';
  club: Maybe<Club>;
  clubType: Maybe<Clubtype>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  events: Array<Event>;
  formData: Array<FormData>;
  fundraisers: Array<Fundraiser>;
  id: Scalars['Float']['output'];
  isUsed: Scalars['Boolean']['output'];
  membershipGroupSubtypes: Array<MembershipGroupSubtype>;
  membershipTypes: Array<MembershipType>;
  name: Scalars['String']['output'];
  schema: Scalars['JSON']['output'];
  uiSchema: Scalars['JSON']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FormData = {
  __typename?: 'FormData';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  eventTickets: EventTicket;
  form: Form;
  fundraiserTickets: FundraiserTicket;
  id: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FormDataDataArray = {
  __typename?: 'FormDataDataArray';
  data: Scalars['JSON']['output'];
  duplicated: Scalars['Boolean']['output'];
  existing: Scalars['Boolean']['output'];
};

export type FormDataDataArrayInput = {
  data: Scalars['JSON']['input'];
};

export type FormDataFromFileResponse = {
  __typename?: 'FormDataFromFileResponse';
  fileError: Scalars['String']['output'];
  formData: Array<FormDataDataArray>;
  schema: Scalars['JSON']['output'];
  uiSchema: Scalars['JSON']['output'];
};

export type Fundraiser = {
  __typename?: 'Fundraiser';
  additionalInfoEnabled: Maybe<Scalars['Boolean']['output']>;
  club: Club;
  clubSuffix: Scalars['String']['output'];
  currency: Currency;
  currentEvent: Maybe<FundraiserEvent>;
  currentRoundSales: CurrentFundraiserRoundSales;
  demo: Scalars['Boolean']['output'];
  endDate: Maybe<Scalars['DateTime']['output']>;
  ended: Scalars['Boolean']['output'];
  form: Maybe<Form>;
  fundraiserDescription: FundraiserDescription;
  fundraiserEvents: Array<FundraiserEvent>;
  fundraiserType: FundraiserCategory;
  id: Scalars['Float']['output'];
  isAssignedToMembership: Scalars['Boolean']['output'];
  lastEvent: Maybe<FundraiserEvent>;
  matchThreeNumbers: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notificationTriggers: Array<NotificationTrigger>;
  notifications: Array<Notification>;
  prizes: Array<Scalars['String']['output']>;
  randomGeneration: Scalars['Boolean']['output'];
  repetition: RepetitionType;
  startDate: Maybe<Scalars['DateTime']['output']>;
  ticketTypes: Array<FundraiserTicketType>;
  type: FundraiserType;
};


export type FundraisercurrentRoundSalesArgs = {
  clubId: Scalars['Float']['input'];
};

export type FundraiserAdditionalInfoInput = {
  additionalInfoEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  schema?: InputMaybe<Scalars['JSON']['input']>;
  uiSchema?: InputMaybe<Scalars['JSON']['input']>;
};

export type FundraiserBundleConfirmationResult = {
  __typename?: 'FundraiserBundleConfirmationResult';
  isConfirmationValid: Scalars['Boolean']['output'];
};

export type FundraiserCategory = {
  __typename?: 'FundraiserCategory';
  demo: Maybe<Scalars['Boolean']['output']>;
  description: Scalars['String']['output'];
  fundraisers: Array<Fundraiser>;
  id: Scalars['Float']['output'];
  name: FundraiserType;
};

export type FundraiserDescription = {
  __typename?: 'FundraiserDescription';
  numberOfBalls: Maybe<Scalars['Float']['output']>;
  numberRange: Maybe<Scalars['Float']['output']>;
  ticketStartNumber: Maybe<Scalars['Float']['output']>;
};

export type FundraiserEvent = {
  __typename?: 'FundraiserEvent';
  createdAt: Scalars['DateTime']['output'];
  eventDate: Scalars['DateTime']['output'];
  eventNumber: Scalars['Float']['output'];
  fundraiser: Fundraiser;
  fundraiserDraws: Array<FundraiserEventResult>;
  fundraiserEventStatistics: Maybe<FundraiserEventStatistics>;
  fundraiserEventTickets: Array<FundraiserEventTicket>;
  id: Scalars['Float']['output'];
  isOpen: Scalars['Boolean']['output'];
  notificationTriggers: Maybe<Array<NotificationTrigger>>;
  prizes: Array<Scalars['String']['output']>;
  purchases: Maybe<Array<RecurringPurchase>>;
  updatedAt: Scalars['DateTime']['output'];
  winningNumbers: Array<Scalars['Float']['output']>;
};

export type FundraiserEventResult = {
  __typename?: 'FundraiserEventResult';
  drawDate: Scalars['DateTime']['output'];
  eventResultTicketLines: Array<FundraiserEventResultTicketLine>;
  fundraiserEvent: FundraiserEvent;
  id: Scalars['Float']['output'];
  winningNumber: Array<Scalars['Float']['output']>;
};

export type FundraiserEventResultTicketLine = {
  __typename?: 'FundraiserEventResultTicketLine';
  fundraiserEventResult: FundraiserEventResult;
  id: Scalars['Float']['output'];
  prize: Scalars['String']['output'];
  ticketLine: FundraiserTicketLine;
};

export type FundraiserEventStatisticDetails = {
  __typename?: 'FundraiserEventStatisticDetails';
  clubId: Scalars['Float']['output'];
  fundraiserEventId: Scalars['Float']['output'];
  fundraiserEventNumber: Scalars['Float']['output'];
  fundraiserId: Scalars['Float']['output'];
  fundraiserName: Scalars['String']['output'];
  fundraiserTypeId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  itemsTotal: Scalars['Float']['output'];
  jackpot: Scalars['String']['output'];
  priceTotal: Scalars['Float']['output'];
  roundEnd: Scalars['DateTime']['output'];
  roundStart: Scalars['DateTime']['output'];
};

export type FundraiserEventStatistics = {
  __typename?: 'FundraiserEventStatistics';
  fundraiserEvent: FundraiserEvent;
  itemsTotal: Scalars['Float']['output'];
  priceTotal: Scalars['Float']['output'];
};

export type FundraiserEventStatisticsPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FundraiserEventStatisticsPaginationOrder>;
  orderDirection?: InputMaybe<OrderDirection>;
};

/** The possible variations of the "order" prop */
export enum FundraiserEventStatisticsPaginationOrder {
  fundraiserEventNumber = 'fundraiserEventNumber',
  fundraiserName = 'fundraiserName',
  itemsTotal = 'itemsTotal',
  jackpot = 'jackpot',
  priceTotal = 'priceTotal',
  roundEnd = 'roundEnd',
  roundStart = 'roundStart'
}

export type FundraiserEventTicket = {
  __typename?: 'FundraiserEventTicket';
  drawsLeft: Scalars['Float']['output'];
  eventTicketPayment: FundraiserEventTicketPayment;
  fundraiserEvent: FundraiserEvent;
  id: Scalars['Float']['output'];
  ticket: FundraiserTicket;
};

export type FundraiserEventTicketPayment = {
  __typename?: 'FundraiserEventTicketPayment';
  eventTicket: FundraiserEventTicket;
  id: Scalars['Float']['output'];
  ticketPayment: FundraiserTicketPayment;
};

export type FundraiserEventWithWinners = {
  __typename?: 'FundraiserEventWithWinners';
  event: FundraiserEvent;
  winners: Array<FundraiserWinners>;
};

export type FundraiserNotificationPreviewFields = {
  __typename?: 'FundraiserNotificationPreviewFields';
  body: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type FundraiserNotificationPreviewTemplateData = {
  __typename?: 'FundraiserNotificationPreviewTemplateData';
  eventDate: Maybe<Scalars['String']['output']>;
  eventPrize: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  firstNameLengths: Array<FirstNameLength>;
  fundraiserName: Maybe<Scalars['String']['output']>;
  fundraiserWebsiteLink: Scalars['String']['output'];
};

export type FundraiserParticipantTicket = {
  __typename?: 'FundraiserParticipantTicket';
  additionalInfo: Maybe<Scalars['String']['output']>;
  additionalInfoRaw: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['String']['output'];
  drawsLeft: Scalars['Float']['output'];
  email: Scalars['String']['output'];
  eventDate: Scalars['String']['output'];
  fundraiserName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  roundNumber: Scalars['Float']['output'];
  ticketTypeName: Scalars['String']['output'];
};

export type FundraiserRounds = {
  __typename?: 'FundraiserRounds';
  clubId: Scalars['Float']['output'];
  combination: Maybe<Array<Scalars['Float']['output']>>;
  currency: Maybe<Currency>;
  drawDate: Scalars['DateTime']['output'];
  drawNumber: Scalars['Float']['output'];
  fundraiserName: Scalars['String']['output'];
  fundraiserType: FundraiserType;
  id: Scalars['Float']['output'];
  isOpen: Scalars['Boolean']['output'];
  jackpot: Maybe<Scalars['Float']['output']>;
  repetition: RaffleRepetitionType;
  roundId: Scalars['Float']['output'];
  winners: Maybe<Array<FundraiserWinner>>;
};

export type FundraiserTicket = {
  __typename?: 'FundraiserTicket';
  boughtFrom: TicketBoughtFrom;
  email: Scalars['String']['output'];
  endDate: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  formData: Maybe<FormData>;
  fundraiserEventTickets: Array<FundraiserEventTicket>;
  fundraiserTicketLines: Array<FundraiserTicketLine>;
  fundraiserTicketPayments: Array<FundraiserTicketPayment>;
  id: Scalars['Float']['output'];
  isOpen: Scalars['Boolean']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  membershipFundraiserTickets: Maybe<MembershipFundraiserTicket>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  purchases: Maybe<Array<RecurringPurchase>>;
  recurring: Scalars['Boolean']['output'];
  recurringCard: Maybe<CustomerCard>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  ticketType: FundraiserTicketType;
  user: Maybe<User>;
};

export type FundraiserTicketDetails = {
  __typename?: 'FundraiserTicketDetails';
  boughtFrom: Maybe<Scalars['String']['output']>;
  clubId: Scalars['Float']['output'];
  combination: Maybe<Array<Scalars['Float']['output']>>;
  draw: Scalars['Float']['output'];
  drawId: Scalars['Float']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fundraiserId: Maybe<Scalars['Float']['output']>;
  fundraiserName: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  itemType: FundraiserType;
  lastName: Scalars['String']['output'];
  lineOrder: Scalars['Float']['output'];
  lottoId: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  numberOfLines: Scalars['Float']['output'];
  paidAmount: Scalars['Float']['output'];
  paymentId: Scalars['Float']['output'];
  phoneNumber: Maybe<Scalars['String']['output']>;
  purchaseDate: Maybe<Scalars['DateTime']['output']>;
  raffleId: Maybe<Scalars['Float']['output']>;
  recurring: Scalars['Boolean']['output'];
  rowId: Scalars['String']['output'];
  ticketLineId: Scalars['Float']['output'];
  ticketNumber: Maybe<Scalars['Float']['output']>;
  ticketType: Scalars['String']['output'];
  ticketTypeId: Scalars['Float']['output'];
  userId: Maybe<Scalars['Float']['output']>;
};

export type FundraiserTicketLine = {
  __typename?: 'FundraiserTicketLine';
  eventResultTicketLines: Array<FundraiserEventResultTicketLine>;
  id: Scalars['Float']['output'];
  lineNumber: Scalars['Float']['output'];
  ticket: FundraiserTicket;
  ticketLineItems: Array<FundraiserTicketLineItem>;
};

export type FundraiserTicketLineItem = {
  __typename?: 'FundraiserTicketLineItem';
  id: Scalars['Float']['output'];
  item: Scalars['Float']['output'];
  ticketLine: FundraiserTicketLine;
};

export type FundraiserTicketPayment = {
  __typename?: 'FundraiserTicketPayment';
  eventTicketPayments: Array<FundraiserEventTicketPayment>;
  id: Scalars['Float']['output'];
  payment: Payment;
  ticket: FundraiserTicket;
};

export type FundraiserTicketTransactionDetails = {
  __typename?: 'FundraiserTicketTransactionDetails';
  autoRenew: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
};

export type FundraiserTicketType = {
  __typename?: 'FundraiserTicketType';
  currency: Currency;
  entries: Scalars['Float']['output'];
  fundraiser: Fundraiser;
  fundraiserTickets: Array<FundraiserTicket>;
  id: Scalars['Float']['output'];
  membershipFundraiserTickets: Maybe<Array<MembershipFundraiserTicket>>;
  membershipTypeFundraiserTicketTypes: Maybe<MembershipTypeFundraiserTicketType>;
  name: Scalars['String']['output'];
  numberOfRounds: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
};

export type FundraiserTickets = {
  __typename?: 'FundraiserTickets';
  combination: Maybe<Array<Array<Scalars['Float']['output']>>>;
  currency: Maybe<Currency>;
  drawDate: Scalars['DateTime']['output'];
  drawNumber: Scalars['Float']['output'];
  entries: Scalars['Float']['output'];
  fundraiserId: Scalars['String']['output'];
  fundraiserName: Scalars['String']['output'];
  fundraiserType: FundraiserType;
  id: Scalars['Float']['output'];
  jackpotAmount: Maybe<Scalars['Float']['output']>;
  numberOfRounds: Scalars['Float']['output'];
  paidAt: Maybe<Scalars['DateTime']['output']>;
  paymentId: Scalars['Float']['output'];
  prizes: Maybe<Array<RafflePrize>>;
  recurring: Scalars['Boolean']['output'];
  remainingDraws: Scalars['Float']['output'];
  repetition: RaffleRepetitionType;
  roundId: Scalars['Float']['output'];
  ticketNumbers: Maybe<Array<Scalars['Float']['output']>>;
  ticketType: TicketType;
  ticketTypeName: Scalars['String']['output'];
  userId: Scalars['Float']['output'];
  winners: Maybe<Array<FundraiserWinner>>;
  winningNumbers: Maybe<Array<Scalars['Float']['output']>>;
};

export type FundraiserTicketsPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<LottoTicketsPaginationOrder>;
  orderDirection?: InputMaybe<OrderDirection>;
};

/** Type of a fundraiser */
export enum FundraiserType {
  lotto = 'lotto',
  raffle = 'raffle'
}

export type FundraiserWinner = {
  __typename?: 'FundraiserWinner';
  amount: Maybe<Scalars['Float']['output']>;
  currency: Maybe<Currency>;
  name: Scalars['String']['output'];
  prize: Maybe<RafflePrize>;
  ticketNumber: Maybe<Scalars['String']['output']>;
};

export type FundraiserWinners = {
  __typename?: 'FundraiserWinners';
  amount: Maybe<Scalars['Float']['output']>;
  combination: Maybe<Array<Scalars['Float']['output']>>;
  currency: Currency;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  prize: Maybe<RafflePrize>;
  ticketId: Scalars['Float']['output'];
  ticketLineId: Scalars['Float']['output'];
  ticketNumber: Maybe<Scalars['Float']['output']>;
};

export type FundraiserWithTicketTypes = PublicLottoWithTicketTypes | PublicRaffleWithTicketTypes;

/** Gender of users */
export enum Gender {
  female = 'female',
  male = 'male',
  unknown = 'unknown'
}

export type GeneratedRaffleWinner = {
  __typename?: 'GeneratedRaffleWinner';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Maybe<Scalars['String']['output']>;
  prize: RafflePrize;
  ticketLineId: Scalars['Float']['output'];
  ticketNumber: Scalars['String']['output'];
};

export type GetMembershipTypesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type Gradient = {
  __typename?: 'Gradient';
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type GrantRole = {
  __typename?: 'GrantRole';
  data: Maybe<Array<User>>;
  error: Maybe<GrantRoleError>;
};

export type GrantRoleError = {
  __typename?: 'GrantRoleError';
  email: Scalars['String']['output'];
  message: Scalars['String']['output'];
  translationKey: Scalars['String']['output'];
};

export type GrantRoleInput = {
  users: Array<UsersNewRole>;
};

export type Group = {
  __typename?: 'Group';
  club: PublicClub;
  id: Scalars['ID']['output'];
  messages: Array<GroupMessage>;
  myUnrespondedMessagesCount: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  users: Array<GroupMember>;
};

export type GroupListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type GroupMember = {
  __typename?: 'GroupMember';
  group: Group;
  manager: Scalars['Boolean']['output'];
  user: PublicUserType;
};

export type GroupMembershipCart = {
  piece: Scalars['Float']['input'];
  subtypeId: Scalars['String']['input'];
};

export type GroupMembershipDescription = {
  __typename?: 'GroupMembershipDescription';
  maxGroupSize: Scalars['Float']['output'];
  minGroupSize: Scalars['Float']['output'];
};

export type GroupMessage = {
  __typename?: 'GroupMessage';
  content: Scalars['String']['output'];
  group: Group;
  id: Scalars['ID']['output'];
  membersWithResponse: Maybe<Array<MemberWithResponse>>;
  myResponse: Maybe<Scalars['Boolean']['output']>;
  responses: Array<Response>;
  schedules: Array<Schedule>;
  sentOn: Scalars['DateTime']['output'];
  subject: Scalars['String']['output'];
  summarizedResponses: SummarizedResponses;
};

export type GroupUserInput = {
  id: Scalars['Float']['input'];
  manager?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ImportMembersInput = {
  address: AddressInput;
  associatedClub?: InputMaybe<Scalars['String']['input']>;
  association?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<MemberGender>;
  id: Scalars['Float']['input'];
  irishFirstName?: InputMaybe<Scalars['String']['input']>;
  irishLastName?: InputMaybe<Scalars['String']['input']>;
  isAnyMedicalCondition?: InputMaybe<Scalars['Boolean']['input']>;
  isPlayer?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  medicalInformation?: InputMaybe<Scalars['String']['input']>;
  membershipTypeId: Scalars['Float']['input'];
  ngbId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  subtypeId?: InputMaybe<Scalars['String']['input']>;
};

export type InputFeatures = {
  fundraisers: Scalars['Boolean']['input'];
  fundraisersOnMemberSite: Scalars['Boolean']['input'];
  membership: Scalars['Boolean']['input'];
  tickets: Scalars['Boolean']['input'];
};

export type InputSettings = {
  features: InputFeatures;
};

export type Instalment = {
  __typename?: 'Instalment';
  amount: Scalars['Float']['output'];
  currency: Currency;
  dueDate: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  instalmentPayments: Maybe<Array<InstalmentPayment>>;
  membershipInstalments: Maybe<Array<MembershipInstalment>>;
};

export type InstalmentPayment = {
  __typename?: 'InstalmentPayment';
  id: Scalars['Float']['output'];
  instalment: Instalment;
  payment: Payment;
};

export type InstalmentPaymentStatus = {
  __typename?: 'InstalmentPaymentStatus';
  areInstalmentsConsideredAsFullyPaid: Scalars['Boolean']['output'];
  numberOfInstalments: Scalars['Float']['output'];
  numberOfInstalmentsBehindOnPayment: Scalars['Float']['output'];
  numberOfPaidInstalments: Scalars['Float']['output'];
};

export type InstalmentPlan = {
  __typename?: 'InstalmentPlan';
  calculatedAt: Scalars['DateTime']['output'];
  monthlyFee: Scalars['Float']['output'];
  numberOfMonths: Scalars['Float']['output'];
};

export type LastUnregisteredMembersNotificationDate = {
  __typename?: 'LastUnregisteredMembersNotificationDate';
  isRecent: Scalars['Boolean']['output'];
  lastUnregisteredMembersNotificationDate: Maybe<Scalars['DateTime']['output']>;
};

export type LinkStatus = {
  __typename?: 'LinkStatus';
  isExpired: Scalars['Boolean']['output'];
};

export type ListArticlesInput = {
  dateRange?: InputMaybe<ArticleSearchDateRange>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ListEventsInput = {
  currentDay?: InputMaybe<Scalars['DateTime']['input']>;
  day?: InputMaybe<Scalars['Float']['input']>;
  filter?: InputMaybe<EventFilter>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  past?: InputMaybe<Scalars['Boolean']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
};

export type ListFundraiserRoundsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListMembersInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MembersPaginationOrder>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ListMembershipInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MembershipListPaginationOrder>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ListMobileBuildStatusWithStats = {
  __typename?: 'ListMobileBuildStatusWithStats';
  hasMore: Scalars['Boolean']['output'];
  latestAppVersion: Maybe<Scalars['String']['output']>;
  next: Maybe<PaginationNext>;
  numberOfAbortableBuilds: Scalars['Int']['output'];
  numberOfUpdatableBuilds: Scalars['Int']['output'];
  rows: Array<MobileBuildStatus>;
  totalCount: Scalars['Int']['output'];
};

export type ListPayoutsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ListTransactionsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ListUserFundraiserTicketsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Lotto = {
  __typename?: 'Lotto';
  additionalInfoEnabled: Maybe<Scalars['Boolean']['output']>;
  club: Club;
  currency: Currency;
  currentRound: Maybe<LottoRound>;
  demo: Scalars['Boolean']['output'];
  ended: Scalars['Boolean']['output'];
  form: Maybe<Form>;
  id: Scalars['Float']['output'];
  jackpotAmount: Scalars['Float']['output'];
  matchThreeNumbers: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notifications: Array<Notification>;
  numberOfBalls: Scalars['Float']['output'];
  numberRange: Scalars['Float']['output'];
  randomGeneration: Scalars['Boolean']['output'];
  repeating: LottoRepetitionType;
  rounds: Array<LottoRound>;
  ticketTypes: Array<LottoTicketType>;
};

export type LottoParticipant = {
  __typename?: 'LottoParticipant';
  additionalInfo: Maybe<Scalars['String']['output']>;
  additionalInfoRaw: Maybe<Scalars['JSON']['output']>;
  combination: Scalars['String']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Maybe<Scalars['String']['output']>;
};

/** Types of repeating lottos */
export enum LottoRepetitionType {
  biMonthly = 'biMonthly',
  everyMonth = 'everyMonth',
  everyTwoWeeks = 'everyTwoWeeks',
  everyWeek = 'everyWeek'
}

export type LottoRound = {
  __typename?: 'LottoRound';
  combination: Maybe<Array<Scalars['Float']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  drawDate: Scalars['DateTime']['output'];
  drawNumber: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  isOpen: Scalars['Boolean']['output'];
  jackpot: Maybe<Scalars['Float']['output']>;
  lotto: Lotto;
  tickets: Array<LottoTicket>;
  updatedAt: Scalars['DateTime']['output'];
  winners: Maybe<Array<WinnerType>>;
};

export type LottoSales = {
  __typename?: 'LottoSales';
  lottoid: Scalars['Float']['output'];
  roundid: Scalars['Float']['output'];
  sum: Scalars['Float']['output'];
};

export type LottoTicket = {
  __typename?: 'LottoTicket';
  boughtFrom: TicketBoughtFrom;
  combination: Array<Scalars['Float']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  payment: Maybe<Payment>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  remainingDraws: Scalars['Float']['output'];
  round: LottoRound;
  ticketType: LottoTicketType;
  user: Maybe<User>;
};

export type LottoTicketType = {
  __typename?: 'LottoTicketType';
  currency: Currency;
  entries: Scalars['Int']['output'];
  id: Scalars['Float']['output'];
  lotto: Lotto;
  name: Scalars['String']['output'];
  numberOfRounds: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  recurringTickets: Scalars['Int']['output'];
  tickets: Array<LottoTicket>;
  type: TicketType;
};

/** The possible variations of the "order" prop */
export enum LottoTicketsPaginationOrder {
  fundraiserName = 'fundraiserName',
  lastName = 'lastName',
  purchaseDate = 'purchaseDate',
  ticketType = 'ticketType'
}

export type MatchThreeWinnerTicketIds = {
  matchThreeWinnerTicketIds: Array<Scalars['Float']['input']>;
};

export type MemberFormDataDuplicationsInput = {
  formData: Array<FormDataDataArrayInput>;
};

export type MemberFormDataDuplicationsResponse = {
  __typename?: 'MemberFormDataDuplicationsResponse';
  formData: Array<FormDataDataArray>;
};

export type MemberFormDataImportInput = {
  formData: Array<FormDataDataArrayInput>;
};

export enum MemberGender {
  female = 'female',
  male = 'male'
}

export type MemberMembershipTypeInput = {
  membershipSubtypeId?: InputMaybe<Scalars['String']['input']>;
  membershipTypeId: Scalars['Float']['input'];
};

export type MemberWithResponse = {
  __typename?: 'MemberWithResponse';
  birthDate: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Maybe<Scalars['String']['output']>;
  response: Maybe<Scalars['Boolean']['output']>;
};

/** The possible variations of the "order" prop */
export enum MembersPaginationOrder {
  birthDate = 'birthDate',
  contribution = 'contribution',
  email = 'email',
  lastName = 'lastName'
}

export type Membership = {
  __typename?: 'Membership';
  autoRenew: Maybe<Scalars['Boolean']['output']>;
  autoRenewDisabledAt: Maybe<Scalars['DateTime']['output']>;
  cancelled: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  isCompleted: Scalars['Boolean']['output'];
  members: Array<MembershipMember>;
  membershipFundraiserTickets: Maybe<Array<MembershipFundraiserTicket>>;
  membershipInstalments: Maybe<Array<MembershipInstalment>>;
  membershipRenewInDue: Maybe<MembershipRenewInDue>;
  membershipType: MembershipType;
  payment: Maybe<Payment>;
  paymentCancelledAt: Maybe<Scalars['DateTime']['output']>;
  paymentCancellerUser: User;
  renewalMemberships: Membership;
  renewedMembership: Maybe<Membership>;
  status: MembershipStatus;
  user: Maybe<User>;
};

export type MembershipFilterInput = {
  validTo: MembershipValidToInput;
};

export type MembershipFundraiserTicket = {
  __typename?: 'MembershipFundraiserTicket';
  fundraiserTicket: Maybe<FundraiserTicket>;
  fundraiserTicketType: FundraiserTicketType;
  hash: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  membership: Membership;
  url: Scalars['String']['output'];
};

export type MembershipGroupSubtype = {
  __typename?: 'MembershipGroupSubtype';
  description: Maybe<Scalars['String']['output']>;
  form: Maybe<Form>;
  id: Scalars['ID']['output'];
  maxNumberOfMembers: Scalars['Int']['output'];
  members: Array<MembershipMember>;
  membershipType: MembershipType;
  name: Scalars['String']['output'];
  orderNumber: Scalars['Int']['output'];
  prices: Array<Scalars['Float']['output']>;
  purchaseCount: Scalars['Float']['output'];
  typeGAA: Maybe<TypeGAA>;
};

export type MembershipInstalment = {
  __typename?: 'MembershipInstalment';
  id: Scalars['Float']['output'];
  instalment: Instalment;
  membership: Membership;
};

export type MembershipInstalmentDetails = {
  __typename?: 'MembershipInstalmentDetails';
  areInstalmentsConsideredAsFullyPaid: Scalars['Boolean']['output'];
  cancelledAt: Maybe<Scalars['DateTime']['output']>;
  cancellerUser: Maybe<User>;
  numberOfInstalments: Scalars['Float']['output'];
  numberOfInstalmentsBehindOnPayment: Scalars['Float']['output'];
  numberOfPaidInstalments: Scalars['Float']['output'];
};

export type MembershipList = {
  __typename?: 'MembershipList';
  clubId: Scalars['Float']['output'];
  dateOfBirth: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  fullType: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  medicalInformation: Maybe<Scalars['String']['output']>;
  membershipFormData: Maybe<Scalars['JSON']['output']>;
  membershipTypeId: Maybe<Scalars['Float']['output']>;
  name: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  subtype: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

/** The possible variations of the "order" prop */
export enum MembershipListPaginationOrder {
  dateOfBirth = 'dateOfBirth',
  email = 'email',
  fullType = 'fullType',
  medicalInformation = 'medicalInformation',
  name = 'name',
  status = 'status'
}

export type MembershipMember = {
  __typename?: 'MembershipMember';
  id: Scalars['Float']['output'];
  instalmentPaymentStatus: InstalmentPaymentStatus;
  memberData: Maybe<MembershipMemberData>;
  membershipFormData: Maybe<Scalars['JSON']['output']>;
  membershipPayment: Membership;
  status: MembershipStatus;
  subtype: Maybe<MembershipGroupSubtype>;
};

export type MembershipMemberData = {
  __typename?: 'MembershipMemberData';
  address: Address;
  associatedClub: Maybe<Scalars['String']['output']>;
  association: Maybe<Scalars['String']['output']>;
  companyName: Maybe<Scalars['String']['output']>;
  dateOfBirth: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender: Maybe<MemberGender>;
  irishFirstName: Maybe<Scalars['String']['output']>;
  irishLastName: Maybe<Scalars['String']['output']>;
  isAnyMedicalCondition: Maybe<Scalars['Boolean']['output']>;
  isPlayer: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  medicalInformation: Maybe<Scalars['String']['output']>;
  ngbId: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
};

export type MembershipMemberDataInput = {
  address: AddressInput;
  associatedClub?: InputMaybe<Scalars['String']['input']>;
  association?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<MemberGender>;
  irishFirstName?: InputMaybe<Scalars['String']['input']>;
  irishLastName?: InputMaybe<Scalars['String']['input']>;
  isAnyMedicalCondition?: InputMaybe<Scalars['Boolean']['input']>;
  isPlayer?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  medicalInformation?: InputMaybe<Scalars['String']['input']>;
  ngbId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MembershipMemberDetailed = {
  __typename?: 'MembershipMemberDetailed';
  email: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  instalmentPaymentStatus: InstalmentPaymentStatus;
  memberData: Maybe<MembershipMemberData>;
  membershipFormData: Maybe<Scalars['JSON']['output']>;
  membershipName: Scalars['String']['output'];
  membershipPayment: Membership;
  name: Scalars['String']['output'];
  status: MembershipStatus;
  subtype: Maybe<MembershipGroupSubtype>;
};

export type MembershipMemberFormDataInput = {
  memberData: Scalars['JSON']['input'];
};

export type MembershipMemberImportDuplicationCheckInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Float']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  row?: InputMaybe<Scalars['Float']['input']>;
};

export type MembershipMemberImportDuplications = {
  __typename?: 'MembershipMemberImportDuplications';
  csvDuplicatedRows: Array<Scalars['Float']['output']>;
  dbDuplicatedRows: Array<Scalars['Float']['output']>;
};

export type MembershipRenewInDue = {
  __typename?: 'MembershipRenewInDue';
  description: Scalars['String']['output'];
  dueDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo: Scalars['DateTime']['output'];
};

/** Validity status of a membership */
export enum MembershipStatus {
  behindOnPayment = 'behindOnPayment',
  cancelled = 'cancelled',
  expired = 'expired',
  paidUnregistered = 'paidUnregistered',
  valid = 'valid'
}

export type MembershipTransactionDetails = {
  __typename?: 'MembershipTransactionDetails';
  autoRenew: Maybe<Scalars['Boolean']['output']>;
  cancelled: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  instalment: Maybe<MembershipInstalmentDetails>;
  members: Array<MembershipMemberDetailed>;
  status: MembershipStatus;
};

export type MembershipType = {
  __typename?: 'MembershipType';
  active: Scalars['Boolean']['output'];
  bundledFundraiser: Maybe<BundledFundraiser>;
  club: Club;
  currency: Currency;
  description: Maybe<Scalars['String']['output']>;
  enableInstalmentPayment: Scalars['Boolean']['output'];
  enableRenewableMembership: Scalars['Boolean']['output'];
  form: Maybe<Form>;
  fundraiserTicketTypeId: Scalars['String']['output'];
  groupDescription: Maybe<GroupMembershipDescription>;
  id: Scalars['Float']['output'];
  inactiveSubtypes: Array<MembershipGroupSubtype>;
  includeFundraiserTicket: Scalars['Boolean']['output'];
  interest: Maybe<Scalars['Float']['output']>;
  isSingle: Scalars['Boolean']['output'];
  membershipGroupSubtypes: Array<MembershipGroupSubtype>;
  membershipTypeFundraiserTicketTypes: Maybe<Array<MembershipTypeFundraiserTicketType>>;
  memberships: Array<Membership>;
  name: Scalars['String']['output'];
  numberOfInstalments: Maybe<Scalars['Float']['output']>;
  price: Scalars['Float']['output'];
  publishType: PublishType;
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  purchaseCount: Scalars['Float']['output'];
  renewableMembershipDefault: Maybe<Scalars['Boolean']['output']>;
  renewalMembershipType: Maybe<MembershipType>;
  renewedMembershipTypes: Maybe<Array<MembershipType>>;
  schedules: Array<Schedule>;
  typeGAA: Maybe<TypeGAA>;
  validFrom: Scalars['DateTime']['output'];
  validTo: Scalars['DateTime']['output'];
};

export type MembershipTypeFundraiserTicketType = {
  __typename?: 'MembershipTypeFundraiserTicketType';
  fundraiserTicketType: Maybe<FundraiserTicketType>;
  id: Scalars['Float']['output'];
  membershipType: MembershipType;
};

/** Membership validation filter input type */
export enum MembershipValidToInput {
  future = 'future',
  past = 'past'
}

export type MessagesListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

/** Mobile app build bulk action */
export enum MobileAppBuildBulkAction {
  abortAll = 'abortAll',
  updateAll = 'updateAll'
}

/** Mobile app build platform */
export enum MobileAppBuildPlatform {
  android = 'android',
  ios = 'ios'
}

/** Mobile app build status */
export enum MobileAppBuildStatus {
  aborted = 'aborted',
  building = 'building',
  complete = 'complete',
  failed = 'failed',
  queued = 'queued'
}

export type MobileAppInput = {
  apnToken?: InputMaybe<Scalars['String']['input']>;
  apnTokenKeyId?: InputMaybe<Scalars['String']['input']>;
  appWelcomeText: Scalars['String']['input'];
  appleAppId?: InputMaybe<Scalars['String']['input']>;
  appleCompanyName?: InputMaybe<Scalars['String']['input']>;
  appleContentProviderId?: InputMaybe<Scalars['String']['input']>;
  appleDeveloperTeamId?: InputMaybe<Scalars['String']['input']>;
  appleTeamName?: InputMaybe<Scalars['String']['input']>;
  fcmId?: InputMaybe<Scalars['String']['input']>;
  firebaseAndroidAppId?: InputMaybe<Scalars['String']['input']>;
  firebaseIosAppId?: InputMaybe<Scalars['String']['input']>;
  firebaseProjectId?: InputMaybe<Scalars['String']['input']>;
  firebaseServiceKey?: InputMaybe<Scalars['String']['input']>;
  newKeyStore?: InputMaybe<Scalars['Boolean']['input']>;
  packageIdentifier?: InputMaybe<Scalars['String']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  sha256CertFingerprints?: InputMaybe<Scalars['String']['input']>;
};

export type MobileBuild = {
  __typename?: 'MobileBuild';
  branch: Scalars['String']['output'];
  buildNotes: Scalars['String']['output'];
  buildNumber: Scalars['Float']['output'];
  buildSlug: Scalars['String']['output'];
  buildVersion: Scalars['String']['output'];
  club: Club;
  id: Scalars['Float']['output'];
  platform: MobileAppBuildPlatform;
  status: MobileAppBuildStatus;
};

export type MobileBuildStatus = {
  __typename?: 'MobileBuildStatus';
  appVersion: Maybe<Scalars['String']['output']>;
  branch: Maybe<Scalars['String']['output']>;
  buildNotes: Maybe<Scalars['String']['output']>;
  buildNumber: Maybe<Scalars['Float']['output']>;
  buildSlug: Maybe<Scalars['String']['output']>;
  buildStatus: Maybe<MobileAppBuildStatus>;
  id: Scalars['Float']['output'];
  mobileBuildId: Maybe<Scalars['Float']['output']>;
  organisation: Scalars['String']['output'];
  organisationId: Scalars['Float']['output'];
  platform: MobileAppBuildPlatform;
};

export type MobileBuildStatusInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MobileBuildStatusPaginationOrder>;
  orderDirection?: InputMaybe<OrderDirection>;
};

/** Order app build versions */
export enum MobileBuildStatusPaginationOrder {
  appVersion = 'appVersion',
  buildNotes = 'buildNotes',
  buildNumber = 'buildNumber',
  buildStatus = 'buildStatus',
  organisation = 'organisation',
  platform = 'platform'
}

export type MobileBuilds = {
  __typename?: 'MobileBuilds';
  builds: Array<MobileBuild>;
};

export enum MobilePlatform {
  android = 'android',
  ios = 'ios'
}

/** Mobile navigator screen names. Should be kept up to date with the mobile navigator */
export enum MobileScreens {
  ArticleDetails = 'ArticleDetails',
  DrawResults = 'DrawResults',
  EventDetails = 'EventDetails',
  MemberDetailsForm = 'MemberDetailsForm',
  MembershipOptions = 'MembershipOptions',
  MembershipSidebarNavigator = 'MembershipSidebarNavigator',
  MessageDetails = 'MessageDetails',
  MessagesScreen = 'MessagesScreen',
  ResultsScreen = 'ResultsScreen'
}

export type ModifyUserInput = {
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  deviceToken?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  abortBuild: MobileBuild;
  bulkAction: MobileBuilds;
  /** @deprecated use purchase mutation */
  buyEventTicket: PaymentSession;
  /** @deprecated use purchase mutation */
  buyMembership: PaymentSession;
  cancelInstalmentPayment: Payment;
  cancelRecurringTicket: FundraiserTicket;
  changeMembershipTypeActive: MembershipType;
  changePassword: User;
  confirmFilledBundledFundraiser: FundraiserBundleConfirmationResult;
  contactClub: Scalars['Boolean']['output'];
  createArticle: Article;
  createClub: Club;
  createClubForm: Form;
  createEvent: Event;
  createGroup: Group;
  createGroupMembership: MembershipType;
  createLotto: Lotto;
  createMembershipType: MembershipType;
  createRaffle: Raffle;
  deleteArticle: Article;
  deleteClubForm: Form;
  deleteEvent: Event;
  deleteGroup: Group;
  deleteMembershipType: MembershipType;
  deleteMessage: GroupMessage;
  editArticle: Article;
  editEvent: Event;
  editGroup: Group;
  editGroupMembership: MembershipType;
  editMembershipType: MembershipType;
  editSponsorships: Array<Sponsorship>;
  endFundraiser: Fundraiser;
  finishSetup: Scalars['Boolean']['output'];
  grantRoles: GrantRole;
  importMembers: Array<MembershipMember>;
  makeCardDefault: CustomerCard;
  modifyClub: Club;
  modifyPushnotificationInfo: Club;
  modifyUser: User;
  payoutToBank: PayoutResponse;
  pinArticle: Article;
  pinEvent: Event;
  publishRaffleWinners: FundraiserEvent;
  publishWinners: FundraiserEvent;
  purchase: Redirect;
  refreshBuild: MobileBuild;
  refreshDeviceToken: DeviceId;
  refundTransaction: Payment;
  registerMember: MembershipMember;
  registerMemberFormData: MembershipMember;
  removeCard: CustomerCard;
  resendClubInvitationEmail: Club;
  revokeRole: User;
  sendBugReport: Scalars['Boolean']['output'];
  sendMessage: GroupMessage;
  sendResponse: Response;
  sendUnregisteredMembersNotification: SendUnregisteredMembersNotificationResponse;
  setMembershipAsFullyPaid: MembershipMember;
  setMembershipDisableAutoRenew: MembershipMember;
  setUploadedCodeOfConduct: CodeOfConductData;
  setUploadedTermsAndConditions: TermsAndConditions;
  setupCard: PaymentSession;
  startBuild: MobileBuild;
  startBuilds: MobileBuilds;
  unpinArticle: Article;
  unpinEvent: Event;
  updateClubForm: Form;
  updateContactToBlacklist: Array<BlacklistedContactData>;
  updateLotto: Lotto;
  updateMemberMembershipMemberFormData: MembershipMember;
  updateRaffle: Raffle;
  updateSmSSenderId: Scalars['String']['output'];
  uploadBulkPlayers: Array<FundraiserTicket>;
  uploadRequest: FileUploadRequestResponse;
  upsertBookmarks: Array<Bookmark>;
  viewArticle: Article;
};


export type MutationabortBuildArgs = {
  buildId: Scalars['Float']['input'];
};


export type MutationbulkActionArgs = {
  bulkActionInput: BulkActionInput;
};


export type MutationbuyEventTicketArgs = {
  eventId: Scalars['Float']['input'];
  eventTicketInput: EventTicketInput;
};


export type MutationbuyMembershipArgs = {
  membershipTypeId: Scalars['Float']['input'];
};


export type MutationcancelInstalmentPaymentArgs = {
  clubId: Scalars['Float']['input'];
  membersAction: RefundMembersAction;
  paymentId: Scalars['Float']['input'];
};


export type MutationcancelRecurringTicketArgs = {
  ticketId: Scalars['Float']['input'];
};


export type MutationchangeMembershipTypeActiveArgs = {
  membershipType: ChangeMembershipTypeActiveInput;
  membershipTypeId: Scalars['Float']['input'];
};


export type MutationchangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationconfirmFilledBundledFundraiserArgs = {
  bundleHash: Scalars['String']['input'];
  cart: Cart;
};


export type MutationcontactClubArgs = {
  clubId: Scalars['Float']['input'];
  message: ClubContactMessage;
};


export type MutationcreateArticleArgs = {
  clubId: Scalars['Float']['input'];
  input: ArticleInput;
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
  socialMediaArticlePostInput: SocialMediaArticlePostInput;
};


export type MutationcreateClubArgs = {
  admin: ClubOwnerInput;
  club: ClubInput;
};


export type MutationcreateClubFormArgs = {
  input: CreateFormInput;
};


export type MutationcreateEventArgs = {
  additionalInfoInput?: InputMaybe<AdditionalInfoInput>;
  clubId: Scalars['Float']['input'];
  input: EventInput;
  notificationSchedule: ScheduleInput;
  socialMediaPostInput: SocialMediaPostInput;
  ticketInput?: InputMaybe<CreateEventTicketInput>;
};


export type MutationcreateGroupArgs = {
  clubId: Scalars['Float']['input'];
  input: CreateGroupInput;
};


export type MutationcreateGroupMembershipArgs = {
  clubId: Scalars['Float']['input'];
  fundraiserTicketTypeId?: InputMaybe<Scalars['String']['input']>;
  membership: CreateGroupMembershipInput;
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
};


export type MutationcreateLottoArgs = {
  clubId: Scalars['Float']['input'];
  input: NewLottoInput;
};


export type MutationcreateMembershipTypeArgs = {
  clubId: Scalars['Float']['input'];
  fundraiserTicketTypeId?: InputMaybe<Scalars['String']['input']>;
  membershipType?: InputMaybe<CreateMembershipTypeInput>;
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
};


export type MutationcreateRaffleArgs = {
  clubId: Scalars['Float']['input'];
  input: NewRaffleInput;
};


export type MutationdeleteArticleArgs = {
  articleId: Scalars['Float']['input'];
};


export type MutationdeleteClubFormArgs = {
  id: Scalars['Float']['input'];
};


export type MutationdeleteEventArgs = {
  id: Scalars['Float']['input'];
};


export type MutationdeleteGroupArgs = {
  id: Scalars['String']['input'];
};


export type MutationdeleteMembershipTypeArgs = {
  membershipTypeId: Scalars['Float']['input'];
};


export type MutationdeleteMessageArgs = {
  groupId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
};


export type MutationeditArticleArgs = {
  articleId: Scalars['Float']['input'];
  input: ArticleInput;
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
  socialMediaArticlePostInput: SocialMediaArticlePostInput;
};


export type MutationeditEventArgs = {
  additionalInfoInput?: InputMaybe<AdditionalInfoInput>;
  id: Scalars['Float']['input'];
  input: EventInput;
  notificationSchedule: ScheduleInput;
  socialMediaPostInput: SocialMediaPostInput;
  ticketInput?: InputMaybe<CreateEventTicketInput>;
};


export type MutationeditGroupArgs = {
  groupId: Scalars['String']['input'];
  input: EditGroupInput;
};


export type MutationeditGroupMembershipArgs = {
  fundraiserTicketTypeId?: InputMaybe<Scalars['String']['input']>;
  membership: EditGroupMembershipInput;
  membershipTypeId: Scalars['Float']['input'];
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
};


export type MutationeditMembershipTypeArgs = {
  membershipType: EditMembershipTypeInput;
  membershipTypeId: Scalars['Float']['input'];
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
};


export type MutationeditSponsorshipsArgs = {
  clubId: Scalars['Float']['input'];
  input: Array<SponsorshipInput>;
};


export type MutationendFundraiserArgs = {
  fundraiserId: Scalars['Float']['input'];
};


export type MutationfinishSetupArgs = {
  clubId: Scalars['Float']['input'];
};


export type MutationgrantRolesArgs = {
  input: GrantRoleInput;
};


export type MutationimportMembersArgs = {
  members: MemberFormDataImportInput;
  membershipTypeId: Scalars['Float']['input'];
};


export type MutationmakeCardDefaultArgs = {
  cardId: Scalars['String']['input'];
};


export type MutationmodifyClubArgs = {
  admin: ClubOwnerInput;
  club: ClubInput;
  clubId: Scalars['Float']['input'];
  migrateToNewFirebaseService?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationmodifyPushnotificationInfoArgs = {
  club: UpdatePushnotificationInput;
  clubId: Scalars['Float']['input'];
};


export type MutationmodifyUserArgs = {
  id: Scalars['Float']['input'];
  userInput: ModifyUserInput;
};


export type MutationpayoutToBankArgs = {
  clubId: Scalars['Float']['input'];
  options: PayoutToBankInput;
};


export type MutationpinArticleArgs = {
  articleId: Scalars['Float']['input'];
};


export type MutationpinEventArgs = {
  id: Scalars['Float']['input'];
};


export type MutationpublishRaffleWinnersArgs = {
  eventId: Scalars['Float']['input'];
  publishInputs: PublishWinnerInputs;
};


export type MutationpublishWinnersArgs = {
  eventId: Scalars['Float']['input'];
  publishInputs: PublishInputs;
};


export type MutationpurchaseArgs = {
  cart: Cart;
  option: PaymentOption;
  publicUser?: InputMaybe<PublicPurchaseUser>;
};


export type MutationrefreshBuildArgs = {
  buildNumber: Scalars['Float']['input'];
};


export type MutationrefreshDeviceTokenArgs = {
  deviceToken: Scalars['String']['input'];
  deviceType: MobilePlatform;
  pushVersion?: InputMaybe<Scalars['Float']['input']>;
  userId: Scalars['Float']['input'];
};


export type MutationrefundTransactionArgs = {
  clubId: Scalars['Float']['input'];
  options: RefundTransactionsInput;
};


export type MutationregisterMemberArgs = {
  memberData: ValidatedMembershipMemberDataInput;
  memberId: Scalars['Float']['input'];
};


export type MutationregisterMemberFormDataArgs = {
  memberData: MembershipMemberFormDataInput;
  memberId: Scalars['Float']['input'];
};


export type MutationremoveCardArgs = {
  cardId: Scalars['String']['input'];
};


export type MutationresendClubInvitationEmailArgs = {
  clubId: Scalars['Float']['input'];
};


export type MutationrevokeRoleArgs = {
  userId: Scalars['Float']['input'];
};


export type MutationsendBugReportArgs = {
  message: BugReportMessage;
};


export type MutationsendMessageArgs = {
  groupId: Scalars['String']['input'];
  message: NewMessage;
};


export type MutationsendResponseArgs = {
  groupId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
  response: Scalars['Boolean']['input'];
};


export type MutationsendUnregisteredMembersNotificationArgs = {
  clubId: Scalars['Float']['input'];
};


export type MutationsetMembershipAsFullyPaidArgs = {
  clubId: Scalars['Float']['input'];
  memberId: Scalars['Float']['input'];
};


export type MutationsetMembershipDisableAutoRenewArgs = {
  clubId: Scalars['Float']['input'];
  memberId: Scalars['Float']['input'];
};


export type MutationsetUploadedCodeOfConductArgs = {
  clubId: Scalars['Float']['input'];
  fileUrl: Scalars['String']['input'];
};


export type MutationsetUploadedTermsAndConditionsArgs = {
  clubId: Scalars['Float']['input'];
  fileUrl: Scalars['String']['input'];
  platform: PlatformsEnum;
};


export type MutationstartBuildArgs = {
  buildDetails: BuildDetails;
};


export type MutationstartBuildsArgs = {
  buildDetails: Array<BuildDetails>;
};


export type MutationunpinArticleArgs = {
  articleId: Scalars['Float']['input'];
};


export type MutationunpinEventArgs = {
  id: Scalars['Float']['input'];
};


export type MutationupdateClubFormArgs = {
  id: Scalars['Float']['input'];
  input: UpdateFormInput;
};


export type MutationupdateContactToBlacklistArgs = {
  clubId: Scalars['Float']['input'];
  inputs: Array<BlacklistedContactDataWithType>;
};


export type MutationupdateLottoArgs = {
  input: EditLottoInput;
  lottoId: Scalars['Float']['input'];
};


export type MutationupdateMemberMembershipMemberFormDataArgs = {
  memberDataInput: MembershipMemberFormDataInput;
  memberId: Scalars['Float']['input'];
  membershipInput?: InputMaybe<MemberMembershipTypeInput>;
};


export type MutationupdateRaffleArgs = {
  input: EditRaffleInput;
  raffleId: Scalars['Float']['input'];
};


export type MutationupdateSmSSenderIdArgs = {
  clubId: Scalars['Float']['input'];
  senderId: SenderIdInput;
};


export type MutationuploadBulkPlayersArgs = {
  data: BulkUploadArg;
  ticketTypeId: Scalars['Float']['input'];
};


export type MutationuploadRequestArgs = {
  clubId?: InputMaybe<Scalars['Float']['input']>;
  fileName: Scalars['String']['input'];
  type: FileUploadType;
};


export type MutationupsertBookmarksArgs = {
  clubId: Scalars['Float']['input'];
  input: UpsertBookmarkInput;
};


export type MutationviewArticleArgs = {
  articleId: Scalars['Float']['input'];
};

export type NewLottoInput = {
  additionalInfoInput?: InputMaybe<FundraiserAdditionalInfoInput>;
  drawDate: Scalars['DateTime']['input'];
  jackpotAmount: Scalars['Float']['input'];
  matchThreeNumbers: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  notifications: Array<NotificationInput>;
  numberOfBalls: Scalars['Int']['input'];
  numberRange: Scalars['Int']['input'];
  randomGeneration: Scalars['Boolean']['input'];
  repeating: LottoRepetitionType;
  ticketTypes: Array<TicketTypesInput>;
};

export type NewMessage = {
  content: Scalars['String']['input'];
  sendOn?: InputMaybe<Scalars['DateTime']['input']>;
  subject: Scalars['String']['input'];
};

export type NewRaffleInput = {
  additionalInfoInput?: InputMaybe<FundraiserAdditionalInfoInput>;
  drawDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  notifications: Array<NotificationInput>;
  prizes: Array<RafflePrizeInput>;
  repeating: RaffleRepetitionType;
  ticketStartNumber: Scalars['Float']['input'];
  ticketTypes: Array<TicketTypesInput>;
};

export type Notification = {
  __typename?: 'Notification';
  contents: Array<NotificationContent>;
  fundraiser: Fundraiser;
  id: Scalars['ID']['output'];
  instances: Array<NotificationInstance>;
  preferredChannels: Array<Channel>;
  targetGroup: AudienceGroup;
  targetGroupOptions: AudienceOptions;
  timings: Array<NotificationTiming>;
};

export type NotificationBlacklist = {
  __typename?: 'NotificationBlacklist';
  channel: Channel;
  club: Club;
  contact: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type NotificationContent = {
  __typename?: 'NotificationContent';
  channel: Channel;
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  notification: Notification;
  subject: Maybe<Scalars['String']['output']>;
};

export type NotificationContentInput = {
  channel: Channel;
  content: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationInput = {
  contents: Array<NotificationContentInput>;
  preferredChannels: Array<Channel>;
  targetGroup: AudienceGroup;
  targetGroupOptions: AudienceOptionsInput;
  timings: Array<TimingInput>;
};

export type NotificationInstance = {
  __typename?: 'NotificationInstance';
  channel: Channel;
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  notification: Notification;
  processedOn: Maybe<Scalars['DateTime']['output']>;
  smsMessages: Array<SmsMessage>;
  trigger: NotificationTrigger;
  triggerAt: Scalars['DateTime']['output'];
  triggerEvent: NotificationTimingType;
};

export type NotificationTiming = {
  __typename?: 'NotificationTiming';
  id: Scalars['ID']['output'];
  notification: Notification;
  offset: Maybe<Scalars['Float']['output']>;
  triggerEvent: NotificationTimingType;
};

/** Types of notification timing */
export enum NotificationTimingType {
  draw = 'draw',
  publish = 'publish',
  roundEnd = 'roundEnd',
  roundStart = 'roundStart',
  update = 'update'
}

export type NotificationTrigger = {
  __typename?: 'NotificationTrigger';
  fundraiser: Fundraiser;
  fundraiserEvent: Maybe<FundraiserEvent>;
  id: Scalars['ID']['output'];
  instances: Array<NotificationInstance>;
  isRead: Scalars['Boolean']['output'];
  triggerEvent: NotificationTimingType;
  triggeredAt: Scalars['DateTime']['output'];
};

export type OfflineWinnerInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type Order = {
  orderBy?: InputMaybe<ContactOrderByParams>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum OrderDirection {
  asc = 'asc',
  desc = 'desc'
}

export type PaginatedOrderedSearchInputClass = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type PaginatedSearchArticleResponse = {
  __typename?: 'PaginatedSearchArticleResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<Article>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchClubResponse = {
  __typename?: 'PaginatedSearchClubResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<Club>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchContactDetailsBlacklistedResponse = {
  __typename?: 'PaginatedSearchContactDetailsBlacklistedResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<ContactDetailsBlacklisted>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchEventResponse = {
  __typename?: 'PaginatedSearchEventResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<Event>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchExtendedMembershipTypeResponse = {
  __typename?: 'PaginatedSearchExtendedMembershipTypeResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<ExtendedMembershipType>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchFundraiserEventResponse = {
  __typename?: 'PaginatedSearchFundraiserEventResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<FundraiserEvent>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchFundraiserEventStatisticDetailsResponse = {
  __typename?: 'PaginatedSearchFundraiserEventStatisticDetailsResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<FundraiserEventStatisticDetails>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchFundraiserRoundsResponse = {
  __typename?: 'PaginatedSearchFundraiserRoundsResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<FundraiserRounds>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchFundraiserTicketDetailsResponse = {
  __typename?: 'PaginatedSearchFundraiserTicketDetailsResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<FundraiserTicketDetails>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchFundraiserTicketsResponse = {
  __typename?: 'PaginatedSearchFundraiserTicketsResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<FundraiserTickets>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchGroupMessageResponse = {
  __typename?: 'PaginatedSearchGroupMessageResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<GroupMessage>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchGroupResponse = {
  __typename?: 'PaginatedSearchGroupResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<Group>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginatedSearchMembershipListResponse = {
  __typename?: 'PaginatedSearchMembershipListResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<MembershipList>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchMobileBuildStatusResponse = {
  __typename?: 'PaginatedSearchMobileBuildStatusResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<MobileBuildStatus>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchPaymentResponse = {
  __typename?: 'PaginatedSearchPaymentResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<Payment>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchPayoutResponseResponse = {
  __typename?: 'PaginatedSearchPayoutResponseResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<PayoutResponse>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchPublicEventResponse = {
  __typename?: 'PaginatedSearchPublicEventResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<PublicEvent>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchPublicUserTypeResponse = {
  __typename?: 'PaginatedSearchPublicUserTypeResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<PublicUserType>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSearchUserDetailsResponse = {
  __typename?: 'PaginatedSearchUserDetailsResponse';
  hasMore: Scalars['Boolean']['output'];
  next: Maybe<PaginationNext>;
  rows: Array<UserDetails>;
  totalCount: Scalars['Int']['output'];
};

export type PaginationNext = {
  __typename?: 'PaginationNext';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Float']['output'];
  canCancelInstalments: Scalars['Boolean']['output'];
  cardId: Maybe<Scalars['String']['output']>;
  club: Club;
  comment: Maybe<Scalars['String']['output']>;
  currency: Currency;
  email: Scalars['String']['output'];
  eventTickets: EventTicket;
  failedAt: Maybe<Scalars['DateTime']['output']>;
  failureDetails: Maybe<Scalars['String']['output']>;
  fee: Scalars['Float']['output'];
  firstName: Scalars['String']['output'];
  fundraiserTicketPayments: Array<FundraiserTicketPayment>;
  id: Scalars['Float']['output'];
  instalmentPayments: Maybe<Array<InstalmentPayment>>;
  itemType: PaymentItemType;
  lastName: Scalars['String']['output'];
  memberships: Membership;
  netAmount: Scalars['Float']['output'];
  paidAt: Maybe<Scalars['DateTime']['output']>;
  payment: Maybe<Payment>;
  paymentStatusUpdatedAt: Scalars['DateTime']['output'];
  paymentType: PaymentType;
  payout: Maybe<Payout>;
  refundReason: Maybe<RefundReason>;
  refundableAmount: Scalars['Float']['output'];
  refunds: Maybe<Array<Payment>>;
  status: PaymentStatus;
  stripeInfoEnteredAt: Maybe<Scalars['DateTime']['output']>;
  stripePaymentId: Maybe<Scalars['String']['output']>;
  stripeSessionId: Maybe<Scalars['String']['output']>;
  user: Maybe<User>;
};

/** Type of a payment */
export enum PaymentItemType {
  event = 'event',
  lotto = 'lotto',
  membership = 'membership',
  membershipFundraiserBundle = 'membershipFundraiserBundle',
  raffle = 'raffle'
}

export type PaymentOption = {
  autoRenew?: InputMaybe<Scalars['Boolean']['input']>;
  cardId?: InputMaybe<Scalars['String']['input']>;
  fromApp?: InputMaybe<Scalars['Boolean']['input']>;
  instalmentPlanStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaymentSession = {
  __typename?: 'PaymentSession';
  redirectUrl: Scalars['String']['output'];
};

/** Status of a payment */
export enum PaymentStatus {
  complete = 'complete',
  failed = 'failed',
  pending = 'pending',
  received = 'received'
}

/** Type of the payment */
export enum PaymentType {
  instalment = 'instalment',
  refund = 'refund',
  single = 'single'
}

export type Payout = {
  __typename?: 'Payout';
  amount: Scalars['Float']['output'];
  arrivalDate: Maybe<Scalars['DateTime']['output']>;
  automatic: Scalars['Boolean']['output'];
  club: Club;
  created: Scalars['DateTime']['output'];
  currency: Currency;
  destination: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  payments: Array<Payment>;
  processed: Scalars['Boolean']['output'];
  status: PayoutStatus;
  stripePayoutId: Scalars['String']['output'];
};

export type PayoutIncomeStream = {
  __typename?: 'PayoutIncomeStream';
  grossAmount: Scalars['Float']['output'];
  netAmount: Scalars['Float']['output'];
};

export type PayoutIncomeStreams = {
  __typename?: 'PayoutIncomeStreams';
  event: PayoutIncomeStream;
  lotto: PayoutIncomeStream;
  membership: PayoutIncomeStream;
  raffle: PayoutIncomeStream;
  total: PayoutIncomeStream;
};

export type PayoutIncomeStreamsResponse = {
  __typename?: 'PayoutIncomeStreamsResponse';
  refundFeatureReleaseDate: Maybe<Scalars['DateTime']['output']>;
  streams: PayoutIncomeStreams;
};

export type PayoutResponse = {
  __typename?: 'PayoutResponse';
  amount: Scalars['Float']['output'];
  arrivalDate: Scalars['DateTime']['output'];
  automatic: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  currency: Currency;
  destination: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** Status of the payout */
export enum PayoutStatus {
  canceled = 'canceled',
  failed = 'failed',
  in_transit = 'in_transit',
  paid = 'paid',
  pending = 'pending'
}

export type PayoutToBankInput = {
  amount: Scalars['Float']['input'];
  currency: Currency;
  statementDescriptor: Scalars['String']['input'];
};

/** Mobile platforms */
export enum PlatformsEnum {
  apple = 'apple',
  google = 'google'
}

export type PublicBuyTicketUser = {
  clubId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PublicClub = {
  __typename?: 'PublicClub';
  colors: Colors;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  logoSource: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  privacyPolicyUrl: Maybe<Scalars['String']['output']>;
  settings: Settings;
  suffix: Scalars['String']['output'];
  termsOfServiceUrl: Maybe<Scalars['String']['output']>;
};

export type PublicClubWithSuffix = {
  __typename?: 'PublicClubWithSuffix';
  suffix: Scalars['String']['output'];
};

export type PublicEvent = {
  __typename?: 'PublicEvent';
  additionalInfoEnabled: Maybe<Scalars['Boolean']['output']>;
  club: PublicClubWithSuffix;
  content: Scalars['String']['output'];
  coverImageSource: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  form: Maybe<PublicForm>;
  id: Scalars['Float']['output'];
  location: Scalars['String']['output'];
  maxTickets: Scalars['Float']['output'];
  startDate: Scalars['DateTime']['output'];
  ticketSalesStatus: EventTicketStatusType;
  ticketTypes: Array<PublicEventTicketType>;
  ticketingType: Maybe<TicketingType>;
  title: Scalars['String']['output'];
};

export type PublicEventTicketType = {
  __typename?: 'PublicEventTicketType';
  currency: Currency;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type PublicForm = {
  __typename?: 'PublicForm';
  id: Scalars['Float']['output'];
  schema: Scalars['JSON']['output'];
  uiSchema: Scalars['JSON']['output'];
};

export type PublicLotto = {
  __typename?: 'PublicLotto';
  club: PublicClub;
  currency: Currency;
  currentRound: PublicLottoRound;
  ended: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  jackpotAmount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  numberOfBalls: Scalars['Float']['output'];
  numberRange: Scalars['Float']['output'];
  repeating: LottoRepetitionType;
};

export type PublicLottoRound = {
  __typename?: 'PublicLottoRound';
  combination: Maybe<Array<Scalars['Float']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  drawDate: Scalars['DateTime']['output'];
  drawNumber: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  isOpen: Scalars['Boolean']['output'];
  jackpot: Maybe<Scalars['Float']['output']>;
  lotto: PublicLottoWithoutRounds;
  updatedAt: Scalars['DateTime']['output'];
  winners: Maybe<Array<WinnerType>>;
};

export type PublicLottoTicketType = {
  __typename?: 'PublicLottoTicketType';
  currency: Currency;
  entries: Scalars['Int']['output'];
  id: Scalars['Float']['output'];
  lotto: PublicLotto;
  name: Scalars['String']['output'];
  numberOfRounds: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  type: TicketType;
};

export type PublicLottoWithTicketTypes = {
  __typename?: 'PublicLottoWithTicketTypes';
  club: PublicClub;
  currency: Currency;
  currentRound: PublicLottoRound;
  ended: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  jackpotAmount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  numberOfBalls: Scalars['Float']['output'];
  numberRange: Scalars['Float']['output'];
  repeating: LottoRepetitionType;
  ticketTypes: Array<PublicLottoTicketType>;
};

export type PublicLottoWithoutRounds = {
  __typename?: 'PublicLottoWithoutRounds';
  club: PublicClub;
  currency: Currency;
  ended: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  jackpotAmount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  numberOfBalls: Scalars['Float']['output'];
  numberRange: Scalars['Float']['output'];
  repeating: LottoRepetitionType;
};

export type PublicPayment = {
  __typename?: 'PublicPayment';
  amount: Scalars['Float']['output'];
  club: PublicClub;
  currency: Currency;
  failedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  itemType: PaymentItemType;
  paidAt: Maybe<Scalars['DateTime']['output']>;
  stripeSessionId: Scalars['String']['output'];
};

export type PublicPurchaseUser = {
  clubId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PublicRaffle = {
  __typename?: 'PublicRaffle';
  currentRaffleRound: PublicRaffleRound;
  ended: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  prizes: Array<RafflePrize>;
  repeating: RaffleRepetitionType;
};

export type PublicRaffleRound = {
  __typename?: 'PublicRaffleRound';
  drawDate: Scalars['DateTime']['output'];
  drawNumber: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  isOpen: Scalars['Boolean']['output'];
  raffle: PublicRaffleWithoutRounds;
  winners: Maybe<Array<RaffleWinner>>;
};

export type PublicRaffleTicketType = {
  __typename?: 'PublicRaffleTicketType';
  currency: Currency;
  entries: Scalars['Int']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  numberOfRounds: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  raffle: PublicRaffle;
  type: TicketType;
};

export type PublicRaffleWithTicketTypes = {
  __typename?: 'PublicRaffleWithTicketTypes';
  currentRound: PublicRaffleRound;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  prizes: Array<RafflePrize>;
  repeating: RaffleRepetitionType;
  ticketTypes: Array<PublicRaffleTicketType>;
};

export type PublicRaffleWithoutRounds = {
  __typename?: 'PublicRaffleWithoutRounds';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  prizes: Array<RafflePrize>;
  repeating: RaffleRepetitionType;
};

export type PublicUser = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PublicUserType = {
  __typename?: 'PublicUserType';
  birthDate: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Maybe<Scalars['String']['output']>;
};

export type PublishInputs = {
  jackpotOfflineWinners?: InputMaybe<Array<OfflineWinnerInput>>;
  newJackpotAmount: Scalars['Float']['input'];
  randomWinners?: InputMaybe<Array<RandomWinnerInput>>;
  winningNumbers: Array<Scalars['Float']['input']>;
};

/** Types of publish setting */
export enum PublishType {
  draft = 'draft',
  published = 'published',
  setToPublish = 'setToPublish'
}

export type PublishWinnerInputs = {
  newPrizes: Array<RafflePrizeInput>;
  winners: Array<RaffleWinnerInput>;
};

export type PurchaseData = {
  __typename?: 'PurchaseData';
  failedAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  paidAt: Maybe<Scalars['DateTime']['output']>;
  type: PaymentItemType;
};

export type Query = {
  __typename?: 'Query';
  activeMembershipFormTypes: Array<MembershipType>;
  /** @deprecated Use activeMembershipV2 */
  activeMembershipTypes: Array<MembershipType>;
  article: Article;
  articles: PaginatedSearchArticleResponse;
  calculatePrice: Scalars['Float']['output'];
  checkStripeConnectAccount: Scalars['Boolean']['output'];
  club: Club;
  clubBranding: Maybe<Branding>;
  clubBrandingSuffix: Maybe<Branding>;
  countTransactions: TransactionsCountResponse;
  createLoginLink: Scalars['String']['output'];
  createOnboardingLink: Scalars['String']['output'];
  createStripeConnectAccount: Scalars['String']['output'];
  event: Event;
  events: PaginatedSearchEventResponse;
  findEvents: Array<FundraiserEvent>;
  findLotto: Lotto;
  findTicketTypes: Array<FundraiserTicketType>;
  getActiveFundraisersWithTicketTypes: Array<FundraiserWithTicketTypes>;
  getAvailableCountries: Array<Country>;
  getBookmarks: Maybe<Array<Bookmark>>;
  getBundledFundraiserData: BundledFundraiserData;
  getClubForms: Array<Form>;
  getClubOwner: User;
  getClubUsersWithRole: Array<User>;
  getCodeOfConductFile: CodeOfConductData;
  getDetailsOfMember: MembershipMember;
  getEventTicketTypes: Array<EventTicketType>;
  getEventWithWinners: FundraiserEventWithWinners;
  getForm: Form;
  getFundraiserEvent: FundraiserEvent;
  getFundraiserNotificationPreviewTemplateData: FundraiserNotificationPreviewTemplateData;
  getFundraisersWithOpenEvent: Array<Fundraiser>;
  getGroupMembershipMembers: Array<MembershipMember>;
  getInstalmentPlan: Maybe<InstalmentPlan>;
  getLastUnregisteredMembersNotificationDate: LastUnregisteredMembersNotificationDate;
  getMember: MembershipMember;
  getMemberDataFromFile: FormDataFromFileResponse;
  getMemberFormDataDuplications: MemberFormDataDuplicationsResponse;
  getMembershipsFormFundraiserTicketsOfUser: Array<Membership>;
  getMembershipsFundraiserTicketsOfUser: Array<Membership>;
  getMessage: GroupMessage;
  getNextEvent: Maybe<FundraiserEvent>;
  getNotificationBadges: Array<Badge>;
  getPayoutIncomeStreams: PayoutIncomeStreamsResponse;
  getPurchaseData: PurchaseData;
  getRaffle: Raffle;
  getRaffleTicketTypePublic: PublicRaffleTicketType;
  getRoundToFinish: RoundToFinish;
  getSavedCards: Array<CustomerCard>;
  getSelectableForms: Array<Form>;
  getSmSSenderId: Scalars['String']['output'];
  getSponsorshipData: Maybe<Sponsorship>;
  getSponsorshipsData: Maybe<Array<Sponsorship>>;
  getSponsorshipsDataAdmin: Maybe<Array<Sponsorship>>;
  getStripeAccountDetails: StripeAccount;
  getSystemForms: Array<Form>;
  getTermsAndConditionsFiles: TermsAndConditions;
  getTicketType: LottoTicketType;
  getTicketTypePublic: TicketTypePublic;
  getTicketsSold: PaginatedSearchFundraiserTicketDetailsResponse;
  getTransactionAutoCompleteOptions: Array<AutocompleteItem>;
  getUserAutoCompleteOptions: Array<UserAutocompleteItem>;
  group: Group;
  isDefaultCardRemovable: Scalars['Boolean']['output'];
  isLinkExpired: LinkStatus;
  listActiveUnfinishedFundraisers: Array<Fundraiser>;
  listBuilds: ListMobileBuildStatusWithStats;
  listClosedFundraiserEvents: PaginatedSearchFundraiserEventResponse;
  listClubs: PaginatedSearchClubResponse;
  listContactDetails: PaginatedSearchContactDetailsBlacklistedResponse;
  listFundraiserClosedRounds: PaginatedSearchFundraiserRoundsResponse;
  listFundraiserEventStatistics: PaginatedSearchFundraiserEventStatisticDetailsResponse;
  listGroups: PaginatedSearchGroupResponse;
  listMessages: PaginatedSearchGroupMessageResponse;
  listPayouts: PaginatedSearchPayoutResponseResponse;
  listTransactions: PaginatedSearchPaymentResponse;
  listUserEventTickets: Array<EventTicket>;
  listUserFundraiserTickets: PaginatedSearchFundraiserTicketsResponse;
  listUsers: PaginatedSearchPublicUserTypeResponse;
  matchThreeOnlineWinnerTickets: Array<FundraiserTicketDetails>;
  members: PaginatedSearchUserDetailsResponse;
  membershipMembers: PaginatedSearchMembershipListResponse;
  membershipType: MembershipType;
  membershipTypes: PaginatedSearchExtendedMembershipTypeResponse;
  myGroups: Array<Group>;
  notHiddenClubs: Array<PublicClub>;
  paymentStatus: PublicPayment;
  publishedArticle: Article;
  publishedArticles: PaginatedSearchArticleResponse;
  publishedEvent: Event;
  publishedEventPublic: PublicEvent;
  publishedEvents: PaginatedSearchEventResponse;
  publishedEventsPublic: PaginatedSearchPublicEventResponse;
  raffleTicketInfo: RaffleTicketInfo;
  randomOnlineWinnerTickets: Array<FundraiserTicketDetails>;
  randomRaffleWinner: Array<GeneratedRaffleWinner>;
  transactionDetails: TransactionDetailsResponse;
  user: User;
  users: Maybe<Array<UserDetails>>;
  validMembers: Array<MembershipMember>;
};


export type QueryactiveMembershipFormTypesArgs = {
  clubId: Scalars['Float']['input'];
};


export type QueryactiveMembershipTypesArgs = {
  clubId: Scalars['Float']['input'];
};


export type QueryarticleArgs = {
  id: Scalars['Float']['input'];
};


export type QueryarticlesArgs = {
  clubId?: InputMaybe<Scalars['Float']['input']>;
  input?: InputMaybe<ListArticlesInput>;
};


export type QuerycalculatePriceArgs = {
  cart: Array<GroupMembershipCart>;
  membershipTypeId: Scalars['Float']['input'];
};


export type QuerycheckStripeConnectAccountArgs = {
  clubId: Scalars['Float']['input'];
};


export type QueryclubArgs = {
  id: Scalars['Float']['input'];
};


export type QueryclubBrandingArgs = {
  clubId: Scalars['Float']['input'];
};


export type QueryclubBrandingSuffixArgs = {
  suffix: Scalars['String']['input'];
};


export type QuerycountTransactionsArgs = {
  clubId: Scalars['Float']['input'];
  filter: FilterCountTransactionsInput;
};


export type QuerycreateLoginLinkArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerycreateOnboardingLinkArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerycreateStripeConnectAccountArgs = {
  clubId: Scalars['Float']['input'];
};


export type QueryeventArgs = {
  id: Scalars['Float']['input'];
};


export type QueryeventsArgs = {
  clubId?: InputMaybe<Scalars['Float']['input']>;
  input?: InputMaybe<ListEventsInput>;
};


export type QueryfindEventsArgs = {
  clubId: Scalars['Float']['input'];
  fundraiserId?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryfindLottoArgs = {
  lottoId: Scalars['Float']['input'];
};


export type QueryfindTicketTypesArgs = {
  clubId: Scalars['Float']['input'];
  fundraiserId?: InputMaybe<Scalars['Float']['input']>;
};


export type QuerygetActiveFundraisersWithTicketTypesArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetBookmarksArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetBundledFundraiserDataArgs = {
  bundleHash: Scalars['String']['input'];
};


export type QuerygetClubOwnerArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetClubUsersWithRoleArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetCodeOfConductFileArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetDetailsOfMemberArgs = {
  memberId: Scalars['Float']['input'];
};


export type QuerygetEventTicketTypesArgs = {
  eventId: Scalars['Float']['input'];
};


export type QuerygetEventWithWinnersArgs = {
  eventId: Scalars['Float']['input'];
};


export type QuerygetFormArgs = {
  id: Scalars['Float']['input'];
};


export type QuerygetFundraiserEventArgs = {
  eventId: Scalars['Float']['input'];
};


export type QuerygetFundraiserNotificationPreviewTemplateDataArgs = {
  fundraiserId?: InputMaybe<Scalars['Float']['input']>;
};


export type QuerygetFundraisersWithOpenEventArgs = {
  clubId: Scalars['Float']['input'];
  ticketTypeIdToInclude?: InputMaybe<Scalars['Float']['input']>;
};


export type QuerygetGroupMembershipMembersArgs = {
  membershipId: Scalars['Float']['input'];
};


export type QuerygetInstalmentPlanArgs = {
  cart: Cart;
};


export type QuerygetLastUnregisteredMembersNotificationDateArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetMemberArgs = {
  memberId: Scalars['Float']['input'];
};


export type QuerygetMemberDataFromFileArgs = {
  file: Scalars['String']['input'];
  membershipTypeId: Scalars['Float']['input'];
};


export type QuerygetMemberFormDataDuplicationsArgs = {
  members: MemberFormDataDuplicationsInput;
};


export type QuerygetMembershipsFormFundraiserTicketsOfUserArgs = {
  clubId: Scalars['Float']['input'];
  userId: Scalars['Float']['input'];
};


export type QuerygetMembershipsFundraiserTicketsOfUserArgs = {
  clubId: Scalars['Float']['input'];
  userId: Scalars['Float']['input'];
};


export type QuerygetMessageArgs = {
  groupId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
};


export type QuerygetNextEventArgs = {
  eventId: Scalars['Float']['input'];
};


export type QuerygetPayoutIncomeStreamsArgs = {
  clubId: Scalars['Float']['input'];
  payoutId: Scalars['Float']['input'];
};


export type QuerygetPurchaseDataArgs = {
  clubId: Scalars['Float']['input'];
  sessionId: Scalars['String']['input'];
};


export type QuerygetRaffleArgs = {
  raffleId: Scalars['Float']['input'];
};


export type QuerygetRaffleTicketTypePublicArgs = {
  id: Scalars['Float']['input'];
};


export type QuerygetRoundToFinishArgs = {
  combinationInput: CombinationInput;
  roundId: Scalars['Float']['input'];
};


export type QuerygetSmSSenderIdArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetSponsorshipDataArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetSponsorshipsDataArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetSponsorshipsDataAdminArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetStripeAccountDetailsArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetTermsAndConditionsFilesArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerygetTicketTypeArgs = {
  id: Scalars['Float']['input'];
};


export type QuerygetTicketTypePublicArgs = {
  id: Scalars['Float']['input'];
};


export type QuerygetTicketsSoldArgs = {
  clubId: Scalars['Float']['input'];
  filters: FilterFundraiserTicketsInput;
  pagination: FundraiserTicketsPaginationInput;
};


export type QuerygetTransactionAutoCompleteOptionsArgs = {
  clubId: Scalars['Float']['input'];
  searchTerm: Scalars['String']['input'];
};


export type QuerygetUserAutoCompleteOptionsArgs = {
  clubId: Scalars['Float']['input'];
  searchTerm: Scalars['String']['input'];
  withRole?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerygroupArgs = {
  id: Scalars['String']['input'];
};


export type QueryisLinkExpiredArgs = {
  clubId: Scalars['Float']['input'];
  sessionId: Scalars['String']['input'];
};


export type QuerylistActiveUnfinishedFundraisersArgs = {
  clubId: Scalars['Float']['input'];
};


export type QuerylistBuildsArgs = {
  filter?: InputMaybe<FilterMobileBuildStatusInput>;
  input?: InputMaybe<MobileBuildStatusInput>;
};


export type QuerylistClosedFundraiserEventsArgs = {
  clubId: Scalars['Float']['input'];
  input: PaginatedSearchInput;
};


export type QuerylistClubsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination: ClubListPaginationInput;
};


export type QuerylistContactDetailsArgs = {
  clubId: Scalars['Float']['input'];
  inputs: ContactDetailsPaginationInput;
};


export type QuerylistFundraiserClosedRoundsArgs = {
  clubId: Scalars['Float']['input'];
  input: ListFundraiserRoundsInput;
};


export type QuerylistFundraiserEventStatisticsArgs = {
  clubId: Scalars['Float']['input'];
  filters: FilterFundraiserEventStatisticsInput;
  pagination: FundraiserEventStatisticsPaginationInput;
};


export type QuerylistGroupsArgs = {
  clubId: Scalars['Float']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination: GroupListPaginationInput;
};


export type QuerylistMessagesArgs = {
  groupId: Scalars['String']['input'];
  pagination: MessagesListPaginationInput;
};


export type QuerylistPayoutsArgs = {
  clubId: Scalars['Float']['input'];
  filter: FilterPayoutsInput;
  input: ListPayoutsInput;
};


export type QuerylistTransactionsArgs = {
  clubId: Scalars['Float']['input'];
  filter: FilterTransactionsInput;
  input: ListTransactionsInput;
};


export type QuerylistUserEventTicketsArgs = {
  eventId: Scalars['Float']['input'];
};


export type QuerylistUserFundraiserTicketsArgs = {
  clubId: Scalars['Float']['input'];
  input: ListUserFundraiserTicketsInput;
};


export type QuerylistUsersArgs = {
  clubId?: InputMaybe<Scalars['Float']['input']>;
  filter?: InputMaybe<FilterMembersInput>;
  input?: InputMaybe<ListMembersInput>;
};


export type QuerymatchThreeOnlineWinnerTicketsArgs = {
  clubId: Scalars['Float']['input'];
  combinationInput: CombinationInput;
  eventId: Scalars['Float']['input'];
  numberOfWinners: Scalars['Float']['input'];
};


export type QuerymembersArgs = {
  clubId?: InputMaybe<Scalars['Float']['input']>;
  filter?: InputMaybe<FilterMembersInput>;
  input?: InputMaybe<ListMembersInput>;
};


export type QuerymembershipMembersArgs = {
  clubId?: InputMaybe<Scalars['Float']['input']>;
  filter?: InputMaybe<FilterMembersInput>;
  input?: InputMaybe<ListMembershipInput>;
};


export type QuerymembershipTypeArgs = {
  membershipTypeId: Scalars['Float']['input'];
};


export type QuerymembershipTypesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  clubId: Scalars['Float']['input'];
  enableRenewableMembership?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MembershipFilterInput>;
  input?: InputMaybe<GetMembershipTypesInput>;
  publishType?: InputMaybe<PublishType>;
  single?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerypaymentStatusArgs = {
  clubId: Scalars['Float']['input'];
  stripeSessionId: Scalars['String']['input'];
};


export type QuerypublishedArticleArgs = {
  id: Scalars['Float']['input'];
};


export type QuerypublishedArticlesArgs = {
  clubId?: InputMaybe<Scalars['Float']['input']>;
  input?: InputMaybe<ListArticlesInput>;
};


export type QuerypublishedEventArgs = {
  id: Scalars['Float']['input'];
};


export type QuerypublishedEventPublicArgs = {
  id: Scalars['Float']['input'];
};


export type QuerypublishedEventsArgs = {
  clubId?: InputMaybe<Scalars['Float']['input']>;
  input?: InputMaybe<ListEventsInput>;
};


export type QuerypublishedEventsPublicArgs = {
  clubId: Scalars['Float']['input'];
  input?: InputMaybe<ListEventsInput>;
};


export type QueryraffleTicketInfoArgs = {
  eventId: Scalars['Float']['input'];
  ticketNumber: Scalars['Float']['input'];
};


export type QueryrandomOnlineWinnerTicketsArgs = {
  clubId: Scalars['Float']['input'];
  eventId: Scalars['Float']['input'];
  matchThreeWinnerTicketIds: MatchThreeWinnerTicketIds;
  numberOfWinners: Scalars['Float']['input'];
};


export type QueryrandomRaffleWinnerArgs = {
  eventId: Scalars['Float']['input'];
};


export type QuerytransactionDetailsArgs = {
  clubId: Scalars['Float']['input'];
  paymentId: Scalars['Float']['input'];
};


export type QueryuserArgs = {
  id: Scalars['Float']['input'];
};


export type QueryvalidMembersArgs = {
  filter: ValidMembersFilter;
};

export type Raffle = {
  __typename?: 'Raffle';
  activeTicketTypes: Array<RaffleTicketType>;
  additionalInfoEnabled: Maybe<Scalars['Boolean']['output']>;
  demo: Scalars['Boolean']['output'];
  ended: Scalars['Boolean']['output'];
  form: Maybe<Form>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  notifications: Array<Notification>;
  prizes: Array<RafflePrize>;
  repeating: RaffleRepetitionType;
  rounds: Array<RaffleRound>;
  ticketStartNumber: Scalars['Float']['output'];
  ticketTypes: Array<RaffleTicketType>;
};

export type RaffleParticipant = {
  __typename?: 'RaffleParticipant';
  additionalInfo: Maybe<Scalars['String']['output']>;
  additionalInfoRaw: Maybe<Scalars['JSON']['output']>;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Maybe<Scalars['String']['output']>;
  ticketNumber: Scalars['Float']['output'];
};

export type RafflePrize = {
  __typename?: 'RafflePrize';
  lineNumber: Scalars['Float']['output'];
  prizeName: Scalars['String']['output'];
};

export type RafflePrizeInput = {
  lineNumber: Scalars['Float']['input'];
  prizeName: Scalars['String']['input'];
};

/** Types of repeating raffles */
export enum RaffleRepetitionType {
  biMonthly = 'biMonthly',
  everyMonth = 'everyMonth',
  everyTwoWeeks = 'everyTwoWeeks',
  everyWeek = 'everyWeek',
  oneTime = 'oneTime'
}

export type RaffleRound = {
  __typename?: 'RaffleRound';
  createdAt: Scalars['DateTime']['output'];
  drawDate: Scalars['DateTime']['output'];
  drawNumber: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  isOpen: Scalars['Boolean']['output'];
  raffle: Raffle;
  tickets: Array<RaffleTicket>;
  updatedAt: Scalars['DateTime']['output'];
  winners: Maybe<Array<RaffleWinner>>;
};

export type RaffleSales = {
  __typename?: 'RaffleSales';
  raffleid: Scalars['Float']['output'];
  roundid: Scalars['Float']['output'];
  sum: Scalars['Float']['output'];
};

export type RaffleTicket = {
  __typename?: 'RaffleTicket';
  boughtFrom: TicketBoughtFrom;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  payment: Maybe<Payment>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  remainingDraws: Scalars['Float']['output'];
  round: RaffleRound;
  ticketNumber: Scalars['Float']['output'];
  ticketType: RaffleTicketType;
  user: Maybe<User>;
};

export type RaffleTicketInfo = {
  __typename?: 'RaffleTicketInfo';
  boughtfrom: Maybe<TicketBoughtFrom>;
  firstName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  paidat: Maybe<Scalars['DateTime']['output']>;
  ticketNumber: Maybe<Scalars['String']['output']>;
};

export type RaffleTicketType = {
  __typename?: 'RaffleTicketType';
  currency: Currency;
  entries: Scalars['Int']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  numberOfRounds: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  recurringTickets: Scalars['Int']['output'];
  tickets: RaffleTicket;
  type: TicketType;
};

export type RaffleWinner = {
  __typename?: 'RaffleWinner';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  prize: RafflePrize;
  ticketNumber: Scalars['String']['output'];
};

export type RaffleWinnerInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  prize: RafflePrizeInput;
  ticketLineId?: InputMaybe<Scalars['Float']['input']>;
  ticketNumber: Scalars['Float']['input'];
};

export type RandomWinnerInput = {
  amount: Scalars['Float']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  ticketLineId?: InputMaybe<Scalars['Float']['input']>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type RecurringPurchase = {
  __typename?: 'RecurringPurchase';
  card: CustomerCard;
  chargeRequestedAt: Maybe<Scalars['DateTime']['output']>;
  error: Maybe<Scalars['String']['output']>;
  event: FundraiserEvent;
  failedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  paymentIntentId: Maybe<Scalars['String']['output']>;
  succeededAt: Maybe<Scalars['DateTime']['output']>;
  ticket: FundraiserTicket;
};

export type Redirect = {
  __typename?: 'Redirect';
  additionalInfo: Maybe<AdditionalInfo>;
  redirectUrl: Maybe<Scalars['String']['output']>;
};

/** Type of a refund members action */
export enum RefundMembersAction {
  removeAll = 'removeAll',
  retainMembersAsPaid = 'retainMembersAsPaid'
}

/** Type of a refundReason */
export enum RefundReason {
  duplicate = 'duplicate',
  fraudulent = 'fraudulent',
  requested_by_customer = 'requested_by_customer'
}

export type RefundTransactionsInput = {
  amount: Scalars['Float']['input'];
  cancelInstalments?: InputMaybe<Scalars['Boolean']['input']>;
  eventTicketIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  membersAction?: InputMaybe<RefundMembersAction>;
  paymentId: Scalars['Float']['input'];
  reason: RefundReason;
};

export enum RepetitionType {
  biMonthly = 'biMonthly',
  everyMonth = 'everyMonth',
  everyTwoWeeks = 'everyTwoWeeks',
  everyWeek = 'everyWeek',
  oneTime = 'oneTime'
}

export type Response = {
  __typename?: 'Response';
  message: GroupMessage;
  response: Scalars['Boolean']['output'];
  user: PublicUserType;
};

export type RoundToFinish = {
  __typename?: 'RoundToFinish';
  event: FundraiserEvent;
  matchThreeTicketsAmount: Scalars['Float']['output'];
  soldTicketsAmount: Scalars['Float']['output'];
  winnerTickets: Array<WinnerTicketWithCombination>;
};

export type SalesData = {
  __typename?: 'SalesData';
  numberSold: Maybe<Scalars['Float']['output']>;
  sumValues: Maybe<Array<Scalars['Float']['output']>>;
  timeRange: TimeRange;
  valueSold: Maybe<Scalars['Float']['output']>;
};

export type Schedule = {
  __typename?: 'Schedule';
  active: Scalars['Boolean']['output'];
  article: Article;
  club: Club;
  event: Event;
  firedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  itemType: ScheduleItemType;
  membershipType: MembershipType;
  message: GroupMessage;
  notificationType: Maybe<ScheduleNotificationType>;
  scheduleDate: Scalars['DateTime']['output'];
  type: ScheduleType;
};

export type ScheduleInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  mode: SchedulingMode;
};

/** Type of a schedule item */
export enum ScheduleItemType {
  article = 'article',
  event = 'event',
  membership = 'membership',
  message = 'message'
}

/** Type of a schedule notification */
export enum ScheduleNotificationType {
  email = 'email',
  push = 'push',
  sms = 'sms'
}

/** Type of a schedule */
export enum ScheduleType {
  notification = 'notification',
  publish = 'publish'
}

/** Mode of a schedule */
export enum SchedulingMode {
  immediate = 'immediate',
  none = 'none',
  scheduled = 'scheduled'
}

export type SendUnregisteredMembersNotificationResponse = {
  __typename?: 'SendUnregisteredMembersNotificationResponse';
  lastUnregisteredMembersNotificationDate: Maybe<Scalars['DateTime']['output']>;
  notifiedUsersCount: Scalars['Float']['output'];
};

export type SenderIdInput = {
  senderId: Scalars['String']['input'];
};

export type Settings = {
  __typename?: 'Settings';
  features: Features;
};

export type SmsMessage = {
  __typename?: 'SmsMessage';
  club: Maybe<Club>;
  error: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  notificationInstance: Maybe<NotificationInstance>;
  phoneNumber: Scalars['String']['output'];
  sendOn: Scalars['DateTime']['output'];
  sentOn: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<Scalars['String']['output']>;
  twilioMessageId: Maybe<Scalars['String']['output']>;
  twilioNotificationId: Maybe<Scalars['String']['output']>;
};

export type SocialMediaArticlePostInput = {
  facebook: Scalars['Boolean']['input'];
  instagram: Scalars['Boolean']['input'];
  twitter: Scalars['Boolean']['input'];
};

export type SocialMediaAuth = {
  __typename?: 'SocialMediaAuth';
  accessToken: Scalars['String']['output'];
  accountName: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  facebookUserId: Maybe<Scalars['String']['output']>;
  instagramUserId: Maybe<Scalars['String']['output']>;
  pageAccessToken: Maybe<Scalars['String']['output']>;
  pageId: Maybe<Scalars['String']['output']>;
  refreshToken: Maybe<Scalars['String']['output']>;
};

export type SocialMediaConnection = {
  __typename?: 'SocialMediaConnection';
  auth: Maybe<SocialMediaAuth>;
  club: Club;
  id: Scalars['Float']['output'];
  lastUsedAt: Maybe<Scalars['DateTime']['output']>;
  platform: SocialMediaPlatform;
};

/** Social media platform */
export enum SocialMediaPlatform {
  facebook = 'facebook',
  instagram = 'instagram',
  twitter = 'twitter'
}

export type SocialMediaPostInput = {
  facebook: Scalars['Boolean']['input'];
  instagram: Scalars['Boolean']['input'];
  twitter: Scalars['Boolean']['input'];
};

export type SocialMediaShareArticleSettings = {
  __typename?: 'SocialMediaShareArticleSettings';
  facebook: Maybe<Scalars['Boolean']['output']>;
  instagram: Maybe<Scalars['Boolean']['output']>;
  twitter: Maybe<Scalars['Boolean']['output']>;
};

export type SocialMediaShareSettings = {
  __typename?: 'SocialMediaShareSettings';
  facebook: Maybe<Scalars['Boolean']['output']>;
  instagram: Maybe<Scalars['Boolean']['output']>;
  twitter: Maybe<Scalars['Boolean']['output']>;
};

export type Sponsorship = {
  __typename?: 'Sponsorship';
  bannerImageSource: Scalars['String']['output'];
  bannerUrl: Scalars['String']['output'];
  club: Club;
  id: Scalars['Float']['output'];
  visible: Scalars['Boolean']['output'];
};

export type SponsorshipInput = {
  bannerImageSource: Scalars['String']['input'];
  bannerUrl: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  availableBalance: StripeAvailableBalance;
  currentlyDue: Scalars['Boolean']['output'];
  detailsSubmitted: Scalars['Boolean']['output'];
  disabledReason: Maybe<Scalars['String']['output']>;
  errors: Scalars['Boolean']['output'];
  externalAccounts: Array<StripeExternalAccount>;
  isPayoutManual: Scalars['Boolean']['output'];
  payoutsEnabled: Scalars['Boolean']['output'];
  pendingVerification: Scalars['Boolean']['output'];
};

export type StripeAvailableBalance = {
  __typename?: 'StripeAvailableBalance';
  amount: Scalars['Float']['output'];
  currency: Currency;
};

export type StripeConfig = {
  __typename?: 'StripeConfig';
  clubs: Array<Club>;
  config: StripeConfigField;
  country: Country;
  id: Scalars['Float']['output'];
  stripeFee: Scalars['Float']['output'];
  stripeFeeVAT: Scalars['Float']['output'];
  stripeWebhookIdentifier: StripeWebhookIdentifier;
  useForClubCreation: Scalars['Boolean']['output'];
};

export type StripeConfigField = {
  __typename?: 'StripeConfigField';
  publicKey: Scalars['String']['output'];
  secretKey: Scalars['String']['output'];
  webhookSignature: Scalars['String']['output'];
};

export type StripeExternalAccount = {
  __typename?: 'StripeExternalAccount';
  bankName: Scalars['String']['output'];
  currency: Currency;
  defaultForCurrency: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export enum StripeWebhookIdentifier {
  WEBHOOK_IRELAND = 'WEBHOOK_IRELAND',
  WEBHOOK_UK = 'WEBHOOK_UK'
}

export type SummarizedResponses = {
  __typename?: 'SummarizedResponses';
  awaiting: Scalars['Float']['output'];
  no: Scalars['Float']['output'];
  yes: Scalars['Float']['output'];
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  apple: TermsAndConditionsData;
  google: TermsAndConditionsData;
};

export type TermsAndConditionsData = {
  __typename?: 'TermsAndConditionsData';
  fileUrl: Maybe<Scalars['String']['output']>;
  lastModified: Maybe<Scalars['DateTime']['output']>;
};

/** Where ticket was bought from */
export enum TicketBoughtFrom {
  imported = 'imported',
  membershipBundle = 'membershipBundle',
  mobile = 'mobile',
  web = 'web'
}

export enum TicketColor {
  BLUE = 'BLUE',
  DEFAULT = 'DEFAULT',
  GREEN = 'GREEN',
  RED = 'RED'
}

/** Types of lotto or raffle tickets tickets */
export enum TicketType {
  combination = 'combination',
  quantityBased = 'quantityBased',
  timeBased = 'timeBased'
}

export type TicketTypePublic = {
  __typename?: 'TicketTypePublic';
  currency: Currency;
  entries: Scalars['Int']['output'];
  fundraiser: Fundraiser;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  numberOfRounds: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
};

export type TicketTypesInput = {
  entries: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  numberOfRounds: Scalars['Float']['input'];
  price: Scalars['Float']['input'];
};

export type TicketWithLineItems = {
  __typename?: 'TicketWithLineItems';
  ticketId: Scalars['Float']['output'];
  ticketLineId: Scalars['Float']['output'];
  ticketLineItems: Array<Scalars['Float']['output']>;
  ticketTypeId: Scalars['Float']['output'];
};

/** Which ticketing service is used for event tickets */
export enum TicketingType {
  clubspot = 'clubspot',
  tixserve = 'tixserve'
}

export type TimeMetadataPublic = {
  __typename?: 'TimeMetadataPublic';
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Possible time ranges for statistics */
export enum TimeRange {
  month = 'month',
  week = 'week',
  year = 'year'
}

/** Timezone enum */
export enum Timezone {
  AfricaAbidjan = 'AfricaAbidjan',
  AfricaAccra = 'AfricaAccra',
  AfricaAddisAbaba = 'AfricaAddisAbaba',
  AfricaAlgiers = 'AfricaAlgiers',
  AfricaAsmara = 'AfricaAsmara',
  AfricaAsmera = 'AfricaAsmera',
  AfricaBamako = 'AfricaBamako',
  AfricaBangui = 'AfricaBangui',
  AfricaBanjul = 'AfricaBanjul',
  AfricaBissau = 'AfricaBissau',
  AfricaBlantyre = 'AfricaBlantyre',
  AfricaBrazzaville = 'AfricaBrazzaville',
  AfricaBujumbura = 'AfricaBujumbura',
  AfricaCairo = 'AfricaCairo',
  AfricaCasablanca = 'AfricaCasablanca',
  AfricaCeuta = 'AfricaCeuta',
  AfricaConakry = 'AfricaConakry',
  AfricaDakar = 'AfricaDakar',
  AfricaDaresSalaam = 'AfricaDaresSalaam',
  AfricaDjibouti = 'AfricaDjibouti',
  AfricaDouala = 'AfricaDouala',
  AfricaElAaiun = 'AfricaElAaiun',
  AfricaFreetown = 'AfricaFreetown',
  AfricaGaborone = 'AfricaGaborone',
  AfricaHarare = 'AfricaHarare',
  AfricaJohannesburg = 'AfricaJohannesburg',
  AfricaJuba = 'AfricaJuba',
  AfricaKampala = 'AfricaKampala',
  AfricaKhartoum = 'AfricaKhartoum',
  AfricaKigali = 'AfricaKigali',
  AfricaKinshasa = 'AfricaKinshasa',
  AfricaLagos = 'AfricaLagos',
  AfricaLibreville = 'AfricaLibreville',
  AfricaLome = 'AfricaLome',
  AfricaLuanda = 'AfricaLuanda',
  AfricaLubumbashi = 'AfricaLubumbashi',
  AfricaLusaka = 'AfricaLusaka',
  AfricaMalabo = 'AfricaMalabo',
  AfricaMaputo = 'AfricaMaputo',
  AfricaMaseru = 'AfricaMaseru',
  AfricaMbabane = 'AfricaMbabane',
  AfricaMogadishu = 'AfricaMogadishu',
  AfricaMonrovia = 'AfricaMonrovia',
  AfricaNairobi = 'AfricaNairobi',
  AfricaNdjamena = 'AfricaNdjamena',
  AfricaNiamey = 'AfricaNiamey',
  AfricaNouakchott = 'AfricaNouakchott',
  AfricaOuagadougou = 'AfricaOuagadougou',
  AfricaPortoNovo = 'AfricaPortoNovo',
  AfricaSaoTome = 'AfricaSaoTome',
  AfricaTimbuktu = 'AfricaTimbuktu',
  AfricaTripoli = 'AfricaTripoli',
  AfricaTunis = 'AfricaTunis',
  AfricaWindhoek = 'AfricaWindhoek',
  AmericaAdak = 'AmericaAdak',
  AmericaAnchorage = 'AmericaAnchorage',
  AmericaAnguilla = 'AmericaAnguilla',
  AmericaAntigua = 'AmericaAntigua',
  AmericaAraguaina = 'AmericaAraguaina',
  AmericaArgentinaBuenosAires = 'AmericaArgentinaBuenosAires',
  AmericaArgentinaCatamarca = 'AmericaArgentinaCatamarca',
  AmericaArgentinaComodRivadavia = 'AmericaArgentinaComodRivadavia',
  AmericaArgentinaCordoba = 'AmericaArgentinaCordoba',
  AmericaArgentinaJujuy = 'AmericaArgentinaJujuy',
  AmericaArgentinaLaRioja = 'AmericaArgentinaLaRioja',
  AmericaArgentinaMendoza = 'AmericaArgentinaMendoza',
  AmericaArgentinaRioGallegos = 'AmericaArgentinaRioGallegos',
  AmericaArgentinaSalta = 'AmericaArgentinaSalta',
  AmericaArgentinaSanJuan = 'AmericaArgentinaSanJuan',
  AmericaArgentinaSanLuis = 'AmericaArgentinaSanLuis',
  AmericaArgentinaTucuman = 'AmericaArgentinaTucuman',
  AmericaArgentinaUshuaia = 'AmericaArgentinaUshuaia',
  AmericaAruba = 'AmericaAruba',
  AmericaAsuncion = 'AmericaAsuncion',
  AmericaAtikokan = 'AmericaAtikokan',
  AmericaAtka = 'AmericaAtka',
  AmericaBahia = 'AmericaBahia',
  AmericaBahiaBanderas = 'AmericaBahiaBanderas',
  AmericaBarbados = 'AmericaBarbados',
  AmericaBelem = 'AmericaBelem',
  AmericaBelize = 'AmericaBelize',
  AmericaBlancSablon = 'AmericaBlancSablon',
  AmericaBoaVista = 'AmericaBoaVista',
  AmericaBogota = 'AmericaBogota',
  AmericaBoise = 'AmericaBoise',
  AmericaBuenosAires = 'AmericaBuenosAires',
  AmericaCambridgeBay = 'AmericaCambridgeBay',
  AmericaCampoGrande = 'AmericaCampoGrande',
  AmericaCancun = 'AmericaCancun',
  AmericaCaracas = 'AmericaCaracas',
  AmericaCatamarca = 'AmericaCatamarca',
  AmericaCayenne = 'AmericaCayenne',
  AmericaCayman = 'AmericaCayman',
  AmericaChicago = 'AmericaChicago',
  AmericaChihuahua = 'AmericaChihuahua',
  AmericaCoralHarbour = 'AmericaCoralHarbour',
  AmericaCordoba = 'AmericaCordoba',
  AmericaCostaRica = 'AmericaCostaRica',
  AmericaCreston = 'AmericaCreston',
  AmericaCuiaba = 'AmericaCuiaba',
  AmericaCuracao = 'AmericaCuracao',
  AmericaDanmarkshavn = 'AmericaDanmarkshavn',
  AmericaDawson = 'AmericaDawson',
  AmericaDawsonCreek = 'AmericaDawsonCreek',
  AmericaDenver = 'AmericaDenver',
  AmericaDetroit = 'AmericaDetroit',
  AmericaDominica = 'AmericaDominica',
  AmericaEdmonton = 'AmericaEdmonton',
  AmericaEirunepe = 'AmericaEirunepe',
  AmericaElSalvador = 'AmericaElSalvador',
  AmericaEnsenada = 'AmericaEnsenada',
  AmericaFortNelson = 'AmericaFortNelson',
  AmericaFortWayne = 'AmericaFortWayne',
  AmericaFortaleza = 'AmericaFortaleza',
  AmericaGlaceBay = 'AmericaGlaceBay',
  AmericaGodthab = 'AmericaGodthab',
  AmericaGooseBay = 'AmericaGooseBay',
  AmericaGrandTurk = 'AmericaGrandTurk',
  AmericaGrenada = 'AmericaGrenada',
  AmericaGuadeloupe = 'AmericaGuadeloupe',
  AmericaGuatemala = 'AmericaGuatemala',
  AmericaGuayaquil = 'AmericaGuayaquil',
  AmericaGuyana = 'AmericaGuyana',
  AmericaHalifax = 'AmericaHalifax',
  AmericaHavana = 'AmericaHavana',
  AmericaHermosillo = 'AmericaHermosillo',
  AmericaIndianaIndianapolis = 'AmericaIndianaIndianapolis',
  AmericaIndianaKnox = 'AmericaIndianaKnox',
  AmericaIndianaMarengo = 'AmericaIndianaMarengo',
  AmericaIndianaPetersburg = 'AmericaIndianaPetersburg',
  AmericaIndianaTellCity = 'AmericaIndianaTellCity',
  AmericaIndianaVevay = 'AmericaIndianaVevay',
  AmericaIndianaVincennes = 'AmericaIndianaVincennes',
  AmericaIndianaWinamac = 'AmericaIndianaWinamac',
  AmericaIndianapolis = 'AmericaIndianapolis',
  AmericaInuvik = 'AmericaInuvik',
  AmericaIqaluit = 'AmericaIqaluit',
  AmericaJamaica = 'AmericaJamaica',
  AmericaJujuy = 'AmericaJujuy',
  AmericaJuneau = 'AmericaJuneau',
  AmericaKentuckyLouisville = 'AmericaKentuckyLouisville',
  AmericaKentuckyMonticello = 'AmericaKentuckyMonticello',
  AmericaKnoxIN = 'AmericaKnoxIN',
  AmericaKralendijk = 'AmericaKralendijk',
  AmericaLaPaz = 'AmericaLaPaz',
  AmericaLima = 'AmericaLima',
  AmericaLosAngeles = 'AmericaLosAngeles',
  AmericaLouisville = 'AmericaLouisville',
  AmericaLowerPrinces = 'AmericaLowerPrinces',
  AmericaMaceio = 'AmericaMaceio',
  AmericaManagua = 'AmericaManagua',
  AmericaManaus = 'AmericaManaus',
  AmericaMarigot = 'AmericaMarigot',
  AmericaMartinique = 'AmericaMartinique',
  AmericaMatamoros = 'AmericaMatamoros',
  AmericaMazatlan = 'AmericaMazatlan',
  AmericaMendoza = 'AmericaMendoza',
  AmericaMenominee = 'AmericaMenominee',
  AmericaMerida = 'AmericaMerida',
  AmericaMetlakatla = 'AmericaMetlakatla',
  AmericaMexicoCity = 'AmericaMexicoCity',
  AmericaMiquelon = 'AmericaMiquelon',
  AmericaMoncton = 'AmericaMoncton',
  AmericaMonterrey = 'AmericaMonterrey',
  AmericaMontevideo = 'AmericaMontevideo',
  AmericaMontreal = 'AmericaMontreal',
  AmericaMontserrat = 'AmericaMontserrat',
  AmericaNassau = 'AmericaNassau',
  AmericaNewYork = 'AmericaNewYork',
  AmericaNipigon = 'AmericaNipigon',
  AmericaNome = 'AmericaNome',
  AmericaNoronha = 'AmericaNoronha',
  AmericaNorthDakotaBeulah = 'AmericaNorthDakotaBeulah',
  AmericaNorthDakotaCenter = 'AmericaNorthDakotaCenter',
  AmericaNorthDakotaNewSalem = 'AmericaNorthDakotaNewSalem',
  AmericaNuuk = 'AmericaNuuk',
  AmericaOjinaga = 'AmericaOjinaga',
  AmericaPanama = 'AmericaPanama',
  AmericaPangnirtung = 'AmericaPangnirtung',
  AmericaParamaribo = 'AmericaParamaribo',
  AmericaPhoenix = 'AmericaPhoenix',
  AmericaPortauPrince = 'AmericaPortauPrince',
  AmericaPortoAcre = 'AmericaPortoAcre',
  AmericaPortoVelho = 'AmericaPortoVelho',
  AmericaPortofSpain = 'AmericaPortofSpain',
  AmericaPuertoRico = 'AmericaPuertoRico',
  AmericaPuntaArenas = 'AmericaPuntaArenas',
  AmericaRainyRiver = 'AmericaRainyRiver',
  AmericaRankinInlet = 'AmericaRankinInlet',
  AmericaRecife = 'AmericaRecife',
  AmericaRegina = 'AmericaRegina',
  AmericaResolute = 'AmericaResolute',
  AmericaRioBranco = 'AmericaRioBranco',
  AmericaRosario = 'AmericaRosario',
  AmericaSantaIsabel = 'AmericaSantaIsabel',
  AmericaSantarem = 'AmericaSantarem',
  AmericaSantiago = 'AmericaSantiago',
  AmericaSantoDomingo = 'AmericaSantoDomingo',
  AmericaSaoPaulo = 'AmericaSaoPaulo',
  AmericaScoresbysund = 'AmericaScoresbysund',
  AmericaShiprock = 'AmericaShiprock',
  AmericaSitka = 'AmericaSitka',
  AmericaStBarthelemy = 'AmericaStBarthelemy',
  AmericaStJohns = 'AmericaStJohns',
  AmericaStKitts = 'AmericaStKitts',
  AmericaStLucia = 'AmericaStLucia',
  AmericaStThomas = 'AmericaStThomas',
  AmericaStVincent = 'AmericaStVincent',
  AmericaSwiftCurrent = 'AmericaSwiftCurrent',
  AmericaTegucigalpa = 'AmericaTegucigalpa',
  AmericaThule = 'AmericaThule',
  AmericaThunderBay = 'AmericaThunderBay',
  AmericaTijuana = 'AmericaTijuana',
  AmericaToronto = 'AmericaToronto',
  AmericaTortola = 'AmericaTortola',
  AmericaVancouver = 'AmericaVancouver',
  AmericaVirgin = 'AmericaVirgin',
  AmericaWhitehorse = 'AmericaWhitehorse',
  AmericaWinnipeg = 'AmericaWinnipeg',
  AmericaYakutat = 'AmericaYakutat',
  AmericaYellowknife = 'AmericaYellowknife',
  AntarcticaCasey = 'AntarcticaCasey',
  AntarcticaDavis = 'AntarcticaDavis',
  AntarcticaDumontDUrville = 'AntarcticaDumontDUrville',
  AntarcticaMacquarie = 'AntarcticaMacquarie',
  AntarcticaMawson = 'AntarcticaMawson',
  AntarcticaMcMurdo = 'AntarcticaMcMurdo',
  AntarcticaPalmer = 'AntarcticaPalmer',
  AntarcticaRothera = 'AntarcticaRothera',
  AntarcticaSouthPole = 'AntarcticaSouthPole',
  AntarcticaSyowa = 'AntarcticaSyowa',
  AntarcticaTroll = 'AntarcticaTroll',
  AntarcticaVostok = 'AntarcticaVostok',
  ArcticLongyearbyen = 'ArcticLongyearbyen',
  AsiaAden = 'AsiaAden',
  AsiaAlmaty = 'AsiaAlmaty',
  AsiaAmman = 'AsiaAmman',
  AsiaAnadyr = 'AsiaAnadyr',
  AsiaAqtau = 'AsiaAqtau',
  AsiaAqtobe = 'AsiaAqtobe',
  AsiaAshgabat = 'AsiaAshgabat',
  AsiaAshkhabad = 'AsiaAshkhabad',
  AsiaAtyrau = 'AsiaAtyrau',
  AsiaBaghdad = 'AsiaBaghdad',
  AsiaBahrain = 'AsiaBahrain',
  AsiaBaku = 'AsiaBaku',
  AsiaBangkok = 'AsiaBangkok',
  AsiaBarnaul = 'AsiaBarnaul',
  AsiaBeirut = 'AsiaBeirut',
  AsiaBishkek = 'AsiaBishkek',
  AsiaBrunei = 'AsiaBrunei',
  AsiaCalcutta = 'AsiaCalcutta',
  AsiaChita = 'AsiaChita',
  AsiaChoibalsan = 'AsiaChoibalsan',
  AsiaChongqing = 'AsiaChongqing',
  AsiaChungking = 'AsiaChungking',
  AsiaColombo = 'AsiaColombo',
  AsiaDacca = 'AsiaDacca',
  AsiaDamascus = 'AsiaDamascus',
  AsiaDhaka = 'AsiaDhaka',
  AsiaDili = 'AsiaDili',
  AsiaDubai = 'AsiaDubai',
  AsiaDushanbe = 'AsiaDushanbe',
  AsiaFamagusta = 'AsiaFamagusta',
  AsiaGaza = 'AsiaGaza',
  AsiaHarbin = 'AsiaHarbin',
  AsiaHebron = 'AsiaHebron',
  AsiaHoChiMinh = 'AsiaHoChiMinh',
  AsiaHongKong = 'AsiaHongKong',
  AsiaHovd = 'AsiaHovd',
  AsiaIrkutsk = 'AsiaIrkutsk',
  AsiaIstanbul = 'AsiaIstanbul',
  AsiaJakarta = 'AsiaJakarta',
  AsiaJayapura = 'AsiaJayapura',
  AsiaJerusalem = 'AsiaJerusalem',
  AsiaKabul = 'AsiaKabul',
  AsiaKamchatka = 'AsiaKamchatka',
  AsiaKarachi = 'AsiaKarachi',
  AsiaKashgar = 'AsiaKashgar',
  AsiaKathmandu = 'AsiaKathmandu',
  AsiaKatmandu = 'AsiaKatmandu',
  AsiaKhandyga = 'AsiaKhandyga',
  AsiaKolkata = 'AsiaKolkata',
  AsiaKrasnoyarsk = 'AsiaKrasnoyarsk',
  AsiaKualaLumpur = 'AsiaKualaLumpur',
  AsiaKuching = 'AsiaKuching',
  AsiaKuwait = 'AsiaKuwait',
  AsiaMacao = 'AsiaMacao',
  AsiaMacau = 'AsiaMacau',
  AsiaMagadan = 'AsiaMagadan',
  AsiaMakassar = 'AsiaMakassar',
  AsiaManila = 'AsiaManila',
  AsiaMuscat = 'AsiaMuscat',
  AsiaNicosia = 'AsiaNicosia',
  AsiaNovokuznetsk = 'AsiaNovokuznetsk',
  AsiaNovosibirsk = 'AsiaNovosibirsk',
  AsiaOmsk = 'AsiaOmsk',
  AsiaOral = 'AsiaOral',
  AsiaPhnomPenh = 'AsiaPhnomPenh',
  AsiaPontianak = 'AsiaPontianak',
  AsiaPyongyang = 'AsiaPyongyang',
  AsiaQatar = 'AsiaQatar',
  AsiaQostanay = 'AsiaQostanay',
  AsiaQyzylorda = 'AsiaQyzylorda',
  AsiaRangoon = 'AsiaRangoon',
  AsiaRiyadh = 'AsiaRiyadh',
  AsiaSaigon = 'AsiaSaigon',
  AsiaSakhalin = 'AsiaSakhalin',
  AsiaSamarkand = 'AsiaSamarkand',
  AsiaSeoul = 'AsiaSeoul',
  AsiaShanghai = 'AsiaShanghai',
  AsiaSingapore = 'AsiaSingapore',
  AsiaSrednekolymsk = 'AsiaSrednekolymsk',
  AsiaTaipei = 'AsiaTaipei',
  AsiaTashkent = 'AsiaTashkent',
  AsiaTbilisi = 'AsiaTbilisi',
  AsiaTehran = 'AsiaTehran',
  AsiaTelAviv = 'AsiaTelAviv',
  AsiaThimbu = 'AsiaThimbu',
  AsiaThimphu = 'AsiaThimphu',
  AsiaTokyo = 'AsiaTokyo',
  AsiaTomsk = 'AsiaTomsk',
  AsiaUjungPandang = 'AsiaUjungPandang',
  AsiaUlaanbaatar = 'AsiaUlaanbaatar',
  AsiaUlanBator = 'AsiaUlanBator',
  AsiaUrumqi = 'AsiaUrumqi',
  AsiaUstNera = 'AsiaUstNera',
  AsiaVientiane = 'AsiaVientiane',
  AsiaVladivostok = 'AsiaVladivostok',
  AsiaYakutsk = 'AsiaYakutsk',
  AsiaYangon = 'AsiaYangon',
  AsiaYekaterinburg = 'AsiaYekaterinburg',
  AsiaYerevan = 'AsiaYerevan',
  AtlanticAzores = 'AtlanticAzores',
  AtlanticBermuda = 'AtlanticBermuda',
  AtlanticCanary = 'AtlanticCanary',
  AtlanticCapeVerde = 'AtlanticCapeVerde',
  AtlanticFaeroe = 'AtlanticFaeroe',
  AtlanticFaroe = 'AtlanticFaroe',
  AtlanticJanMayen = 'AtlanticJanMayen',
  AtlanticMadeira = 'AtlanticMadeira',
  AtlanticReykjavik = 'AtlanticReykjavik',
  AtlanticSouthGeorgia = 'AtlanticSouthGeorgia',
  AtlanticStHelena = 'AtlanticStHelena',
  AtlanticStanley = 'AtlanticStanley',
  AustraliaACT = 'AustraliaACT',
  AustraliaAdelaide = 'AustraliaAdelaide',
  AustraliaBrisbane = 'AustraliaBrisbane',
  AustraliaBrokenHill = 'AustraliaBrokenHill',
  AustraliaCanberra = 'AustraliaCanberra',
  AustraliaCurrie = 'AustraliaCurrie',
  AustraliaDarwin = 'AustraliaDarwin',
  AustraliaEucla = 'AustraliaEucla',
  AustraliaHobart = 'AustraliaHobart',
  AustraliaLHI = 'AustraliaLHI',
  AustraliaLindeman = 'AustraliaLindeman',
  AustraliaLordHowe = 'AustraliaLordHowe',
  AustraliaMelbourne = 'AustraliaMelbourne',
  AustraliaNSW = 'AustraliaNSW',
  AustraliaNorth = 'AustraliaNorth',
  AustraliaPerth = 'AustraliaPerth',
  AustraliaQueensland = 'AustraliaQueensland',
  AustraliaSouth = 'AustraliaSouth',
  AustraliaSydney = 'AustraliaSydney',
  AustraliaTasmania = 'AustraliaTasmania',
  AustraliaVictoria = 'AustraliaVictoria',
  AustraliaWest = 'AustraliaWest',
  AustraliaYancowinna = 'AustraliaYancowinna',
  BrazilAcre = 'BrazilAcre',
  BrazilDeNoronha = 'BrazilDeNoronha',
  BrazilEast = 'BrazilEast',
  BrazilWest = 'BrazilWest',
  CET = 'CET',
  CST6CDT = 'CST6CDT',
  CanadaAtlantic = 'CanadaAtlantic',
  CanadaCentral = 'CanadaCentral',
  CanadaEastern = 'CanadaEastern',
  CanadaMountain = 'CanadaMountain',
  CanadaNewfoundland = 'CanadaNewfoundland',
  CanadaPacific = 'CanadaPacific',
  CanadaSaskatchewan = 'CanadaSaskatchewan',
  CanadaYukon = 'CanadaYukon',
  ChileContinental = 'ChileContinental',
  ChileEasterIsland = 'ChileEasterIsland',
  Cuba = 'Cuba',
  EET = 'EET',
  EST = 'EST',
  EST5EDT = 'EST5EDT',
  Egypt = 'Egypt',
  Eire = 'Eire',
  EtcGMT = 'EtcGMT',
  EtcGMT0 = 'EtcGMT0',
  EtcGMTMinus0 = 'EtcGMTMinus0',
  EtcGMTMinus1 = 'EtcGMTMinus1',
  EtcGMTMinus2 = 'EtcGMTMinus2',
  EtcGMTMinus3 = 'EtcGMTMinus3',
  EtcGMTMinus4 = 'EtcGMTMinus4',
  EtcGMTMinus5 = 'EtcGMTMinus5',
  EtcGMTMinus6 = 'EtcGMTMinus6',
  EtcGMTMinus7 = 'EtcGMTMinus7',
  EtcGMTMinus8 = 'EtcGMTMinus8',
  EtcGMTMinus9 = 'EtcGMTMinus9',
  EtcGMTMinus10 = 'EtcGMTMinus10',
  EtcGMTMinus11 = 'EtcGMTMinus11',
  EtcGMTMinus12 = 'EtcGMTMinus12',
  EtcGMTMinus13 = 'EtcGMTMinus13',
  EtcGMTMinus14 = 'EtcGMTMinus14',
  EtcGMTPlus0 = 'EtcGMTPlus0',
  EtcGMTPlus1 = 'EtcGMTPlus1',
  EtcGMTPlus2 = 'EtcGMTPlus2',
  EtcGMTPlus3 = 'EtcGMTPlus3',
  EtcGMTPlus4 = 'EtcGMTPlus4',
  EtcGMTPlus5 = 'EtcGMTPlus5',
  EtcGMTPlus6 = 'EtcGMTPlus6',
  EtcGMTPlus7 = 'EtcGMTPlus7',
  EtcGMTPlus8 = 'EtcGMTPlus8',
  EtcGMTPlus9 = 'EtcGMTPlus9',
  EtcGMTPlus10 = 'EtcGMTPlus10',
  EtcGMTPlus11 = 'EtcGMTPlus11',
  EtcGMTPlus12 = 'EtcGMTPlus12',
  EtcGreenwich = 'EtcGreenwich',
  EtcUCT = 'EtcUCT',
  EtcUTC = 'EtcUTC',
  EtcUniversal = 'EtcUniversal',
  EtcZulu = 'EtcZulu',
  EuropeAmsterdam = 'EuropeAmsterdam',
  EuropeAndorra = 'EuropeAndorra',
  EuropeAstrakhan = 'EuropeAstrakhan',
  EuropeAthens = 'EuropeAthens',
  EuropeBelfast = 'EuropeBelfast',
  EuropeBelgrade = 'EuropeBelgrade',
  EuropeBerlin = 'EuropeBerlin',
  EuropeBratislava = 'EuropeBratislava',
  EuropeBrussels = 'EuropeBrussels',
  EuropeBucharest = 'EuropeBucharest',
  EuropeBudapest = 'EuropeBudapest',
  EuropeBusingen = 'EuropeBusingen',
  EuropeChisinau = 'EuropeChisinau',
  EuropeCopenhagen = 'EuropeCopenhagen',
  EuropeDublin = 'EuropeDublin',
  EuropeGibraltar = 'EuropeGibraltar',
  EuropeGuernsey = 'EuropeGuernsey',
  EuropeHelsinki = 'EuropeHelsinki',
  EuropeIsleofMan = 'EuropeIsleofMan',
  EuropeIstanbul = 'EuropeIstanbul',
  EuropeJersey = 'EuropeJersey',
  EuropeKaliningrad = 'EuropeKaliningrad',
  EuropeKiev = 'EuropeKiev',
  EuropeKirov = 'EuropeKirov',
  EuropeLisbon = 'EuropeLisbon',
  EuropeLjubljana = 'EuropeLjubljana',
  EuropeLondon = 'EuropeLondon',
  EuropeLuxembourg = 'EuropeLuxembourg',
  EuropeMadrid = 'EuropeMadrid',
  EuropeMalta = 'EuropeMalta',
  EuropeMariehamn = 'EuropeMariehamn',
  EuropeMinsk = 'EuropeMinsk',
  EuropeMonaco = 'EuropeMonaco',
  EuropeMoscow = 'EuropeMoscow',
  EuropeNicosia = 'EuropeNicosia',
  EuropeOslo = 'EuropeOslo',
  EuropeParis = 'EuropeParis',
  EuropePodgorica = 'EuropePodgorica',
  EuropePrague = 'EuropePrague',
  EuropeRiga = 'EuropeRiga',
  EuropeRome = 'EuropeRome',
  EuropeSamara = 'EuropeSamara',
  EuropeSanMarino = 'EuropeSanMarino',
  EuropeSarajevo = 'EuropeSarajevo',
  EuropeSaratov = 'EuropeSaratov',
  EuropeSimferopol = 'EuropeSimferopol',
  EuropeSkopje = 'EuropeSkopje',
  EuropeSofia = 'EuropeSofia',
  EuropeStockholm = 'EuropeStockholm',
  EuropeTallinn = 'EuropeTallinn',
  EuropeTirane = 'EuropeTirane',
  EuropeTiraspol = 'EuropeTiraspol',
  EuropeUlyanovsk = 'EuropeUlyanovsk',
  EuropeUzhgorod = 'EuropeUzhgorod',
  EuropeVaduz = 'EuropeVaduz',
  EuropeVatican = 'EuropeVatican',
  EuropeVienna = 'EuropeVienna',
  EuropeVilnius = 'EuropeVilnius',
  EuropeVolgograd = 'EuropeVolgograd',
  EuropeWarsaw = 'EuropeWarsaw',
  EuropeZagreb = 'EuropeZagreb',
  EuropeZaporozhye = 'EuropeZaporozhye',
  EuropeZurich = 'EuropeZurich',
  GB = 'GB',
  GBEire = 'GBEire',
  GMT = 'GMT',
  GMT0 = 'GMT0',
  GMTMinus0 = 'GMTMinus0',
  GMTPlus0 = 'GMTPlus0',
  Greenwich = 'Greenwich',
  HST = 'HST',
  Hongkong = 'Hongkong',
  Iceland = 'Iceland',
  IndianAntananarivo = 'IndianAntananarivo',
  IndianChagos = 'IndianChagos',
  IndianChristmas = 'IndianChristmas',
  IndianCocos = 'IndianCocos',
  IndianComoro = 'IndianComoro',
  IndianKerguelen = 'IndianKerguelen',
  IndianMahe = 'IndianMahe',
  IndianMaldives = 'IndianMaldives',
  IndianMauritius = 'IndianMauritius',
  IndianMayotte = 'IndianMayotte',
  IndianReunion = 'IndianReunion',
  Iran = 'Iran',
  Israel = 'Israel',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Kwajalein = 'Kwajalein',
  Libya = 'Libya',
  MET = 'MET',
  MST = 'MST',
  MST7MDT = 'MST7MDT',
  MexicoBajaNorte = 'MexicoBajaNorte',
  MexicoBajaSur = 'MexicoBajaSur',
  MexicoGeneral = 'MexicoGeneral',
  NZ = 'NZ',
  NZCHAT = 'NZCHAT',
  Navajo = 'Navajo',
  PRC = 'PRC',
  PST8PDT = 'PST8PDT',
  PacificApia = 'PacificApia',
  PacificAuckland = 'PacificAuckland',
  PacificBougainville = 'PacificBougainville',
  PacificChatham = 'PacificChatham',
  PacificChuuk = 'PacificChuuk',
  PacificEaster = 'PacificEaster',
  PacificEfate = 'PacificEfate',
  PacificEnderbury = 'PacificEnderbury',
  PacificFakaofo = 'PacificFakaofo',
  PacificFiji = 'PacificFiji',
  PacificFunafuti = 'PacificFunafuti',
  PacificGalapagos = 'PacificGalapagos',
  PacificGambier = 'PacificGambier',
  PacificGuadalcanal = 'PacificGuadalcanal',
  PacificGuam = 'PacificGuam',
  PacificHonolulu = 'PacificHonolulu',
  PacificJohnston = 'PacificJohnston',
  PacificKiritimati = 'PacificKiritimati',
  PacificKosrae = 'PacificKosrae',
  PacificKwajalein = 'PacificKwajalein',
  PacificMajuro = 'PacificMajuro',
  PacificMarquesas = 'PacificMarquesas',
  PacificMidway = 'PacificMidway',
  PacificNauru = 'PacificNauru',
  PacificNiue = 'PacificNiue',
  PacificNorfolk = 'PacificNorfolk',
  PacificNoumea = 'PacificNoumea',
  PacificPagoPago = 'PacificPagoPago',
  PacificPalau = 'PacificPalau',
  PacificPitcairn = 'PacificPitcairn',
  PacificPohnpei = 'PacificPohnpei',
  PacificPonape = 'PacificPonape',
  PacificPortMoresby = 'PacificPortMoresby',
  PacificRarotonga = 'PacificRarotonga',
  PacificSaipan = 'PacificSaipan',
  PacificSamoa = 'PacificSamoa',
  PacificTahiti = 'PacificTahiti',
  PacificTarawa = 'PacificTarawa',
  PacificTongatapu = 'PacificTongatapu',
  PacificTruk = 'PacificTruk',
  PacificWake = 'PacificWake',
  PacificWallis = 'PacificWallis',
  PacificYap = 'PacificYap',
  Poland = 'Poland',
  Portugal = 'Portugal',
  ROC = 'ROC',
  ROK = 'ROK',
  Singapore = 'Singapore',
  Turkey = 'Turkey',
  UCT = 'UCT',
  USAlaska = 'USAlaska',
  USAleutian = 'USAleutian',
  USArizona = 'USArizona',
  USCentral = 'USCentral',
  USEastIndiana = 'USEastIndiana',
  USEastern = 'USEastern',
  USHawaii = 'USHawaii',
  USIndianaStarke = 'USIndianaStarke',
  USMichigan = 'USMichigan',
  USMountain = 'USMountain',
  USPacific = 'USPacific',
  USPacificNew = 'USPacificNew',
  USSamoa = 'USSamoa',
  UTC = 'UTC',
  Universal = 'Universal',
  WET = 'WET',
  WSU = 'WSU',
  Zulu = 'Zulu'
}

export type TimingInput = {
  offset?: InputMaybe<Scalars['Float']['input']>;
  triggerEvent: NotificationTimingType;
};

export type TransactionDetailsResponse = {
  __typename?: 'TransactionDetailsResponse';
  amount: Scalars['Float']['output'];
  currency: Currency;
  details: Maybe<Scalars['String']['output']>;
  eventTickets: Array<EventTicketTransactionDetails>;
  failureDetails: Maybe<Scalars['String']['output']>;
  fee: Scalars['Float']['output'];
  firstName: Scalars['String']['output'];
  fundraiserTickets: Array<FundraiserTicketTransactionDetails>;
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  memberships: Array<MembershipTransactionDetails>;
  netAmount: Scalars['Float']['output'];
  ordinalNumber: Scalars['Float']['output'];
  originalTransaction: Maybe<Payment>;
  paymentItemType: PaymentItemType;
  paymentStatusUpdatedAt: Scalars['DateTime']['output'];
  paymentType: PaymentType;
  refundReason: Maybe<RefundReason>;
  refundableAmount: Scalars['Float']['output'];
  refunds: Maybe<Array<Payment>>;
  status: PaymentStatus;
  userName: Scalars['String']['output'];
};

export type TransactionsCountResponse = {
  __typename?: 'TransactionsCountResponse';
  count: Scalars['Float']['output'];
};

export type TwilioConfig = {
  __typename?: 'TwilioConfig';
  accountSID: Scalars['String']['output'];
  authToken: Scalars['String']['output'];
};

/** GAA membership type */
export enum TypeGAA {
  FULL = 'FULL',
  G4MNO = 'G4MNO',
  HONOURARY = 'HONOURARY',
  SOCIAL = 'SOCIAL',
  YOUTH = 'YOUTH'
}

export type UpdateFormInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  schema: Scalars['JSON']['input'];
  uiSchema: Scalars['JSON']['input'];
};

export type UpdatePushnotificationInput = {
  apnToken?: InputMaybe<Scalars['String']['input']>;
  apnTokenKeyId?: InputMaybe<Scalars['String']['input']>;
  appleAppId?: InputMaybe<Scalars['String']['input']>;
  appleCompanyName?: InputMaybe<Scalars['String']['input']>;
  appleContentProviderId?: InputMaybe<Scalars['String']['input']>;
  appleDeveloperTeamId?: InputMaybe<Scalars['String']['input']>;
  appleTeamName?: InputMaybe<Scalars['String']['input']>;
  fcmId?: InputMaybe<Scalars['String']['input']>;
  firebaseAndroidAppId?: InputMaybe<Scalars['String']['input']>;
  firebaseIosAppId?: InputMaybe<Scalars['String']['input']>;
  firebaseProjectId?: InputMaybe<Scalars['String']['input']>;
  firebaseServiceKey?: InputMaybe<Scalars['String']['input']>;
  packageIdentifier?: InputMaybe<Scalars['String']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertBookmarkInput = {
  bookmarks: Array<BookmarkInput>;
};

export type User = {
  __typename?: 'User';
  appUser: Scalars['Boolean']['output'];
  birthDate: Maybe<Scalars['DateTime']['output']>;
  cancelledMemberships: Array<Membership>;
  cards: Array<CustomerCard>;
  club: Maybe<Club>;
  deviceTokens: Array<DeviceId>;
  email: Scalars['String']['output'];
  eventTickets: Array<EventTicket>;
  firstName: Scalars['String']['output'];
  fundraiserTickets: Array<FundraiserTicket>;
  gender: Maybe<Gender>;
  groups: Array<GroupMember>;
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  memberships: Array<Membership>;
  payments: Array<Payment>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  responses: Array<Response>;
  type: UserType;
  verified: Scalars['Boolean']['output'];
};

export type UserAutocompleteItem = {
  __typename?: 'UserAutocompleteItem';
  birthDate: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  type: UserType;
};

export type UserContribution = {
  __typename?: 'UserContribution';
  contribution: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Float']['output'];
};

export type UserDetails = {
  __typename?: 'UserDetails';
  appUser: Scalars['Boolean']['output'];
  birthDate: Maybe<Scalars['DateTime']['output']>;
  clubId: Scalars['Float']['output'];
  contribution: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: Maybe<Gender>;
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: UserType;
  verified: Scalars['Boolean']['output'];
};

/** Type of a user */
export enum UserType {
  admin = 'admin',
  club = 'club',
  fundraisingManager = 'fundraisingManager',
  manager = 'manager',
  member = 'member'
}

export type UsersNewRole = {
  roleToGrant: Scalars['String']['input'];
  userid: Scalars['Float']['input'];
};

export type ValidMembersFilter = {
  forGAAExport: Scalars['Boolean']['input'];
  membershipType?: InputMaybe<Scalars['Float']['input']>;
  validAfter?: InputMaybe<Scalars['String']['input']>;
};

export type ValidatedMembershipMemberDataInput = {
  address: AddressInput;
  associatedClub?: InputMaybe<Scalars['String']['input']>;
  association?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<MemberGender>;
  irishFirstName?: InputMaybe<Scalars['String']['input']>;
  irishLastName?: InputMaybe<Scalars['String']['input']>;
  isAnyMedicalCondition?: InputMaybe<Scalars['Boolean']['input']>;
  isPlayer?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  medicalInformation?: InputMaybe<Scalars['String']['input']>;
  ngbId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type WinnerTicketWithCombination = {
  __typename?: 'WinnerTicketWithCombination';
  combination: Array<Scalars['Float']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  ticketId: Scalars['Float']['output'];
  ticketLineId: Scalars['Float']['output'];
};

export type WinnerType = {
  __typename?: 'WinnerType';
  amount: Maybe<Scalars['Float']['output']>;
  currency: Maybe<Currency>;
  name: Maybe<Scalars['String']['output']>;
  prize: Maybe<RafflePrize>;
  ticketNumber: Maybe<Scalars['String']['output']>;
};

export type getCodeOfConductFileVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type getCodeOfConductFile = { __typename?: 'Query', getCodeOfConductFile: { __typename?: 'CodeOfConductData', fileUrl: string | null, lastModified: any | null } };

export type setUploadedCodeOfConductVariables = Exact<{
  clubId: Scalars['Float']['input'];
  fileUrl: Scalars['String']['input'];
}>;


export type setUploadedCodeOfConduct = { __typename?: 'Mutation', setUploadedCodeOfConduct: { __typename?: 'CodeOfConductData', fileUrl: string | null, lastModified: any | null } };

export type getSponsorshipsDataAdminVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type getSponsorshipsDataAdmin = { __typename?: 'Query', getSponsorshipsDataAdmin: Array<{ __typename?: 'Sponsorship', bannerImageSource: string, bannerUrl: string, visible: boolean }> | null };

export type editSponsorshipsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  input: Array<SponsorshipInput> | SponsorshipInput;
}>;


export type editSponsorships = { __typename?: 'Mutation', editSponsorships: Array<{ __typename?: 'Sponsorship', bannerImageSource: string, bannerUrl: string, visible: boolean }> };

export type getSmSSenderIdVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type getSmSSenderId = { __typename?: 'Query', getSmSSenderId: string };

export type updateSmSSenderIdVariables = Exact<{
  clubId: Scalars['Float']['input'];
  senderId: SenderIdInput;
}>;


export type updateSmSSenderId = { __typename?: 'Mutation', updateSmSSenderId: string };

export type modifyUserVariables = Exact<{
  userId: Scalars['Float']['input'];
  userInput: ModifyUserInput;
}>;


export type modifyUser = { __typename?: 'Mutation', modifyUser: { __typename?: 'User', id: number, firstName: string, lastName: string, email: string, phoneNumber: string | null, birthDate: any | null } };

export type changePasswordVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type changePassword = { __typename?: 'Mutation', changePassword: { __typename?: 'User', id: number } };

export type getUsersWithRoleVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type getUsersWithRole = { __typename?: 'Query', getClubUsersWithRole: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string, email: string, type: UserType, birthDate: any | null, groups: Array<{ __typename?: 'GroupMember', manager: boolean, group: { __typename?: 'Group', id: string, name: string } }> }> };

export type grantRolesVariables = Exact<{
  input: GrantRoleInput;
}>;


export type grantRoles = { __typename?: 'Mutation', grantRoles: { __typename?: 'GrantRole', data: Array<{ __typename?: 'User', id: number }> | null, error: { __typename?: 'GrantRoleError', message: string, translationKey: string, email: string } | null } };

export type revokeRoleVariables = Exact<{
  userId: Scalars['Float']['input'];
}>;


export type revokeRole = { __typename?: 'Mutation', revokeRole: { __typename?: 'User', id: number } };

export type listClubsVariables = Exact<{
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination: ClubListPaginationInput;
}>;


export type listClubs = { __typename?: 'Query', listClubs: { __typename?: 'PaginatedSearchClubResponse', totalCount: number, rows: Array<{ __typename?: 'Club', id: number, name: string, memberSiteUrl: string, signupDate: any, status: ClubStatus, settings: { __typename?: 'Settings', features: { __typename?: 'Features', fundraisers: boolean, tickets: boolean, membership: boolean } } }> } };

export type getClubDataVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type getClubData = { __typename?: 'Query', club: { __typename?: 'Club', id: number, name: string, shortName: string | null, statementDescriptor: string | null, suffix: string, logoSource: string | null, timeZone: Timezone, facebookPixelId: string | null, tixserveSecret: string | null, tixserveServerKey: string | null, clubType: Clubtype, contactEmail: string | null, country: { __typename?: 'Country', id: number, name: string, currency: Currency, vat: number }, colors: { __typename?: 'Colors', scheme: ColorScheme | null, primary: string, secondary: string, appIconBackgroundColor: string | null }, appConfig: { __typename?: 'AppConfig', fcmId: string | null, apnToken: string | null, apnTokenKeyId: string | null, appleDeveloperTeamId: string | null, packageName: string | null, packageIdentifier: string | null, appWelcomeText: string | null, appleAppId: string | null, appleTeamName: string | null, appleCompanyName: string | null, appleContentProviderId: string | null, firebaseProjectId: string | null, firebaseAndroidAppId: string | null, firebaseIosAppId: string | null, firebaseServiceKey: string | null, newKeyStore: boolean | null, sha256CertFingerprints: string | null, legacyFirebaseProjectId: string | null, legacyFirebaseAndroidAppId: string | null, legacyFirebaseServiceKey: string | null, legacyFcmId: string | null }, settings: { __typename?: 'Settings', features: { __typename?: 'Features', membership: boolean, fundraisers: boolean, fundraisersOnMemberSite: boolean | null, tickets: boolean } } } };

export type getClubOwnerVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type getClubOwner = { __typename?: 'Query', getClubOwner: { __typename?: 'User', id: number, email: string, firstName: string, lastName: string } };

export type modifyClubVariables = Exact<{
  clubId: Scalars['Float']['input'];
  clubInput: ClubInput;
  adminInput: ClubOwnerInput;
  migrateToNewFirebaseService?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type modifyClub = { __typename?: 'Mutation', modifyClub: { __typename?: 'Club', id: number } };

export type createClubVariables = Exact<{
  clubInput: ClubInput;
  adminInput: ClubOwnerInput;
}>;


export type createClub = { __typename?: 'Mutation', createClub: { __typename?: 'Club', id: number } };

export type getAvailableCountriesVariables = Exact<{ [key: string]: never; }>;


export type getAvailableCountries = { __typename?: 'Query', getAvailableCountries: Array<{ __typename?: 'Country', id: number, name: string, vat: number, currency: Currency }> };

export type resendClubInvitationEmailVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type resendClubInvitationEmail = { __typename?: 'Mutation', resendClubInvitationEmail: { __typename?: 'Club', name: string } };

export type listBuildVariables = Exact<{
  input?: InputMaybe<MobileBuildStatusInput>;
  filter?: InputMaybe<FilterMobileBuildStatusInput>;
}>;


export type listBuild = { __typename?: 'Query', listBuilds: { __typename?: 'ListMobileBuildStatusWithStats', totalCount: number, numberOfAbortableBuilds: number, numberOfUpdatableBuilds: number, latestAppVersion: string | null, rows: Array<{ __typename?: 'MobileBuildStatus', mobileBuildId: number | null, organisationId: number, organisation: string, platform: MobileAppBuildPlatform, appVersion: string | null, buildNumber: number | null, buildSlug: string | null, buildStatus: MobileAppBuildStatus | null, buildNotes: string | null, branch: string | null }> } };

export type startBuildVariables = Exact<{
  buildDetails: BuildDetails;
}>;


export type startBuild = { __typename?: 'Mutation', startBuild: { __typename?: 'MobileBuild', id: number, status: MobileAppBuildStatus } };

export type startBuildsVariables = Exact<{
  buildDetails: Array<BuildDetails> | BuildDetails;
}>;


export type startBuilds = { __typename?: 'Mutation', startBuilds: { __typename?: 'MobileBuilds', builds: Array<{ __typename?: 'MobileBuild', id: number }> } };

export type abortBuildVariables = Exact<{
  buildId: Scalars['Float']['input'];
}>;


export type abortBuild = { __typename?: 'Mutation', abortBuild: { __typename?: 'MobileBuild', id: number } };

export type bulkActionVariables = Exact<{
  bulkActionInput: BulkActionInput;
}>;


export type bulkAction = { __typename?: 'Mutation', bulkAction: { __typename?: 'MobileBuilds', builds: Array<{ __typename?: 'MobileBuild', id: number }> } };

export type refreshBuildVariables = Exact<{
  buildNumber: Scalars['Float']['input'];
}>;


export type refreshBuild = { __typename?: 'Mutation', refreshBuild: { __typename?: 'MobileBuild', id: number } };

export type ArticlePreview = { __typename?: 'Article', id: number, title: string, content: string, coverImageSource: string, createdAt: any, banner: string | null, published: boolean, pinned: boolean | null, publishType: PublishType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareArticleSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null } };

export type getArticlesVariables = Exact<{
  clubId: Scalars['Float']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  dateRange?: InputMaybe<ArticleSearchDateRange>;
}>;


export type getArticles = { __typename?: 'Query', articles: { __typename?: 'PaginatedSearchArticleResponse', totalCount: number, hasMore: boolean, rows: Array<{ __typename?: 'Article', id: number, title: string, content: string, coverImageSource: string, createdAt: any, banner: string | null, published: boolean, pinned: boolean | null, publishType: PublishType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareArticleSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null } }>, next: { __typename?: 'PaginationNext', offset: number, limit: number } | null } };

export type getArticleDetailsVariables = Exact<{
  articleId: Scalars['Float']['input'];
}>;


export type getArticleDetails = { __typename?: 'Query', article: { __typename?: 'Article', id: number, title: string, content: string, coverImageSource: string, createdAt: any, banner: string | null, published: boolean, pinned: boolean | null, publishType: PublishType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareArticleSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null } } };

export type getFullArticleVariables = Exact<{
  articleId: Scalars['Float']['input'];
}>;


export type getFullArticle = { __typename?: 'Query', article: { __typename?: 'Article', id: number, title: string, content: string, coverImageSource: string, createdAt: any, banner: string | null, published: boolean, pinned: boolean | null, publishType: PublishType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareArticleSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null } } };

export type editArticleVariables = Exact<{
  articleId: Scalars['Float']['input'];
  article: ArticleInput;
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
  socialMediaArticlePostInput: SocialMediaArticlePostInput;
}>;


export type editArticle = { __typename?: 'Mutation', editArticle: { __typename?: 'Article', id: number, title: string, content: string, coverImageSource: string, createdAt: any, banner: string | null, published: boolean, pinned: boolean | null, publishType: PublishType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareArticleSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null } } };

export type deleteArticleVariables = Exact<{
  articleId: Scalars['Float']['input'];
}>;


export type deleteArticle = { __typename?: 'Mutation', deleteArticle: { __typename?: 'Article', id: number, title: string, content: string, coverImageSource: string, createdAt: any, banner: string | null, published: boolean, pinned: boolean | null, publishType: PublishType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareArticleSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null } } };

export type createArticleVariables = Exact<{
  clubId: Scalars['Float']['input'];
  article: ArticleInput;
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
  socialMediaArticlePostInput: SocialMediaArticlePostInput;
}>;


export type createArticle = { __typename?: 'Mutation', createArticle: { __typename?: 'Article', id: number, title: string, content: string, coverImageSource: string, createdAt: any, banner: string | null, published: boolean, pinned: boolean | null, publishType: PublishType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareArticleSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null } } };

export type pinAnArticleVariables = Exact<{
  articleId: Scalars['Float']['input'];
}>;


export type pinAnArticle = { __typename?: 'Mutation', pinArticle: { __typename?: 'Article', id: number, title: string, content: string, coverImageSource: string, createdAt: any, banner: string | null, published: boolean, pinned: boolean | null, publishType: PublishType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareArticleSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null } } };

export type unpinAnArticleVariables = Exact<{
  articleId: Scalars['Float']['input'];
}>;


export type unpinAnArticle = { __typename?: 'Mutation', unpinArticle: { __typename?: 'Article', id: number, title: string, content: string, coverImageSource: string, createdAt: any, banner: string | null, published: boolean, pinned: boolean | null, publishType: PublishType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareArticleSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null } } };

export type BookmarkData = { __typename?: 'Bookmark', id: number, icon: string, iconName: string, label: string, url: string };

export type getBookmarksVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type getBookmarks = { __typename?: 'Query', getBookmarks: Array<{ __typename?: 'Bookmark', id: number, icon: string, iconName: string, label: string, url: string }> | null };

export type upsertBookmarksVariables = Exact<{
  clubId: Scalars['Float']['input'];
  input: UpsertBookmarkInput;
}>;


export type upsertBookmarks = { __typename?: 'Mutation', upsertBookmarks: Array<{ __typename?: 'Bookmark', id: number, icon: string, iconName: string, label: string, url: string }> };

export type EventPreview = { __typename?: 'Event', id: number, title: string, location: string, startDate: any, endDate: any, coverImageSource: string | null, content: string, published: boolean, pinned: boolean | null, maxTickets: number, tixserveEventCode: string | null, ticketingType: TicketingType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, additionalInfoEnabled: boolean | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, club: { __typename?: 'Club', id: number }, ticketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, inactiveTicketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null }, form: { __typename?: 'Form', schema: any, uiSchema: any } | null };

export type getEventsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  published: Scalars['Boolean']['input'];
  past: Scalars['Boolean']['input'];
  searchTerm: Scalars['String']['input'];
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type getEvents = { __typename?: 'Query', events: { __typename?: 'PaginatedSearchEventResponse', totalCount: number, rows: Array<{ __typename?: 'Event', id: number, title: string, location: string, startDate: any, endDate: any, coverImageSource: string | null, content: string, published: boolean, pinned: boolean | null, maxTickets: number, tixserveEventCode: string | null, ticketingType: TicketingType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, additionalInfoEnabled: boolean | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, club: { __typename?: 'Club', id: number }, ticketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, inactiveTicketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null }, form: { __typename?: 'Form', schema: any, uiSchema: any } | null }> } };

export type getEventDetailsVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type getEventDetails = { __typename?: 'Query', event: { __typename?: 'Event', id: number, title: string, location: string, startDate: any, endDate: any, coverImageSource: string | null, content: string, published: boolean, pinned: boolean | null, maxTickets: number, tixserveEventCode: string | null, ticketingType: TicketingType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, additionalInfoEnabled: boolean | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, club: { __typename?: 'Club', id: number }, ticketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, inactiveTicketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null }, form: { __typename?: 'Form', schema: any, uiSchema: any } | null } };

export type getFullEventVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type getFullEvent = { __typename?: 'Query', event: { __typename?: 'Event', id: number, title: string, location: string, startDate: any, endDate: any, coverImageSource: string | null, content: string, published: boolean, pinned: boolean | null, maxTickets: number, tixserveEventCode: string | null, ticketingType: TicketingType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, additionalInfoEnabled: boolean | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, club: { __typename?: 'Club', id: number }, ticketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, inactiveTicketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null }, form: { __typename?: 'Form', schema: any, uiSchema: any } | null } };

export type editEventVariables = Exact<{
  id: Scalars['Float']['input'];
  event: EventInput;
  notificationSchedule: ScheduleInput;
  ticketInput?: InputMaybe<CreateEventTicketInput>;
  socialMediaPostInput: SocialMediaPostInput;
  additionalInfoInput?: InputMaybe<AdditionalInfoInput>;
}>;


export type editEvent = { __typename?: 'Mutation', editEvent: { __typename?: 'Event', id: number, title: string, location: string, startDate: any, endDate: any, coverImageSource: string | null, content: string, published: boolean, pinned: boolean | null, maxTickets: number, tixserveEventCode: string | null, ticketingType: TicketingType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, additionalInfoEnabled: boolean | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, club: { __typename?: 'Club', id: number }, ticketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, inactiveTicketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null }, form: { __typename?: 'Form', schema: any, uiSchema: any } | null } };

export type deleteEventVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type deleteEvent = { __typename?: 'Mutation', deleteEvent: { __typename?: 'Event', id: number } };

export type createEventVariables = Exact<{
  clubId: Scalars['Float']['input'];
  event: EventInput;
  notificationSchedule: ScheduleInput;
  ticketInput?: InputMaybe<CreateEventTicketInput>;
  socialMediaPostInput: SocialMediaPostInput;
  additionalInfoInput?: InputMaybe<AdditionalInfoInput>;
}>;


export type createEvent = { __typename?: 'Mutation', createEvent: { __typename?: 'Event', id: number, title: string, location: string, startDate: any, endDate: any, coverImageSource: string | null, content: string, published: boolean, pinned: boolean | null, maxTickets: number, tixserveEventCode: string | null, ticketingType: TicketingType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, additionalInfoEnabled: boolean | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, club: { __typename?: 'Club', id: number }, ticketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, inactiveTicketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null }, form: { __typename?: 'Form', schema: any, uiSchema: any } | null } };

export type pinAnEventVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type pinAnEvent = { __typename?: 'Mutation', pinEvent: { __typename?: 'Event', id: number, title: string, location: string, startDate: any, endDate: any, coverImageSource: string | null, content: string, published: boolean, pinned: boolean | null, maxTickets: number, tixserveEventCode: string | null, ticketingType: TicketingType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, additionalInfoEnabled: boolean | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, club: { __typename?: 'Club', id: number }, ticketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, inactiveTicketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null }, form: { __typename?: 'Form', schema: any, uiSchema: any } | null } };

export type unpinAnEventVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type unpinAnEvent = { __typename?: 'Mutation', unpinEvent: { __typename?: 'Event', id: number, title: string, location: string, startDate: any, endDate: any, coverImageSource: string | null, content: string, published: boolean, pinned: boolean | null, maxTickets: number, tixserveEventCode: string | null, ticketingType: TicketingType | null, instagramPostId: string | null, twitterTweetId: string | null, facebookPostId: string | null, additionalInfoEnabled: boolean | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, club: { __typename?: 'Club', id: number }, ticketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, inactiveTicketTypes: Array<{ __typename?: 'EventTicketType', id: number, name: string, color: TicketColor, price: number, currency: Currency, tixserveTicketTypeCode: string | null, numberOfTickets: number }>, socialMediaShareSettings: { __typename?: 'SocialMediaShareSettings', instagram: boolean | null, facebook: boolean | null, twitter: boolean | null }, form: { __typename?: 'Form', schema: any, uiSchema: any } | null } };

export type uploadRequestVariables = Exact<{
  fileName: Scalars['String']['input'];
  type: FileUploadType;
  clubId?: InputMaybe<Scalars['Float']['input']>;
}>;


export type uploadRequest = { __typename?: 'Mutation', uploadRequest: { __typename?: 'FileUploadRequestResponse', url: string, fields: Array<{ __typename?: 'FileUploadRequestResponseField', key: string, value: string }> } };

export type TransactionFragment = { __typename?: 'Payment', id: number, paidAt: any | null, paymentStatusUpdatedAt: any, currency: Currency, amount: number, refundableAmount: number, netAmount: number, fee: number, paymentType: PaymentType, itemType: PaymentItemType, status: PaymentStatus, firstName: string, lastName: string, comment: string | null, canCancelInstalments: boolean };

export type countTransactionsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  filter: FilterCountTransactionsInput;
}>;


export type countTransactions = { __typename?: 'Query', countTransactions: { __typename?: 'TransactionsCountResponse', count: number } };

export type listTransactionsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  input: ListTransactionsInput;
  filter: FilterTransactionsInput;
}>;


export type listTransactions = { __typename?: 'Query', listTransactions: { __typename?: 'PaginatedSearchPaymentResponse', totalCount: number, rows: Array<{ __typename?: 'Payment', id: number, paidAt: any | null, paymentStatusUpdatedAt: any, currency: Currency, amount: number, refundableAmount: number, netAmount: number, fee: number, paymentType: PaymentType, itemType: PaymentItemType, status: PaymentStatus, firstName: string, lastName: string, comment: string | null, canCancelInstalments: boolean }> } };

export type getTransactionAutoCompleteOptionsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  searchTerm: Scalars['String']['input'];
}>;


export type getTransactionAutoCompleteOptions = { __typename?: 'Query', getTransactionAutoCompleteOptions: Array<{ __typename?: 'AutocompleteItem', email: string, firstName: string, lastName: string }> };

export type transactionDetailsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  paymentId: Scalars['Float']['input'];
}>;


export type transactionDetails = { __typename?: 'Query', transactionDetails: { __typename?: 'TransactionDetailsResponse', id: number, userName: string, firstName: string, lastName: string, amount: number, netAmount: number, refundableAmount: number, refundReason: RefundReason | null, currency: Currency, details: string | null, fee: number, paymentType: PaymentType, paymentItemType: PaymentItemType, status: PaymentStatus, failureDetails: string | null, paymentStatusUpdatedAt: any, ordinalNumber: number, memberships: Array<{ __typename?: 'MembershipTransactionDetails', id: number, status: MembershipStatus, cancelled: boolean, autoRenew: boolean | null, instalment: { __typename?: 'MembershipInstalmentDetails', numberOfPaidInstalments: number, numberOfInstalmentsBehindOnPayment: number, numberOfInstalments: number, areInstalmentsConsideredAsFullyPaid: boolean, cancelledAt: any | null, cancellerUser: { __typename?: 'User', firstName: string, lastName: string } | null } | null, members: Array<{ __typename?: 'MembershipMemberDetailed', id: number, name: string, membershipName: string }> }>, fundraiserTickets: Array<{ __typename?: 'FundraiserTicketTransactionDetails', id: number, autoRenew: boolean }>, eventTickets: Array<{ __typename?: 'EventTicketTransactionDetails', id: number, firstName: string, lastName: string, email: string, phoneNumber: string, ticketTypeName: string }>, refunds: Array<{ __typename?: 'Payment', id: number, amount: number, netAmount: number, fee: number, paymentType: PaymentType, refundReason: RefundReason | null, paymentStatusUpdatedAt: any, user: { __typename?: 'User', firstName: string, lastName: string } | null }> | null, originalTransaction: { __typename?: 'Payment', firstName: string, lastName: string, amount: number, netAmount: number, fee: number, paymentType: PaymentType, itemType: PaymentItemType, paidAt: any | null } | null } };

export type refundTransactionVariables = Exact<{
  clubId: Scalars['Float']['input'];
  options: RefundTransactionsInput;
}>;


export type refundTransaction = { __typename?: 'Mutation', refundTransaction: { __typename?: 'Payment', id: number } };

export type cancelInstalmentPaymentVariables = Exact<{
  clubId: Scalars['Float']['input'];
  paymentId: Scalars['Float']['input'];
  membersAction: RefundMembersAction;
}>;


export type cancelInstalmentPayment = { __typename?: 'Mutation', cancelInstalmentPayment: { __typename?: 'Payment', id: number } };

export type PayoutFragment = { __typename?: 'PayoutResponse', id: string, amount: number, status: string, created: any, arrivalDate: any, currency: Currency, destination: string, automatic: boolean };

export type listPayoutsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  input: ListPayoutsInput;
  filter: FilterPayoutsInput;
}>;


export type listPayouts = { __typename?: 'Query', listPayouts: { __typename?: 'PaginatedSearchPayoutResponseResponse', totalCount: number, rows: Array<{ __typename?: 'PayoutResponse', id: string, amount: number, status: string, created: any, arrivalDate: any, currency: Currency, destination: string, automatic: boolean }> } };

export type getPayoutIncomeStreamsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  payoutId: Scalars['Float']['input'];
}>;


export type getPayoutIncomeStreams = { __typename?: 'Query', getPayoutIncomeStreams: { __typename?: 'PayoutIncomeStreamsResponse', refundFeatureReleaseDate: any | null, streams: { __typename?: 'PayoutIncomeStreams', membership: { __typename?: 'PayoutIncomeStream', netAmount: number, grossAmount: number }, lotto: { __typename?: 'PayoutIncomeStream', netAmount: number, grossAmount: number }, raffle: { __typename?: 'PayoutIncomeStream', netAmount: number, grossAmount: number }, event: { __typename?: 'PayoutIncomeStream', netAmount: number, grossAmount: number }, total: { __typename?: 'PayoutIncomeStream', netAmount: number, grossAmount: number } } } };

export type payoutToBankVariables = Exact<{
  clubId: Scalars['Float']['input'];
  options: PayoutToBankInput;
}>;


export type payoutToBank = { __typename?: 'Mutation', payoutToBank: { __typename?: 'PayoutResponse', id: string } };

export type FormDataFragment = { __typename?: 'Form', id: number, name: string, description: string, schema: any, uiSchema: any, updatedAt: any, isUsed: boolean };

export type getFormVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type getForm = { __typename?: 'Query', getForm: { __typename?: 'Form', id: number, name: string, description: string, schema: any, uiSchema: any, updatedAt: any, isUsed: boolean } };

export type getClubFormsVariables = Exact<{ [key: string]: never; }>;


export type getClubForms = { __typename?: 'Query', getClubForms: Array<{ __typename?: 'Form', id: number, name: string, description: string, schema: any, uiSchema: any, updatedAt: any, isUsed: boolean }> };

export type getSystemFormsVariables = Exact<{ [key: string]: never; }>;


export type getSystemForms = { __typename?: 'Query', getSystemForms: Array<{ __typename?: 'Form', id: number, name: string, description: string, schema: any, uiSchema: any, updatedAt: any, isUsed: boolean }> };

export type createClubFormVariables = Exact<{
  input: CreateFormInput;
}>;


export type createClubForm = { __typename?: 'Mutation', createClubForm: { __typename?: 'Form', id: number, name: string, description: string, schema: any, uiSchema: any, updatedAt: any, isUsed: boolean } };

export type updateClubFormVariables = Exact<{
  id: Scalars['Float']['input'];
  input: UpdateFormInput;
}>;


export type updateClubForm = { __typename?: 'Mutation', updateClubForm: { __typename?: 'Form', id: number, name: string, description: string, schema: any, uiSchema: any, updatedAt: any, isUsed: boolean } };

export type deleteClubFormVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type deleteClubForm = { __typename?: 'Mutation', deleteClubForm: { __typename?: 'Form', id: number } };

export type endFundraiserVariables = Exact<{
  fundraiserId: Scalars['Float']['input'];
}>;


export type endFundraiser = { __typename?: 'Mutation', endFundraiser: { __typename?: 'Fundraiser', id: number, ended: boolean } };

export type cancelRecurringTicketVariables = Exact<{
  ticketId: Scalars['Float']['input'];
}>;


export type cancelRecurringTicket = { __typename?: 'Mutation', cancelRecurringTicket: { __typename?: 'FundraiserTicket', id: number } };

export type getFundraiserTicketTypesVariables = Exact<{
  clubId: Scalars['Float']['input'];
  ticketTypeIdToInclude?: InputMaybe<Scalars['Float']['input']>;
}>;


export type getFundraiserTicketTypes = { __typename?: 'Query', getFundraisersWithOpenEvent: Array<{ __typename?: 'Fundraiser', id: number, name: string, repetition: RepetitionType, currency: Currency, currentEvent: { __typename?: 'FundraiserEvent', id: number, eventDate: any, eventNumber: number } | null, fundraiserType: { __typename?: 'FundraiserCategory', id: number, name: FundraiserType }, ticketTypes: Array<{ __typename?: 'FundraiserTicketType', id: number, name: string, price: number, currency: Currency, numberOfRounds: number, entries: number }> }> };

export type getFundraiserNotificationPreviewTemplateDataVariables = Exact<{
  fundraiserId?: InputMaybe<Scalars['Float']['input']>;
}>;


export type getFundraiserNotificationPreviewTemplateData = { __typename?: 'Query', getFundraiserNotificationPreviewTemplateData: { __typename?: 'FundraiserNotificationPreviewTemplateData', firstName: string, fundraiserName: string | null, eventPrize: string | null, eventDate: string | null, fundraiserWebsiteLink: string, firstNameLengths: Array<{ __typename?: 'FirstNameLength', length: number, count: number }> } };

export type listActiveUnfinishedFundraisersVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type listActiveUnfinishedFundraisers = { __typename?: 'Query', listActiveUnfinishedFundraisers: Array<{ __typename?: 'Fundraiser', id: number, name: string, repetition: RepetitionType, demo: boolean, ended: boolean, prizes: Array<string>, currency: Currency, isAssignedToMembership: boolean, randomGeneration: boolean, matchThreeNumbers: boolean, fundraiserType: { __typename?: 'FundraiserCategory', name: FundraiserType }, fundraiserDescription: { __typename?: 'FundraiserDescription', ticketStartNumber: number | null, numberOfBalls: number | null, numberRange: number | null }, fundraiserEvents: Array<{ __typename?: 'FundraiserEvent', id: number, isOpen: boolean, createdAt: any, eventDate: any, eventNumber: number }>, currentRoundSales: { __typename?: 'CurrentFundraiserRoundSales', sum: number, count: number } }> };

export type listFundraiserEventStatisticsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  filters: FilterFundraiserEventStatisticsInput;
  pagination: FundraiserEventStatisticsPaginationInput;
}>;


export type listFundraiserEventStatistics = { __typename?: 'Query', listFundraiserEventStatistics: { __typename?: 'PaginatedSearchFundraiserEventStatisticDetailsResponse', totalCount: number, rows: Array<{ __typename?: 'FundraiserEventStatisticDetails', id: number, clubId: number, fundraiserId: number, fundraiserEventId: number, fundraiserTypeId: number, fundraiserName: string, fundraiserEventNumber: number, roundStart: any, roundEnd: any, jackpot: string, itemsTotal: number, priceTotal: number }> } };

export type getTicketsSoldVariables = Exact<{
  clubId: Scalars['Float']['input'];
  filters: FilterFundraiserTicketsInput;
  pagination: FundraiserTicketsPaginationInput;
}>;


export type getTicketsSold = { __typename?: 'Query', getTicketsSold: { __typename?: 'PaginatedSearchFundraiserTicketDetailsResponse', totalCount: number, rows: Array<{ __typename?: 'FundraiserTicketDetails', id: number, clubId: number, firstName: string, lastName: string, email: string, phoneNumber: string | null, fundraiserName: string | null, draw: number, ticketType: string, purchaseDate: any | null, combination: Array<number> | null, ticketNumber: number | null, boughtFrom: string | null, recurring: boolean, lineOrder: number, numberOfLines: number, rowId: string }> } };

export type findTicketTypesVariables = Exact<{
  clubId: Scalars['Float']['input'];
  fundraiserId?: InputMaybe<Scalars['Float']['input']>;
}>;


export type findTicketTypes = { __typename?: 'Query', findTicketTypes: Array<{ __typename?: 'FundraiserTicketType', id: number, name: string, fundraiser: { __typename?: 'Fundraiser', id: number, name: string, fundraiserType: { __typename?: 'FundraiserCategory', name: FundraiserType } } }> };

export type EventFragment = { __typename?: 'FundraiserEvent', id: number, createdAt: any, eventDate: any, eventNumber: number, prizes: Array<string>, fundraiser: { __typename?: 'Fundraiser', id: number, name: string, fundraiserType: { __typename?: 'FundraiserCategory', name: FundraiserType } } };

export type findEventsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  fundraiserId?: InputMaybe<Scalars['Float']['input']>;
}>;


export type findEvents = { __typename?: 'Query', findEvents: Array<{ __typename?: 'FundraiserEvent', id: number, createdAt: any, eventDate: any, eventNumber: number, prizes: Array<string>, fundraiser: { __typename?: 'Fundraiser', id: number, name: string, fundraiserType: { __typename?: 'FundraiserCategory', name: FundraiserType } } }> };

export type getFundraiserEventVariables = Exact<{
  eventId: Scalars['Float']['input'];
}>;


export type getFundraiserEvent = { __typename?: 'Query', getFundraiserEvent: { __typename?: 'FundraiserEvent', id: number, createdAt: any, eventDate: any, eventNumber: number, prizes: Array<string>, fundraiser: { __typename?: 'Fundraiser', id: number, name: string, fundraiserType: { __typename?: 'FundraiserCategory', name: FundraiserType } } } };

export type getNextEventVariables = Exact<{
  eventId: Scalars['Float']['input'];
}>;


export type getNextEvent = { __typename?: 'Query', getNextEvent: { __typename?: 'FundraiserEvent', id: number, isOpen: boolean, createdAt: any, eventDate: any, eventNumber: number, fundraiser: { __typename?: 'Fundraiser', repetition: RepetitionType, name: string, currency: Currency, prizes: Array<string>, randomGeneration: boolean, matchThreeNumbers: boolean } } | null };

export type getTermsAndConditionsFilesVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type getTermsAndConditionsFiles = { __typename?: 'Query', getTermsAndConditionsFiles: { __typename?: 'TermsAndConditions', apple: { __typename?: 'TermsAndConditionsData', fileUrl: string | null, lastModified: any | null }, google: { __typename?: 'TermsAndConditionsData', fileUrl: string | null, lastModified: any | null } } };

export type setUploadedTermsAndConditionsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  fileUrl: Scalars['String']['input'];
  platform: PlatformsEnum;
}>;


export type setUploadedTermsAndConditions = { __typename?: 'Mutation', setUploadedTermsAndConditions: { __typename?: 'TermsAndConditions', apple: { __typename?: 'TermsAndConditionsData', fileUrl: string | null, lastModified: any | null }, google: { __typename?: 'TermsAndConditionsData', fileUrl: string | null, lastModified: any | null } } };

export type listGroupsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination: GroupListPaginationInput;
}>;


export type listGroups = { __typename?: 'Query', listGroups: { __typename?: 'PaginatedSearchGroupResponse', totalCount: number, rows: Array<{ __typename?: 'Group', id: string, name: string, users: Array<{ __typename?: 'GroupMember', manager: boolean, user: { __typename?: 'PublicUserType', firstName: string, lastName: string } }> }> } };

export type listMessagesVariables = Exact<{
  groupId: Scalars['String']['input'];
  pagination: MessagesListPaginationInput;
}>;


export type listMessages = { __typename?: 'Query', listMessages: { __typename?: 'PaginatedSearchGroupMessageResponse', totalCount: number, rows: Array<{ __typename?: 'GroupMessage', id: string, subject: string, content: string, sentOn: any, summarizedResponses: { __typename?: 'SummarizedResponses', yes: number, no: number, awaiting: number } }> } };

export type getGroupVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;


export type getGroup = { __typename?: 'Query', group: { __typename?: 'Group', name: string, users: Array<{ __typename?: 'GroupMember', manager: boolean, user: { __typename?: 'PublicUserType', id: number, firstName: string, lastName: string, birthDate: any | null, email: string } }> } };

export type listUsersVariables = Exact<{
  clubId: Scalars['Float']['input'];
  input?: InputMaybe<ListMembersInput>;
  filter?: InputMaybe<FilterMembersInput>;
}>;


export type listUsers = { __typename?: 'Query', listUsers: { __typename?: 'PaginatedSearchPublicUserTypeResponse', totalCount: number, rows: Array<{ __typename?: 'PublicUserType', id: number, firstName: string, lastName: string, birthDate: any | null, email: string }> } };

export type createGroupVariables = Exact<{
  clubId: Scalars['Float']['input'];
  input: CreateGroupInput;
}>;


export type createGroup = { __typename?: 'Mutation', createGroup: { __typename?: 'Group', id: string, name: string } };

export type editGroupVariables = Exact<{
  groupId: Scalars['String']['input'];
  input: EditGroupInput;
}>;


export type editGroup = { __typename?: 'Mutation', editGroup: { __typename?: 'Group', id: string, name: string } };

export type sendMessageVariables = Exact<{
  groupId: Scalars['String']['input'];
  message: NewMessage;
}>;


export type sendMessage = { __typename?: 'Mutation', sendMessage: { __typename?: 'GroupMessage', id: string } };

export type deleteGroupVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type deleteGroup = { __typename?: 'Mutation', deleteGroup: { __typename?: 'Group', id: string } };

export type deleteMessageVariables = Exact<{
  groupId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
}>;


export type deleteMessage = { __typename?: 'Mutation', deleteMessage: { __typename?: 'GroupMessage', id: string } };

export type getMessageVariables = Exact<{
  groupId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
}>;


export type getMessage = { __typename?: 'Query', getMessage: { __typename?: 'GroupMessage', id: string, subject: string, content: string, sentOn: any, group: { __typename?: 'Group', name: string }, membersWithResponse: Array<{ __typename?: 'MemberWithResponse', id: number, name: string, email: string, response: boolean | null }> | null, summarizedResponses: { __typename?: 'SummarizedResponses', yes: number, no: number, awaiting: number } } };

export type RoundFragment = { __typename?: 'LottoRound', id: number, isOpen: boolean, createdAt: any, drawDate: any, drawNumber: number };

export type LottoNotificationFragment = { __typename?: 'Lotto', notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }> };

export type LottoFragment = { __typename?: 'Lotto', id: number, name: string, repeating: LottoRepetitionType, jackpotAmount: number, numberOfBalls: number, numberRange: number, currency: Currency, ended: boolean, randomGeneration: boolean, matchThreeNumbers: boolean, additionalInfoEnabled: boolean | null, form: { __typename?: 'Form', schema: any, uiSchema: any } | null, rounds: Array<{ __typename?: 'LottoRound', id: number, isOpen: boolean, createdAt: any, drawDate: any, drawNumber: number }>, notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }> };

export type TicketTypesFragment = { __typename?: 'Lotto', ticketTypes: Array<{ __typename?: 'LottoTicketType', id: number, price: number, entries: number, numberOfRounds: number, type: TicketType, currency: Currency, name: string, recurringTickets: number }> };

export type getLottoVariables = Exact<{
  lottoId: Scalars['Float']['input'];
}>;


export type getLotto = { __typename?: 'Query', findLotto: { __typename?: 'Lotto', id: number, name: string, repeating: LottoRepetitionType, jackpotAmount: number, numberOfBalls: number, numberRange: number, matchThreeNumbers: boolean, randomGeneration: boolean, additionalInfoEnabled: boolean | null, club: { __typename?: 'Club', id: number }, currentRound: { __typename?: 'LottoRound', drawDate: any, id: number, isOpen: boolean, createdAt: any, drawNumber: number } | null, form: { __typename?: 'Form', schema: any, uiSchema: any } | null, notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }>, ticketTypes: Array<{ __typename?: 'LottoTicketType', id: number, price: number, entries: number, numberOfRounds: number, type: TicketType, currency: Currency, name: string, recurringTickets: number }> } };

export type userFragment = { __typename?: 'User', id: number, firstName: string, lastName: string, email: string, type: UserType };

export type ticketFragment = { __typename?: 'WinnerTicketWithCombination', ticketId: number, ticketLineId: number, combination: Array<number>, firstName: string, lastName: string, email: string };

export type createLottoVariables = Exact<{
  clubId: Scalars['Float']['input'];
  input: NewLottoInput;
}>;


export type createLotto = { __typename?: 'Mutation', createLotto: { __typename?: 'Lotto', id: number, name: string, repeating: LottoRepetitionType, jackpotAmount: number, numberOfBalls: number, numberRange: number, currency: Currency, ended: boolean, randomGeneration: boolean, matchThreeNumbers: boolean, additionalInfoEnabled: boolean | null, form: { __typename?: 'Form', schema: any, uiSchema: any } | null, rounds: Array<{ __typename?: 'LottoRound', id: number, isOpen: boolean, createdAt: any, drawDate: any, drawNumber: number }>, notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }> } };

export type updateLottoVariables = Exact<{
  lottoId: Scalars['Float']['input'];
  input: EditLottoInput;
}>;


export type updateLotto = { __typename?: 'Mutation', updateLotto: { __typename?: 'Lotto', jackpotAmount: number, ticketTypes: Array<{ __typename?: 'LottoTicketType', id: number, entries: number, price: number, currency: Currency }>, notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }> } };

export type getRoundToFinishVariables = Exact<{
  roundId: Scalars['Float']['input'];
  combinationInput: CombinationInput;
}>;


export type getRoundToFinish = { __typename?: 'Query', getRoundToFinish: { __typename?: 'RoundToFinish', soldTicketsAmount: number, matchThreeTicketsAmount: number, event: { __typename?: 'FundraiserEvent', id: number, createdAt: any, eventDate: any, eventNumber: number, prizes: Array<string>, fundraiser: { __typename?: 'Fundraiser', id: number, name: string, prizes: Array<string>, currency: Currency } }, winnerTickets: Array<{ __typename?: 'WinnerTicketWithCombination', ticketId: number, ticketLineId: number, combination: Array<number>, firstName: string, lastName: string, email: string }> } };

export type finishRoundVariables = Exact<{
  publishInputs: PublishInputs;
  eventId: Scalars['Float']['input'];
}>;


export type finishRound = { __typename?: 'Mutation', publishWinners: { __typename?: 'FundraiserEvent', id: number, eventNumber: number } };

export type generateMatchThreeOnlineWinnersVariables = Exact<{
  clubId: Scalars['Float']['input'];
  numberOfWinners: Scalars['Float']['input'];
  combinationInput: CombinationInput;
  eventId: Scalars['Float']['input'];
}>;


export type generateMatchThreeOnlineWinners = { __typename?: 'Query', matchThreeOnlineWinnerTickets: Array<{ __typename?: 'FundraiserTicketDetails', id: number, ticketLineId: number, userId: number | null, firstName: string, lastName: string }> };

export type generateRandomOnlineWinnersVariables = Exact<{
  numberOfWinners: Scalars['Float']['input'];
  clubId: Scalars['Float']['input'];
  eventId: Scalars['Float']['input'];
  matchThreeWinnerTicketIds: MatchThreeWinnerTicketIds;
}>;


export type generateRandomOnlineWinners = { __typename?: 'Query', randomOnlineWinnerTickets: Array<{ __typename?: 'FundraiserTicketDetails', id: number, ticketLineId: number, userId: number | null, firstName: string, lastName: string }> };

export type bulkVariables = Exact<{
  data: BulkUploadArg;
  ticketTypeId: Scalars['Float']['input'];
}>;


export type bulk = { __typename?: 'Mutation', uploadBulkPlayers: Array<{ __typename?: 'FundraiserTicket', id: number, email: string, firstName: string, lastName: string | null, phoneNumber: string | null }> };

export type UserPreview = { __typename?: 'User', id: number, firstName: string, lastName: string, birthDate: any | null, gender: Gender | null, email: string, phoneNumber: string | null, verified: boolean };

export type MemberPreview = { __typename?: 'UserDetails', id: number, firstName: string, lastName: string, birthDate: any | null, email: string, contribution: any | null };

export type getMembersVariables = Exact<{
  clubId: Scalars['Float']['input'];
  input: ListMembersInput;
  filter?: InputMaybe<FilterMembersInput>;
}>;


export type getMembers = { __typename?: 'Query', members: { __typename?: 'PaginatedSearchUserDetailsResponse', totalCount: number, rows: Array<{ __typename?: 'UserDetails', id: number, firstName: string, lastName: string, birthDate: any | null, email: string, contribution: any | null }> } };

export type getMembershipMembersVariables = Exact<{
  clubId: Scalars['Float']['input'];
  input: ListMembershipInput;
  filter?: InputMaybe<FilterMembersInput>;
}>;


export type getMembershipMembers = { __typename?: 'Query', membershipMembers: { __typename?: 'PaginatedSearchMembershipListResponse', totalCount: number, rows: Array<{ __typename?: 'MembershipList', id: number, name: string | null, email: string | null, dateOfBirth: string | null, status: string | null, type: string | null, subtype: string | null, fullType: string | null, medicalInformation: string | null }> } };

export type getValidMembersAndExportDateVariables = Exact<{
  clubId: Scalars['Float']['input'];
  filter: ValidMembersFilter;
}>;


export type getValidMembersAndExportDate = { __typename?: 'Query', validMembers: Array<{ __typename?: 'MembershipMember', id: number }>, club: { __typename?: 'Club', lastMembershipExportDate: any | null } };

export type getMemberVariables = Exact<{
  memberId: Scalars['Float']['input'];
}>;


export type getMember = { __typename?: 'Query', user: { __typename?: 'User', appUser: boolean, id: number, firstName: string, lastName: string, birthDate: any | null, gender: Gender | null, email: string, phoneNumber: string | null, verified: boolean } };

export type getFormDetailsOfMemberVariables = Exact<{
  memberId: Scalars['Float']['input'];
}>;


export type getFormDetailsOfMember = { __typename?: 'Query', getDetailsOfMember: { __typename?: 'MembershipMember', id: number, status: MembershipStatus, membershipFormData: any | null, subtype: { __typename?: 'MembershipGroupSubtype', id: string, name: string, form: { __typename?: 'Form', id: number, schema: any, uiSchema: any } | null } | null, membershipPayment: { __typename?: 'Membership', autoRenew: boolean | null, autoRenewDisabledAt: any | null, membershipType: { __typename?: 'MembershipType', id: number, name: string, validFrom: any, validTo: any, enableRenewableMembership: boolean, form: { __typename?: 'Form', id: number, schema: any, uiSchema: any } | null }, payment: { __typename?: 'Payment', id: number } | null }, instalmentPaymentStatus: { __typename?: 'InstalmentPaymentStatus', numberOfPaidInstalments: number, numberOfInstalmentsBehindOnPayment: number, numberOfInstalments: number, areInstalmentsConsideredAsFullyPaid: boolean } } };

export type updateMemberMembershipMemberFormDataVariables = Exact<{
  memberId: Scalars['Float']['input'];
  memberDataInput: MembershipMemberFormDataInput;
  membershipInput?: InputMaybe<MemberMembershipTypeInput>;
}>;


export type updateMemberMembershipMemberFormData = { __typename?: 'Mutation', updateMemberMembershipMemberFormData: { __typename?: 'MembershipMember', id: number } };

export type importMembersVariables = Exact<{
  members: MemberFormDataImportInput;
  membershipTypeId: Scalars['Float']['input'];
}>;


export type importMembers = { __typename?: 'Mutation', importMembers: Array<{ __typename?: 'MembershipMember', id: number }> };

export type getMemberDataFromFileVariables = Exact<{
  file: Scalars['String']['input'];
  membershipTypeId: Scalars['Float']['input'];
}>;


export type getMemberDataFromFile = { __typename?: 'Query', getMemberDataFromFile: { __typename?: 'FormDataFromFileResponse', schema: any, uiSchema: any, fileError: string, formData: Array<{ __typename?: 'FormDataDataArray', data: any, existing: boolean, duplicated: boolean }> } };

export type getMemberFormDataDuplicationsVariables = Exact<{
  members: MemberFormDataDuplicationsInput;
}>;


export type getMemberFormDataDuplications = { __typename?: 'Query', getMemberFormDataDuplications: { __typename?: 'MemberFormDataDuplicationsResponse', formData: Array<{ __typename?: 'FormDataDataArray', data: any, existing: boolean, duplicated: boolean }> } };

export type setMembershipAsFullyPaidVariables = Exact<{
  memberId: Scalars['Float']['input'];
  clubId: Scalars['Float']['input'];
}>;


export type setMembershipAsFullyPaid = { __typename?: 'Mutation', setMembershipAsFullyPaid: { __typename?: 'MembershipMember', id: number } };

export type setMembershipDisableAutoRenewVariables = Exact<{
  memberId: Scalars['Float']['input'];
  clubId: Scalars['Float']['input'];
}>;


export type setMembershipDisableAutoRenew = { __typename?: 'Mutation', setMembershipDisableAutoRenew: { __typename?: 'MembershipMember', id: number } };

export type sendUnregisteredMembersNotificationVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type sendUnregisteredMembersNotification = { __typename?: 'Mutation', sendUnregisteredMembersNotification: { __typename?: 'SendUnregisteredMembersNotificationResponse', lastUnregisteredMembersNotificationDate: any | null, notifiedUsersCount: number } };

export type getLastUnregisteredMembersNotificationDateVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type getLastUnregisteredMembersNotificationDate = { __typename?: 'Query', getLastUnregisteredMembersNotificationDate: { __typename?: 'LastUnregisteredMembersNotificationDate', lastUnregisteredMembersNotificationDate: any | null, isRecent: boolean } };

export type MembershipSubtypeDetails = { __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null };

export type MembershipTypeDetails = { __typename?: 'MembershipType', id: number, name: string, description: string | null, typeGAA: TypeGAA | null, purchaseCount: number, price: number, currency: Currency, validFrom: any, validTo: any, active: boolean, publishType: PublishType, includeFundraiserTicket: boolean, fundraiserTicketTypeId: string, enableInstalmentPayment: boolean, numberOfInstalments: number | null, interest: number | null, enableRenewableMembership: boolean, renewableMembershipDefault: boolean | null, form: { __typename?: 'Form', id: number } | null, groupDescription: { __typename?: 'GroupMembershipDescription', minGroupSize: number, maxGroupSize: number } | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, membershipGroupSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, inactiveSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, renewalMembershipType: { __typename?: 'MembershipType', id: number, active: boolean, publishType: PublishType } | null, renewedMembershipTypes: Array<{ __typename?: 'MembershipType', id: number }> | null };

export type ExtendedMembershipTypeDetails = { __typename?: 'ExtendedMembershipType', id: number, name: string, description: string | null, typeGAA: TypeGAA | null, purchaseCount: number, price: number, currency: Currency, validFrom: any, validTo: any, membershipStatus: string, enableRenewableMembership: boolean, active: boolean, publishType: PublishType, includeFundraiserTicket: boolean, fundraiserTicketTypeId: string, enableInstalmentPayment: boolean, numberOfInstalments: number | null, interest: number | null, renewableMembershipDefault: boolean | null, daysLeft: number, autoRenewMembersCount: number, isAboutToExpire: boolean, form: { __typename?: 'Form', id: number } | null, groupDescription: { __typename?: 'GroupMembershipDescription', minGroupSize: number, maxGroupSize: number } | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, membershipGroupSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, inactiveSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, renewalMembershipType: { __typename?: 'MembershipType', id: number, active: boolean, publishType: PublishType } | null };

export type getMembershipTypesVariables = Exact<{
  clubId: Scalars['Float']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
  publishType?: InputMaybe<PublishType>;
  single?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MembershipFilterInput>;
  input?: InputMaybe<GetMembershipTypesInput>;
  enableRenewableMembership?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type getMembershipTypes = { __typename?: 'Query', membershipTypes: { __typename?: 'PaginatedSearchExtendedMembershipTypeResponse', totalCount: number, rows: Array<{ __typename?: 'ExtendedMembershipType', id: number, name: string, description: string | null, typeGAA: TypeGAA | null, purchaseCount: number, price: number, currency: Currency, validFrom: any, validTo: any, membershipStatus: string, enableRenewableMembership: boolean, active: boolean, publishType: PublishType, includeFundraiserTicket: boolean, fundraiserTicketTypeId: string, enableInstalmentPayment: boolean, numberOfInstalments: number | null, interest: number | null, renewableMembershipDefault: boolean | null, daysLeft: number, autoRenewMembersCount: number, isAboutToExpire: boolean, form: { __typename?: 'Form', id: number } | null, groupDescription: { __typename?: 'GroupMembershipDescription', minGroupSize: number, maxGroupSize: number } | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, membershipGroupSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, inactiveSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, renewalMembershipType: { __typename?: 'MembershipType', id: number, active: boolean, publishType: PublishType } | null }> } };

export type getMembershipTypesForDropdownVariables = Exact<{
  clubId: Scalars['Float']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
  publishType?: InputMaybe<PublishType>;
  single?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MembershipFilterInput>;
  input?: InputMaybe<GetMembershipTypesInput>;
}>;


export type getMembershipTypesForDropdown = { __typename?: 'Query', membershipTypes: { __typename?: 'PaginatedSearchExtendedMembershipTypeResponse', rows: Array<{ __typename?: 'ExtendedMembershipType', id: number, name: string, active: boolean, publishType: PublishType, validFrom: any, validTo: any }> } };

export type getMembershipTypeVariables = Exact<{
  membershipTypeId: Scalars['Float']['input'];
}>;


export type getMembershipType = { __typename?: 'Query', membershipType: { __typename?: 'MembershipType', id: number, name: string, description: string | null, typeGAA: TypeGAA | null, purchaseCount: number, price: number, currency: Currency, validFrom: any, validTo: any, active: boolean, publishType: PublishType, includeFundraiserTicket: boolean, fundraiserTicketTypeId: string, enableInstalmentPayment: boolean, numberOfInstalments: number | null, interest: number | null, enableRenewableMembership: boolean, renewableMembershipDefault: boolean | null, form: { __typename?: 'Form', id: number } | null, groupDescription: { __typename?: 'GroupMembershipDescription', minGroupSize: number, maxGroupSize: number } | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, membershipGroupSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, inactiveSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, renewalMembershipType: { __typename?: 'MembershipType', id: number, active: boolean, publishType: PublishType } | null, renewedMembershipTypes: Array<{ __typename?: 'MembershipType', id: number }> | null } };

export type createMembershipTypeVariables = Exact<{
  clubId: Scalars['Float']['input'];
  membershipType: CreateMembershipTypeInput;
  fundraiserTicketTypeId?: InputMaybe<Scalars['String']['input']>;
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
}>;


export type createMembershipType = { __typename?: 'Mutation', createMembershipType: { __typename?: 'MembershipType', id: number, name: string, description: string | null, typeGAA: TypeGAA | null, purchaseCount: number, price: number, currency: Currency, validFrom: any, validTo: any, active: boolean, publishType: PublishType, includeFundraiserTicket: boolean, fundraiserTicketTypeId: string, enableInstalmentPayment: boolean, numberOfInstalments: number | null, interest: number | null, enableRenewableMembership: boolean, renewableMembershipDefault: boolean | null, form: { __typename?: 'Form', id: number } | null, groupDescription: { __typename?: 'GroupMembershipDescription', minGroupSize: number, maxGroupSize: number } | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, membershipGroupSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, inactiveSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, renewalMembershipType: { __typename?: 'MembershipType', id: number, active: boolean, publishType: PublishType } | null, renewedMembershipTypes: Array<{ __typename?: 'MembershipType', id: number }> | null } };

export type createGroupMembershipVariables = Exact<{
  clubId: Scalars['Float']['input'];
  membership: CreateGroupMembershipInput;
  fundraiserTicketTypeId?: InputMaybe<Scalars['String']['input']>;
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
}>;


export type createGroupMembership = { __typename?: 'Mutation', createGroupMembership: { __typename?: 'MembershipType', id: number, name: string, description: string | null, typeGAA: TypeGAA | null, purchaseCount: number, price: number, currency: Currency, validFrom: any, validTo: any, active: boolean, publishType: PublishType, includeFundraiserTicket: boolean, fundraiserTicketTypeId: string, enableInstalmentPayment: boolean, numberOfInstalments: number | null, interest: number | null, enableRenewableMembership: boolean, renewableMembershipDefault: boolean | null, form: { __typename?: 'Form', id: number } | null, groupDescription: { __typename?: 'GroupMembershipDescription', minGroupSize: number, maxGroupSize: number } | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, membershipGroupSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, inactiveSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, renewalMembershipType: { __typename?: 'MembershipType', id: number, active: boolean, publishType: PublishType } | null, renewedMembershipTypes: Array<{ __typename?: 'MembershipType', id: number }> | null } };

export type changeMembershipTypeActiveVariables = Exact<{
  membershipTypeId: Scalars['Float']['input'];
  membershipType: ChangeMembershipTypeActiveInput;
}>;


export type changeMembershipTypeActive = { __typename?: 'Mutation', changeMembershipTypeActive: { __typename?: 'MembershipType', id: number, name: string, description: string | null, typeGAA: TypeGAA | null, purchaseCount: number, price: number, currency: Currency, validFrom: any, validTo: any, active: boolean, publishType: PublishType, includeFundraiserTicket: boolean, fundraiserTicketTypeId: string, enableInstalmentPayment: boolean, numberOfInstalments: number | null, interest: number | null, enableRenewableMembership: boolean, renewableMembershipDefault: boolean | null, form: { __typename?: 'Form', id: number } | null, groupDescription: { __typename?: 'GroupMembershipDescription', minGroupSize: number, maxGroupSize: number } | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, membershipGroupSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, inactiveSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, renewalMembershipType: { __typename?: 'MembershipType', id: number, active: boolean, publishType: PublishType } | null, renewedMembershipTypes: Array<{ __typename?: 'MembershipType', id: number }> | null } };

export type editMembershipTypeVariables = Exact<{
  membershipTypeId: Scalars['Float']['input'];
  membershipType: EditMembershipTypeInput;
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
}>;


export type editMembershipType = { __typename?: 'Mutation', editMembershipType: { __typename?: 'MembershipType', id: number, name: string, description: string | null, typeGAA: TypeGAA | null, purchaseCount: number, price: number, currency: Currency, validFrom: any, validTo: any, active: boolean, publishType: PublishType, includeFundraiserTicket: boolean, fundraiserTicketTypeId: string, enableInstalmentPayment: boolean, numberOfInstalments: number | null, interest: number | null, enableRenewableMembership: boolean, renewableMembershipDefault: boolean | null, form: { __typename?: 'Form', id: number } | null, groupDescription: { __typename?: 'GroupMembershipDescription', minGroupSize: number, maxGroupSize: number } | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, membershipGroupSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, inactiveSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, renewalMembershipType: { __typename?: 'MembershipType', id: number, active: boolean, publishType: PublishType } | null, renewedMembershipTypes: Array<{ __typename?: 'MembershipType', id: number }> | null } };

export type editGroupMembershipVariables = Exact<{
  membershipTypeId: Scalars['Float']['input'];
  membership: EditGroupMembershipInput;
  fundraiserTicketTypeId?: InputMaybe<Scalars['String']['input']>;
  notificationSchedule: ScheduleInput;
  publishSchedule: ScheduleInput;
}>;


export type editGroupMembership = { __typename?: 'Mutation', editGroupMembership: { __typename?: 'MembershipType', id: number, name: string, description: string | null, typeGAA: TypeGAA | null, purchaseCount: number, price: number, currency: Currency, validFrom: any, validTo: any, active: boolean, publishType: PublishType, includeFundraiserTicket: boolean, fundraiserTicketTypeId: string, enableInstalmentPayment: boolean, numberOfInstalments: number | null, interest: number | null, enableRenewableMembership: boolean, renewableMembershipDefault: boolean | null, form: { __typename?: 'Form', id: number } | null, groupDescription: { __typename?: 'GroupMembershipDescription', minGroupSize: number, maxGroupSize: number } | null, schedules: Array<{ __typename?: 'Schedule', id: number, scheduleDate: any, notificationType: ScheduleNotificationType | null, type: ScheduleType, firedAt: any | null }>, membershipGroupSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, inactiveSubtypes: Array<{ __typename?: 'MembershipGroupSubtype', id: string, name: string, description: string | null, orderNumber: number, prices: Array<number>, maxNumberOfMembers: number, purchaseCount: number, typeGAA: TypeGAA | null, form: { __typename?: 'Form', id: number } | null }>, renewalMembershipType: { __typename?: 'MembershipType', id: number, active: boolean, publishType: PublishType } | null, renewedMembershipTypes: Array<{ __typename?: 'MembershipType', id: number }> | null } };

export type deleteMembershipTypeVariables = Exact<{
  membershipTypeId: Scalars['Float']['input'];
}>;


export type deleteMembershipType = { __typename?: 'Mutation', deleteMembershipType: { __typename?: 'MembershipType', id: number } };

export type getSelectableFormsVariables = Exact<{ [key: string]: never; }>;


export type getSelectableForms = { __typename?: 'Query', getSelectableForms: Array<{ __typename?: 'Form', id: number, name: string }> };

export type listContactDetailsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  inputs: ContactDetailsPaginationInput;
}>;


export type listContactDetails = { __typename?: 'Query', listContactDetails: { __typename?: 'PaginatedSearchContactDetailsBlacklistedResponse', totalCount: number, rows: Array<{ __typename?: 'ContactDetailsBlacklisted', id: number, name: string, type: ContactType, email: { __typename?: 'BlacklistedContactData', contact: string, enabled: boolean } | null, phoneNumber: { __typename?: 'BlacklistedContactData', contact: string, enabled: boolean } | null }> } };

export type updateContactToBlacklistVariables = Exact<{
  clubId: Scalars['Float']['input'];
  inputs: Array<BlacklistedContactDataWithType> | BlacklistedContactDataWithType;
}>;


export type updateContactToBlacklist = { __typename?: 'Mutation', updateContactToBlacklist: Array<{ __typename?: 'BlacklistedContactData', contact: string, enabled: boolean }> };

export type RaffleNotificationFragment = { __typename?: 'Raffle', notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }> };

export type RaffleRoundFragment = { __typename?: 'RaffleRound', id: number, isOpen: boolean, createdAt: any, drawDate: any, drawNumber: number };

export type GetRaffleFragment = { __typename?: 'Raffle', id: number, name: string, repeating: RaffleRepetitionType, ticketStartNumber: number, ended: boolean, prizes: Array<{ __typename?: 'RafflePrize', lineNumber: number, prizeName: string }>, rounds: Array<{ __typename?: 'RaffleRound', id: number, isOpen: boolean, createdAt: any, drawDate: any, drawNumber: number }>, notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }> };

export type getRaffleVariables = Exact<{
  raffleId: Scalars['Float']['input'];
}>;


export type getRaffle = { __typename?: 'Query', getRaffle: { __typename?: 'Raffle', additionalInfoEnabled: boolean | null, id: number, name: string, repeating: RaffleRepetitionType, ticketStartNumber: number, ended: boolean, activeTicketTypes: Array<{ __typename?: 'RaffleTicketType', id: number, price: number, entries: number, numberOfRounds: number, type: TicketType, currency: Currency, name: string, recurringTickets: number }>, form: { __typename?: 'Form', schema: any, uiSchema: any } | null, prizes: Array<{ __typename?: 'RafflePrize', lineNumber: number, prizeName: string }>, rounds: Array<{ __typename?: 'RaffleRound', id: number, isOpen: boolean, createdAt: any, drawDate: any, drawNumber: number }>, notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }> } };

export type RaffleFragment = { __typename?: 'Raffle', id: number, name: string, repeating: RaffleRepetitionType, ended: boolean, additionalInfoEnabled: boolean | null, form: { __typename?: 'Form', schema: any, uiSchema: any } | null, notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }> };

export type createRaffleVariables = Exact<{
  clubId: Scalars['Float']['input'];
  input: NewRaffleInput;
}>;


export type createRaffle = { __typename?: 'Mutation', createRaffle: { __typename?: 'Raffle', id: number, name: string, repeating: RaffleRepetitionType, ended: boolean, additionalInfoEnabled: boolean | null, form: { __typename?: 'Form', schema: any, uiSchema: any } | null, notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }> } };

export type updateRaffleVariables = Exact<{
  raffleId: Scalars['Float']['input'];
  input: EditRaffleInput;
}>;


export type updateRaffle = { __typename?: 'Mutation', updateRaffle: { __typename?: 'Raffle', name: string, notifications: Array<{ __typename?: 'Notification', targetGroup: AudienceGroup, preferredChannels: Array<Channel>, contents: Array<{ __typename?: 'NotificationContent', channel: Channel, content: string, subject: string | null }>, timings: Array<{ __typename?: 'NotificationTiming', triggerEvent: NotificationTimingType, offset: number | null }>, targetGroupOptions: { __typename?: 'AudienceOptions', excludeOneTimePlayers: boolean } }> } };

export type finishRaffleRoundVariables = Exact<{
  eventId: Scalars['Float']['input'];
  inputs: PublishWinnerInputs;
}>;


export type finishRaffleRound = { __typename?: 'Mutation', publishRaffleWinners: { __typename?: 'FundraiserEvent', id: number } };

export type generateRaffleWinnerVariables = Exact<{
  eventId: Scalars['Float']['input'];
}>;


export type generateRaffleWinner = { __typename?: 'Query', randomRaffleWinner: Array<{ __typename?: 'GeneratedRaffleWinner', ticketLineId: number, firstName: string, lastName: string, email: string, phoneNumber: string | null, ticketNumber: string, prize: { __typename?: 'RafflePrize', lineNumber: number, prizeName: string } }> };

export type getRaffleTicketInfoVariables = Exact<{
  eventId: Scalars['Float']['input'];
  ticketNumber: Scalars['Float']['input'];
}>;


export type getRaffleTicketInfo = { __typename?: 'Query', raffleTicketInfo: { __typename?: 'RaffleTicketInfo', firstName: string | null, lastName: string | null, ticketNumber: string | null, paidat: any | null, boughtfrom: TicketBoughtFrom | null } };

export type createOnboardingLinkVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type createOnboardingLink = { __typename?: 'Query', createOnboardingLink: string };

export type getStripeAccountDetailsVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type getStripeAccountDetails = { __typename?: 'Query', getStripeAccountDetails: { __typename?: 'StripeAccount', detailsSubmitted: boolean, payoutsEnabled: boolean, pendingVerification: boolean, currentlyDue: boolean, errors: boolean, disabledReason: string | null, isPayoutManual: boolean, externalAccounts: Array<{ __typename?: 'StripeExternalAccount', id: string, bankName: string, last4: string, currency: Currency, defaultForCurrency: boolean }>, availableBalance: { __typename?: 'StripeAvailableBalance', amount: number, currency: Currency } } };

export type createLoginLinkVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type createLoginLink = { __typename?: 'Query', createLoginLink: string };

export type finishSetupVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type finishSetup = { __typename?: 'Mutation', finishSetup: boolean };

export type checkStripeConnectAccountVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type checkStripeConnectAccount = { __typename?: 'Query', checkStripeConnectAccount: boolean };

export type createStripeConnectAccountVariables = Exact<{
  clubId: Scalars['Float']['input'];
}>;


export type createStripeConnectAccount = { __typename?: 'Query', createStripeConnectAccount: string };

export type getUserAutoCompleteOptionsVariables = Exact<{
  clubId: Scalars['Float']['input'];
  searchTerm: Scalars['String']['input'];
  withRole?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type getUserAutoCompleteOptions = { __typename?: 'Query', getUserAutoCompleteOptions: Array<{ __typename?: 'UserAutocompleteItem', id: number, email: string, firstName: string, lastName: string, birthDate: any | null, type: UserType }> };



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  FundraiserWithTicketTypes: ( PublicLottoWithTicketTypes ) | ( PublicRaffleWithTicketTypes );
};


/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AdditionalInfo: ResolverTypeWrapper<AdditionalInfo>;
  AdditionalInfoInput: AdditionalInfoInput;
  Address: ResolverTypeWrapper<Address>;
  AddressInput: AddressInput;
  AndroidGradleTasks: AndroidGradleTasks;
  AppConfig: ResolverTypeWrapper<AppConfig>;
  Article: ResolverTypeWrapper<Article>;
  ArticleInput: ArticleInput;
  ArticleSearchDateRange: ArticleSearchDateRange;
  ArticleView: ResolverTypeWrapper<ArticleView>;
  AttendeeList: ResolverTypeWrapper<AttendeeList>;
  AudienceGroup: AudienceGroup;
  AudienceOptions: ResolverTypeWrapper<AudienceOptions>;
  AudienceOptionsInput: AudienceOptionsInput;
  AutocompleteItem: ResolverTypeWrapper<AutocompleteItem>;
  Badge: ResolverTypeWrapper<Badge>;
  BlacklistedContactData: ResolverTypeWrapper<BlacklistedContactData>;
  BlacklistedContactDataWithType: BlacklistedContactDataWithType;
  Bookmark: ResolverTypeWrapper<Bookmark>;
  BookmarkInput: BookmarkInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Branding: ResolverTypeWrapper<Branding>;
  BugReportMessage: BugReportMessage;
  BuildDetails: BuildDetails;
  BuildVersionFilter: BuildVersionFilter;
  BulkActionInput: BulkActionInput;
  BulkTicketInfo: BulkTicketInfo;
  BulkUploadArg: BulkUploadArg;
  BundledFundraiser: ResolverTypeWrapper<BundledFundraiser>;
  BundledFundraiserData: ResolverTypeWrapper<BundledFundraiserData>;
  BuyTicketCombinations: BuyTicketCombinations;
  Cart: Cart;
  CartEvent: CartEvent;
  CartLotto: CartLotto;
  CartMembership: CartMembership;
  CartRaffle: CartRaffle;
  ChangeMembershipTypeActiveInput: ChangeMembershipTypeActiveInput;
  Channel: Channel;
  Club: ResolverTypeWrapper<Club>;
  ClubContactMessage: ClubContactMessage;
  ClubInput: ClubInput;
  ClubListPaginationInput: ClubListPaginationInput;
  ClubListPaginationOrder: ClubListPaginationOrder;
  ClubOwnerInput: ClubOwnerInput;
  ClubSalesData: ResolverTypeWrapper<ClubSalesData>;
  ClubStatus: ClubStatus;
  Clubtype: Clubtype;
  CodeOfConductData: ResolverTypeWrapper<CodeOfConductData>;
  ColorScheme: ColorScheme;
  Colors: ResolverTypeWrapper<Colors>;
  CombinationInput: CombinationInput;
  ContactDetails: ResolverTypeWrapper<ContactDetails>;
  ContactDetailsBlacklisted: ResolverTypeWrapper<ContactDetailsBlacklisted>;
  ContactDetailsPaginationInput: ContactDetailsPaginationInput;
  ContactOrderByParams: ContactOrderByParams;
  ContactType: ContactType;
  Country: ResolverTypeWrapper<Country>;
  CreateEventTicketData: CreateEventTicketData;
  CreateEventTicketInput: CreateEventTicketInput;
  CreateFormInput: CreateFormInput;
  CreateGroupInput: CreateGroupInput;
  CreateGroupMembershipInput: CreateGroupMembershipInput;
  CreateGroupMembershipSubtypeInput: CreateGroupMembershipSubtypeInput;
  CreateMembershipTypeInput: CreateMembershipTypeInput;
  Currency: Currency;
  CurrentFundraiserRoundSales: ResolverTypeWrapper<CurrentFundraiserRoundSales>;
  CustomerCard: ResolverTypeWrapper<CustomerCard>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DeviceId: ResolverTypeWrapper<DeviceId>;
  EditGroupInput: EditGroupInput;
  EditGroupMembershipInput: EditGroupMembershipInput;
  EditGroupMembershipSubtypeInput: EditGroupMembershipSubtypeInput;
  EditLottoInput: EditLottoInput;
  EditMembershipTypeInput: EditMembershipTypeInput;
  EditRaffleInput: EditRaffleInput;
  EditTicketTypesInput: EditTicketTypesInput;
  Event: ResolverTypeWrapper<Event>;
  EventFilter: EventFilter;
  EventInput: EventInput;
  EventTicket: ResolverTypeWrapper<EventTicket>;
  EventTicketInput: EventTicketInput;
  EventTicketOwner: EventTicketOwner;
  EventTicketPurchaseStatusType: EventTicketPurchaseStatusType;
  EventTicketStatusType: EventTicketStatusType;
  EventTicketToPurchase: EventTicketToPurchase;
  EventTicketTransactionDetails: ResolverTypeWrapper<EventTicketTransactionDetails>;
  EventTicketType: ResolverTypeWrapper<EventTicketType>;
  ExtendedFundraiserEnum: ExtendedFundraiserEnum;
  ExtendedMembershipType: ResolverTypeWrapper<ExtendedMembershipType>;
  ExtendedPaymentItemType: ExtendedPaymentItemType;
  ExtendedPaymentStatus: ExtendedPaymentStatus;
  ExtendedPaymentType: ExtendedPaymentType;
  Features: ResolverTypeWrapper<Features>;
  FeaturesWithClosedFundraiser: ResolverTypeWrapper<FeaturesWithClosedFundraiser>;
  FileUploadRequestResponse: ResolverTypeWrapper<FileUploadRequestResponse>;
  FileUploadRequestResponseField: ResolverTypeWrapper<FileUploadRequestResponseField>;
  FileUploadType: FileUploadType;
  FilterCountTransactionsInput: FilterCountTransactionsInput;
  FilterFundraiserEventStatisticsInput: FilterFundraiserEventStatisticsInput;
  FilterFundraiserTicketsInput: FilterFundraiserTicketsInput;
  FilterMembersInput: FilterMembersInput;
  FilterMobileBuildStatusInput: FilterMobileBuildStatusInput;
  FilterPayoutsInput: FilterPayoutsInput;
  FilterTransactionsInput: FilterTransactionsInput;
  FirstNameLength: ResolverTypeWrapper<FirstNameLength>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Form: ResolverTypeWrapper<Form>;
  FormData: ResolverTypeWrapper<FormData>;
  FormDataDataArray: ResolverTypeWrapper<FormDataDataArray>;
  FormDataDataArrayInput: FormDataDataArrayInput;
  FormDataFromFileResponse: ResolverTypeWrapper<FormDataFromFileResponse>;
  Fundraiser: ResolverTypeWrapper<Fundraiser>;
  FundraiserAdditionalInfoInput: FundraiserAdditionalInfoInput;
  FundraiserBundleConfirmationResult: ResolverTypeWrapper<FundraiserBundleConfirmationResult>;
  FundraiserCategory: ResolverTypeWrapper<FundraiserCategory>;
  FundraiserDescription: ResolverTypeWrapper<FundraiserDescription>;
  FundraiserEvent: ResolverTypeWrapper<FundraiserEvent>;
  FundraiserEventResult: ResolverTypeWrapper<FundraiserEventResult>;
  FundraiserEventResultTicketLine: ResolverTypeWrapper<FundraiserEventResultTicketLine>;
  FundraiserEventStatisticDetails: ResolverTypeWrapper<FundraiserEventStatisticDetails>;
  FundraiserEventStatistics: ResolverTypeWrapper<FundraiserEventStatistics>;
  FundraiserEventStatisticsPaginationInput: FundraiserEventStatisticsPaginationInput;
  FundraiserEventStatisticsPaginationOrder: FundraiserEventStatisticsPaginationOrder;
  FundraiserEventTicket: ResolverTypeWrapper<FundraiserEventTicket>;
  FundraiserEventTicketPayment: ResolverTypeWrapper<FundraiserEventTicketPayment>;
  FundraiserEventWithWinners: ResolverTypeWrapper<FundraiserEventWithWinners>;
  FundraiserNotificationPreviewFields: ResolverTypeWrapper<FundraiserNotificationPreviewFields>;
  FundraiserNotificationPreviewTemplateData: ResolverTypeWrapper<FundraiserNotificationPreviewTemplateData>;
  FundraiserParticipantTicket: ResolverTypeWrapper<FundraiserParticipantTicket>;
  FundraiserRounds: ResolverTypeWrapper<FundraiserRounds>;
  FundraiserTicket: ResolverTypeWrapper<FundraiserTicket>;
  FundraiserTicketDetails: ResolverTypeWrapper<FundraiserTicketDetails>;
  FundraiserTicketLine: ResolverTypeWrapper<FundraiserTicketLine>;
  FundraiserTicketLineItem: ResolverTypeWrapper<FundraiserTicketLineItem>;
  FundraiserTicketPayment: ResolverTypeWrapper<FundraiserTicketPayment>;
  FundraiserTicketTransactionDetails: ResolverTypeWrapper<FundraiserTicketTransactionDetails>;
  FundraiserTicketType: ResolverTypeWrapper<FundraiserTicketType>;
  FundraiserTickets: ResolverTypeWrapper<FundraiserTickets>;
  FundraiserTicketsPaginationInput: FundraiserTicketsPaginationInput;
  FundraiserType: FundraiserType;
  FundraiserWinner: ResolverTypeWrapper<FundraiserWinner>;
  FundraiserWinners: ResolverTypeWrapper<FundraiserWinners>;
  FundraiserWithTicketTypes: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['FundraiserWithTicketTypes']>;
  Gender: Gender;
  GeneratedRaffleWinner: ResolverTypeWrapper<GeneratedRaffleWinner>;
  GetMembershipTypesInput: GetMembershipTypesInput;
  Gradient: ResolverTypeWrapper<Gradient>;
  GrantRole: ResolverTypeWrapper<GrantRole>;
  GrantRoleError: ResolverTypeWrapper<GrantRoleError>;
  GrantRoleInput: GrantRoleInput;
  Group: ResolverTypeWrapper<Group>;
  GroupListPaginationInput: GroupListPaginationInput;
  GroupMember: ResolverTypeWrapper<GroupMember>;
  GroupMembershipCart: GroupMembershipCart;
  GroupMembershipDescription: ResolverTypeWrapper<GroupMembershipDescription>;
  GroupMessage: ResolverTypeWrapper<GroupMessage>;
  GroupUserInput: GroupUserInput;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  ImportMembersInput: ImportMembersInput;
  InputFeatures: InputFeatures;
  InputSettings: InputSettings;
  Instalment: ResolverTypeWrapper<Instalment>;
  InstalmentPayment: ResolverTypeWrapper<InstalmentPayment>;
  InstalmentPaymentStatus: ResolverTypeWrapper<InstalmentPaymentStatus>;
  InstalmentPlan: ResolverTypeWrapper<InstalmentPlan>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  LastUnregisteredMembersNotificationDate: ResolverTypeWrapper<LastUnregisteredMembersNotificationDate>;
  LinkStatus: ResolverTypeWrapper<LinkStatus>;
  ListArticlesInput: ListArticlesInput;
  ListEventsInput: ListEventsInput;
  ListFundraiserRoundsInput: ListFundraiserRoundsInput;
  ListMembersInput: ListMembersInput;
  ListMembershipInput: ListMembershipInput;
  ListMobileBuildStatusWithStats: ResolverTypeWrapper<ListMobileBuildStatusWithStats>;
  ListPayoutsInput: ListPayoutsInput;
  ListTransactionsInput: ListTransactionsInput;
  ListUserFundraiserTicketsInput: ListUserFundraiserTicketsInput;
  Lotto: ResolverTypeWrapper<Lotto>;
  LottoParticipant: ResolverTypeWrapper<LottoParticipant>;
  LottoRepetitionType: LottoRepetitionType;
  LottoRound: ResolverTypeWrapper<LottoRound>;
  LottoSales: ResolverTypeWrapper<LottoSales>;
  LottoTicket: ResolverTypeWrapper<LottoTicket>;
  LottoTicketType: ResolverTypeWrapper<LottoTicketType>;
  LottoTicketsPaginationOrder: LottoTicketsPaginationOrder;
  MatchThreeWinnerTicketIds: MatchThreeWinnerTicketIds;
  MemberFormDataDuplicationsInput: MemberFormDataDuplicationsInput;
  MemberFormDataDuplicationsResponse: ResolverTypeWrapper<MemberFormDataDuplicationsResponse>;
  MemberFormDataImportInput: MemberFormDataImportInput;
  MemberGender: MemberGender;
  MemberMembershipTypeInput: MemberMembershipTypeInput;
  MemberWithResponse: ResolverTypeWrapper<MemberWithResponse>;
  MembersPaginationOrder: MembersPaginationOrder;
  Membership: ResolverTypeWrapper<Membership>;
  MembershipFilterInput: MembershipFilterInput;
  MembershipFundraiserTicket: ResolverTypeWrapper<MembershipFundraiserTicket>;
  MembershipGroupSubtype: ResolverTypeWrapper<MembershipGroupSubtype>;
  MembershipInstalment: ResolverTypeWrapper<MembershipInstalment>;
  MembershipInstalmentDetails: ResolverTypeWrapper<MembershipInstalmentDetails>;
  MembershipList: ResolverTypeWrapper<MembershipList>;
  MembershipListPaginationOrder: MembershipListPaginationOrder;
  MembershipMember: ResolverTypeWrapper<MembershipMember>;
  MembershipMemberData: ResolverTypeWrapper<MembershipMemberData>;
  MembershipMemberDataInput: MembershipMemberDataInput;
  MembershipMemberDetailed: ResolverTypeWrapper<MembershipMemberDetailed>;
  MembershipMemberFormDataInput: MembershipMemberFormDataInput;
  MembershipMemberImportDuplicationCheckInput: MembershipMemberImportDuplicationCheckInput;
  MembershipMemberImportDuplications: ResolverTypeWrapper<MembershipMemberImportDuplications>;
  MembershipRenewInDue: ResolverTypeWrapper<MembershipRenewInDue>;
  MembershipStatus: MembershipStatus;
  MembershipTransactionDetails: ResolverTypeWrapper<MembershipTransactionDetails>;
  MembershipType: ResolverTypeWrapper<MembershipType>;
  MembershipTypeFundraiserTicketType: ResolverTypeWrapper<MembershipTypeFundraiserTicketType>;
  MembershipValidToInput: MembershipValidToInput;
  MessagesListPaginationInput: MessagesListPaginationInput;
  MobileAppBuildBulkAction: MobileAppBuildBulkAction;
  MobileAppBuildPlatform: MobileAppBuildPlatform;
  MobileAppBuildStatus: MobileAppBuildStatus;
  MobileAppInput: MobileAppInput;
  MobileBuild: ResolverTypeWrapper<MobileBuild>;
  MobileBuildStatus: ResolverTypeWrapper<MobileBuildStatus>;
  MobileBuildStatusInput: MobileBuildStatusInput;
  MobileBuildStatusPaginationOrder: MobileBuildStatusPaginationOrder;
  MobileBuilds: ResolverTypeWrapper<MobileBuilds>;
  MobilePlatform: MobilePlatform;
  MobileScreens: MobileScreens;
  ModifyUserInput: ModifyUserInput;
  Mutation: ResolverTypeWrapper<{}>;
  NewLottoInput: NewLottoInput;
  NewMessage: NewMessage;
  NewRaffleInput: NewRaffleInput;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationBlacklist: ResolverTypeWrapper<NotificationBlacklist>;
  NotificationContent: ResolverTypeWrapper<NotificationContent>;
  NotificationContentInput: NotificationContentInput;
  NotificationInput: NotificationInput;
  NotificationInstance: ResolverTypeWrapper<NotificationInstance>;
  NotificationTiming: ResolverTypeWrapper<NotificationTiming>;
  NotificationTimingType: NotificationTimingType;
  NotificationTrigger: ResolverTypeWrapper<NotificationTrigger>;
  OfflineWinnerInput: OfflineWinnerInput;
  Order: Order;
  OrderDirection: OrderDirection;
  PaginatedOrderedSearchInputClass: PaginatedOrderedSearchInputClass;
  PaginatedSearchArticleResponse: ResolverTypeWrapper<PaginatedSearchArticleResponse>;
  PaginatedSearchClubResponse: ResolverTypeWrapper<PaginatedSearchClubResponse>;
  PaginatedSearchContactDetailsBlacklistedResponse: ResolverTypeWrapper<PaginatedSearchContactDetailsBlacklistedResponse>;
  PaginatedSearchEventResponse: ResolverTypeWrapper<PaginatedSearchEventResponse>;
  PaginatedSearchExtendedMembershipTypeResponse: ResolverTypeWrapper<PaginatedSearchExtendedMembershipTypeResponse>;
  PaginatedSearchFundraiserEventResponse: ResolverTypeWrapper<PaginatedSearchFundraiserEventResponse>;
  PaginatedSearchFundraiserEventStatisticDetailsResponse: ResolverTypeWrapper<PaginatedSearchFundraiserEventStatisticDetailsResponse>;
  PaginatedSearchFundraiserRoundsResponse: ResolverTypeWrapper<PaginatedSearchFundraiserRoundsResponse>;
  PaginatedSearchFundraiserTicketDetailsResponse: ResolverTypeWrapper<PaginatedSearchFundraiserTicketDetailsResponse>;
  PaginatedSearchFundraiserTicketsResponse: ResolverTypeWrapper<PaginatedSearchFundraiserTicketsResponse>;
  PaginatedSearchGroupMessageResponse: ResolverTypeWrapper<PaginatedSearchGroupMessageResponse>;
  PaginatedSearchGroupResponse: ResolverTypeWrapper<PaginatedSearchGroupResponse>;
  PaginatedSearchInput: PaginatedSearchInput;
  PaginatedSearchMembershipListResponse: ResolverTypeWrapper<PaginatedSearchMembershipListResponse>;
  PaginatedSearchMobileBuildStatusResponse: ResolverTypeWrapper<PaginatedSearchMobileBuildStatusResponse>;
  PaginatedSearchPaymentResponse: ResolverTypeWrapper<PaginatedSearchPaymentResponse>;
  PaginatedSearchPayoutResponseResponse: ResolverTypeWrapper<PaginatedSearchPayoutResponseResponse>;
  PaginatedSearchPublicEventResponse: ResolverTypeWrapper<PaginatedSearchPublicEventResponse>;
  PaginatedSearchPublicUserTypeResponse: ResolverTypeWrapper<PaginatedSearchPublicUserTypeResponse>;
  PaginatedSearchUserDetailsResponse: ResolverTypeWrapper<PaginatedSearchUserDetailsResponse>;
  PaginationNext: ResolverTypeWrapper<PaginationNext>;
  Payment: ResolverTypeWrapper<Payment>;
  PaymentItemType: PaymentItemType;
  PaymentOption: PaymentOption;
  PaymentSession: ResolverTypeWrapper<PaymentSession>;
  PaymentStatus: PaymentStatus;
  PaymentType: PaymentType;
  Payout: ResolverTypeWrapper<Payout>;
  PayoutIncomeStream: ResolverTypeWrapper<PayoutIncomeStream>;
  PayoutIncomeStreams: ResolverTypeWrapper<PayoutIncomeStreams>;
  PayoutIncomeStreamsResponse: ResolverTypeWrapper<PayoutIncomeStreamsResponse>;
  PayoutResponse: ResolverTypeWrapper<PayoutResponse>;
  PayoutStatus: PayoutStatus;
  PayoutToBankInput: PayoutToBankInput;
  PlatformsEnum: PlatformsEnum;
  PublicBuyTicketUser: PublicBuyTicketUser;
  PublicClub: ResolverTypeWrapper<PublicClub>;
  PublicClubWithSuffix: ResolverTypeWrapper<PublicClubWithSuffix>;
  PublicEvent: ResolverTypeWrapper<PublicEvent>;
  PublicEventTicketType: ResolverTypeWrapper<PublicEventTicketType>;
  PublicForm: ResolverTypeWrapper<PublicForm>;
  PublicLotto: ResolverTypeWrapper<PublicLotto>;
  PublicLottoRound: ResolverTypeWrapper<PublicLottoRound>;
  PublicLottoTicketType: ResolverTypeWrapper<PublicLottoTicketType>;
  PublicLottoWithTicketTypes: ResolverTypeWrapper<PublicLottoWithTicketTypes>;
  PublicLottoWithoutRounds: ResolverTypeWrapper<PublicLottoWithoutRounds>;
  PublicPayment: ResolverTypeWrapper<PublicPayment>;
  PublicPurchaseUser: PublicPurchaseUser;
  PublicRaffle: ResolverTypeWrapper<PublicRaffle>;
  PublicRaffleRound: ResolverTypeWrapper<PublicRaffleRound>;
  PublicRaffleTicketType: ResolverTypeWrapper<PublicRaffleTicketType>;
  PublicRaffleWithTicketTypes: ResolverTypeWrapper<PublicRaffleWithTicketTypes>;
  PublicRaffleWithoutRounds: ResolverTypeWrapper<PublicRaffleWithoutRounds>;
  PublicUser: PublicUser;
  PublicUserType: ResolverTypeWrapper<PublicUserType>;
  PublishInputs: PublishInputs;
  PublishType: PublishType;
  PublishWinnerInputs: PublishWinnerInputs;
  PurchaseData: ResolverTypeWrapper<PurchaseData>;
  Query: ResolverTypeWrapper<{}>;
  Raffle: ResolverTypeWrapper<Raffle>;
  RaffleParticipant: ResolverTypeWrapper<RaffleParticipant>;
  RafflePrize: ResolverTypeWrapper<RafflePrize>;
  RafflePrizeInput: RafflePrizeInput;
  RaffleRepetitionType: RaffleRepetitionType;
  RaffleRound: ResolverTypeWrapper<RaffleRound>;
  RaffleSales: ResolverTypeWrapper<RaffleSales>;
  RaffleTicket: ResolverTypeWrapper<RaffleTicket>;
  RaffleTicketInfo: ResolverTypeWrapper<RaffleTicketInfo>;
  RaffleTicketType: ResolverTypeWrapper<RaffleTicketType>;
  RaffleWinner: ResolverTypeWrapper<RaffleWinner>;
  RaffleWinnerInput: RaffleWinnerInput;
  RandomWinnerInput: RandomWinnerInput;
  RecurringPurchase: ResolverTypeWrapper<RecurringPurchase>;
  Redirect: ResolverTypeWrapper<Redirect>;
  RefundMembersAction: RefundMembersAction;
  RefundReason: RefundReason;
  RefundTransactionsInput: RefundTransactionsInput;
  RepetitionType: RepetitionType;
  Response: ResolverTypeWrapper<Response>;
  RoundToFinish: ResolverTypeWrapper<RoundToFinish>;
  SalesData: ResolverTypeWrapper<SalesData>;
  Schedule: ResolverTypeWrapper<Schedule>;
  ScheduleInput: ScheduleInput;
  ScheduleItemType: ScheduleItemType;
  ScheduleNotificationType: ScheduleNotificationType;
  ScheduleType: ScheduleType;
  SchedulingMode: SchedulingMode;
  SendUnregisteredMembersNotificationResponse: ResolverTypeWrapper<SendUnregisteredMembersNotificationResponse>;
  SenderIdInput: SenderIdInput;
  Settings: ResolverTypeWrapper<Settings>;
  SmsMessage: ResolverTypeWrapper<SmsMessage>;
  SocialMediaArticlePostInput: SocialMediaArticlePostInput;
  SocialMediaAuth: ResolverTypeWrapper<SocialMediaAuth>;
  SocialMediaConnection: ResolverTypeWrapper<SocialMediaConnection>;
  SocialMediaPlatform: SocialMediaPlatform;
  SocialMediaPostInput: SocialMediaPostInput;
  SocialMediaShareArticleSettings: ResolverTypeWrapper<SocialMediaShareArticleSettings>;
  SocialMediaShareSettings: ResolverTypeWrapper<SocialMediaShareSettings>;
  Sponsorship: ResolverTypeWrapper<Sponsorship>;
  SponsorshipInput: SponsorshipInput;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StripeAccount: ResolverTypeWrapper<StripeAccount>;
  StripeAvailableBalance: ResolverTypeWrapper<StripeAvailableBalance>;
  StripeConfig: ResolverTypeWrapper<StripeConfig>;
  StripeConfigField: ResolverTypeWrapper<StripeConfigField>;
  StripeExternalAccount: ResolverTypeWrapper<StripeExternalAccount>;
  StripeWebhookIdentifier: StripeWebhookIdentifier;
  SummarizedResponses: ResolverTypeWrapper<SummarizedResponses>;
  Team: ResolverTypeWrapper<Team>;
  TermsAndConditions: ResolverTypeWrapper<TermsAndConditions>;
  TermsAndConditionsData: ResolverTypeWrapper<TermsAndConditionsData>;
  TicketBoughtFrom: TicketBoughtFrom;
  TicketColor: TicketColor;
  TicketType: TicketType;
  TicketTypePublic: ResolverTypeWrapper<TicketTypePublic>;
  TicketTypesInput: TicketTypesInput;
  TicketWithLineItems: ResolverTypeWrapper<TicketWithLineItems>;
  TicketingType: TicketingType;
  TimeMetadataPublic: ResolverTypeWrapper<TimeMetadataPublic>;
  TimeRange: TimeRange;
  Timezone: Timezone;
  TimingInput: TimingInput;
  TransactionDetailsResponse: ResolverTypeWrapper<TransactionDetailsResponse>;
  TransactionsCountResponse: ResolverTypeWrapper<TransactionsCountResponse>;
  TwilioConfig: ResolverTypeWrapper<TwilioConfig>;
  TypeGAA: TypeGAA;
  UpdateFormInput: UpdateFormInput;
  UpdatePushnotificationInput: UpdatePushnotificationInput;
  UpsertBookmarkInput: UpsertBookmarkInput;
  User: ResolverTypeWrapper<User>;
  UserAutocompleteItem: ResolverTypeWrapper<UserAutocompleteItem>;
  UserContribution: ResolverTypeWrapper<UserContribution>;
  UserDetails: ResolverTypeWrapper<UserDetails>;
  UserType: UserType;
  UsersNewRole: UsersNewRole;
  ValidMembersFilter: ValidMembersFilter;
  ValidatedMembershipMemberDataInput: ValidatedMembershipMemberDataInput;
  WinnerTicketWithCombination: ResolverTypeWrapper<WinnerTicketWithCombination>;
  WinnerType: ResolverTypeWrapper<WinnerType>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AdditionalInfo: AdditionalInfo;
  AdditionalInfoInput: AdditionalInfoInput;
  Address: Address;
  AddressInput: AddressInput;
  AppConfig: AppConfig;
  Article: Article;
  ArticleInput: ArticleInput;
  ArticleSearchDateRange: ArticleSearchDateRange;
  ArticleView: ArticleView;
  AttendeeList: AttendeeList;
  AudienceOptions: AudienceOptions;
  AudienceOptionsInput: AudienceOptionsInput;
  AutocompleteItem: AutocompleteItem;
  Badge: Badge;
  BlacklistedContactData: BlacklistedContactData;
  BlacklistedContactDataWithType: BlacklistedContactDataWithType;
  Bookmark: Bookmark;
  BookmarkInput: BookmarkInput;
  Boolean: Scalars['Boolean']['output'];
  Branding: Branding;
  BugReportMessage: BugReportMessage;
  BuildDetails: BuildDetails;
  BulkActionInput: BulkActionInput;
  BulkTicketInfo: BulkTicketInfo;
  BulkUploadArg: BulkUploadArg;
  BundledFundraiser: BundledFundraiser;
  BundledFundraiserData: BundledFundraiserData;
  BuyTicketCombinations: BuyTicketCombinations;
  Cart: Cart;
  CartEvent: CartEvent;
  CartLotto: CartLotto;
  CartMembership: CartMembership;
  CartRaffle: CartRaffle;
  ChangeMembershipTypeActiveInput: ChangeMembershipTypeActiveInput;
  Club: Club;
  ClubContactMessage: ClubContactMessage;
  ClubInput: ClubInput;
  ClubListPaginationInput: ClubListPaginationInput;
  ClubOwnerInput: ClubOwnerInput;
  ClubSalesData: ClubSalesData;
  CodeOfConductData: CodeOfConductData;
  Colors: Colors;
  CombinationInput: CombinationInput;
  ContactDetails: ContactDetails;
  ContactDetailsBlacklisted: ContactDetailsBlacklisted;
  ContactDetailsPaginationInput: ContactDetailsPaginationInput;
  Country: Country;
  CreateEventTicketData: CreateEventTicketData;
  CreateEventTicketInput: CreateEventTicketInput;
  CreateFormInput: CreateFormInput;
  CreateGroupInput: CreateGroupInput;
  CreateGroupMembershipInput: CreateGroupMembershipInput;
  CreateGroupMembershipSubtypeInput: CreateGroupMembershipSubtypeInput;
  CreateMembershipTypeInput: CreateMembershipTypeInput;
  CurrentFundraiserRoundSales: CurrentFundraiserRoundSales;
  CustomerCard: CustomerCard;
  DateTime: Scalars['DateTime']['output'];
  DeviceId: DeviceId;
  EditGroupInput: EditGroupInput;
  EditGroupMembershipInput: EditGroupMembershipInput;
  EditGroupMembershipSubtypeInput: EditGroupMembershipSubtypeInput;
  EditLottoInput: EditLottoInput;
  EditMembershipTypeInput: EditMembershipTypeInput;
  EditRaffleInput: EditRaffleInput;
  EditTicketTypesInput: EditTicketTypesInput;
  Event: Event;
  EventInput: EventInput;
  EventTicket: EventTicket;
  EventTicketInput: EventTicketInput;
  EventTicketOwner: EventTicketOwner;
  EventTicketToPurchase: EventTicketToPurchase;
  EventTicketTransactionDetails: EventTicketTransactionDetails;
  EventTicketType: EventTicketType;
  ExtendedMembershipType: ExtendedMembershipType;
  Features: Features;
  FeaturesWithClosedFundraiser: FeaturesWithClosedFundraiser;
  FileUploadRequestResponse: FileUploadRequestResponse;
  FileUploadRequestResponseField: FileUploadRequestResponseField;
  FilterCountTransactionsInput: FilterCountTransactionsInput;
  FilterFundraiserEventStatisticsInput: FilterFundraiserEventStatisticsInput;
  FilterFundraiserTicketsInput: FilterFundraiserTicketsInput;
  FilterMembersInput: FilterMembersInput;
  FilterMobileBuildStatusInput: FilterMobileBuildStatusInput;
  FilterPayoutsInput: FilterPayoutsInput;
  FilterTransactionsInput: FilterTransactionsInput;
  FirstNameLength: FirstNameLength;
  Float: Scalars['Float']['output'];
  Form: Form;
  FormData: FormData;
  FormDataDataArray: FormDataDataArray;
  FormDataDataArrayInput: FormDataDataArrayInput;
  FormDataFromFileResponse: FormDataFromFileResponse;
  Fundraiser: Fundraiser;
  FundraiserAdditionalInfoInput: FundraiserAdditionalInfoInput;
  FundraiserBundleConfirmationResult: FundraiserBundleConfirmationResult;
  FundraiserCategory: FundraiserCategory;
  FundraiserDescription: FundraiserDescription;
  FundraiserEvent: FundraiserEvent;
  FundraiserEventResult: FundraiserEventResult;
  FundraiserEventResultTicketLine: FundraiserEventResultTicketLine;
  FundraiserEventStatisticDetails: FundraiserEventStatisticDetails;
  FundraiserEventStatistics: FundraiserEventStatistics;
  FundraiserEventStatisticsPaginationInput: FundraiserEventStatisticsPaginationInput;
  FundraiserEventTicket: FundraiserEventTicket;
  FundraiserEventTicketPayment: FundraiserEventTicketPayment;
  FundraiserEventWithWinners: FundraiserEventWithWinners;
  FundraiserNotificationPreviewFields: FundraiserNotificationPreviewFields;
  FundraiserNotificationPreviewTemplateData: FundraiserNotificationPreviewTemplateData;
  FundraiserParticipantTicket: FundraiserParticipantTicket;
  FundraiserRounds: FundraiserRounds;
  FundraiserTicket: FundraiserTicket;
  FundraiserTicketDetails: FundraiserTicketDetails;
  FundraiserTicketLine: FundraiserTicketLine;
  FundraiserTicketLineItem: FundraiserTicketLineItem;
  FundraiserTicketPayment: FundraiserTicketPayment;
  FundraiserTicketTransactionDetails: FundraiserTicketTransactionDetails;
  FundraiserTicketType: FundraiserTicketType;
  FundraiserTickets: FundraiserTickets;
  FundraiserTicketsPaginationInput: FundraiserTicketsPaginationInput;
  FundraiserWinner: FundraiserWinner;
  FundraiserWinners: FundraiserWinners;
  FundraiserWithTicketTypes: ResolversUnionTypes<ResolversParentTypes>['FundraiserWithTicketTypes'];
  GeneratedRaffleWinner: GeneratedRaffleWinner;
  GetMembershipTypesInput: GetMembershipTypesInput;
  Gradient: Gradient;
  GrantRole: GrantRole;
  GrantRoleError: GrantRoleError;
  GrantRoleInput: GrantRoleInput;
  Group: Group;
  GroupListPaginationInput: GroupListPaginationInput;
  GroupMember: GroupMember;
  GroupMembershipCart: GroupMembershipCart;
  GroupMembershipDescription: GroupMembershipDescription;
  GroupMessage: GroupMessage;
  GroupUserInput: GroupUserInput;
  ID: Scalars['ID']['output'];
  ImportMembersInput: ImportMembersInput;
  InputFeatures: InputFeatures;
  InputSettings: InputSettings;
  Instalment: Instalment;
  InstalmentPayment: InstalmentPayment;
  InstalmentPaymentStatus: InstalmentPaymentStatus;
  InstalmentPlan: InstalmentPlan;
  Int: Scalars['Int']['output'];
  JSON: Scalars['JSON']['output'];
  LastUnregisteredMembersNotificationDate: LastUnregisteredMembersNotificationDate;
  LinkStatus: LinkStatus;
  ListArticlesInput: ListArticlesInput;
  ListEventsInput: ListEventsInput;
  ListFundraiserRoundsInput: ListFundraiserRoundsInput;
  ListMembersInput: ListMembersInput;
  ListMembershipInput: ListMembershipInput;
  ListMobileBuildStatusWithStats: ListMobileBuildStatusWithStats;
  ListPayoutsInput: ListPayoutsInput;
  ListTransactionsInput: ListTransactionsInput;
  ListUserFundraiserTicketsInput: ListUserFundraiserTicketsInput;
  Lotto: Lotto;
  LottoParticipant: LottoParticipant;
  LottoRound: LottoRound;
  LottoSales: LottoSales;
  LottoTicket: LottoTicket;
  LottoTicketType: LottoTicketType;
  MatchThreeWinnerTicketIds: MatchThreeWinnerTicketIds;
  MemberFormDataDuplicationsInput: MemberFormDataDuplicationsInput;
  MemberFormDataDuplicationsResponse: MemberFormDataDuplicationsResponse;
  MemberFormDataImportInput: MemberFormDataImportInput;
  MemberMembershipTypeInput: MemberMembershipTypeInput;
  MemberWithResponse: MemberWithResponse;
  Membership: Membership;
  MembershipFilterInput: MembershipFilterInput;
  MembershipFundraiserTicket: MembershipFundraiserTicket;
  MembershipGroupSubtype: MembershipGroupSubtype;
  MembershipInstalment: MembershipInstalment;
  MembershipInstalmentDetails: MembershipInstalmentDetails;
  MembershipList: MembershipList;
  MembershipMember: MembershipMember;
  MembershipMemberData: MembershipMemberData;
  MembershipMemberDataInput: MembershipMemberDataInput;
  MembershipMemberDetailed: MembershipMemberDetailed;
  MembershipMemberFormDataInput: MembershipMemberFormDataInput;
  MembershipMemberImportDuplicationCheckInput: MembershipMemberImportDuplicationCheckInput;
  MembershipMemberImportDuplications: MembershipMemberImportDuplications;
  MembershipRenewInDue: MembershipRenewInDue;
  MembershipTransactionDetails: MembershipTransactionDetails;
  MembershipType: MembershipType;
  MembershipTypeFundraiserTicketType: MembershipTypeFundraiserTicketType;
  MessagesListPaginationInput: MessagesListPaginationInput;
  MobileAppInput: MobileAppInput;
  MobileBuild: MobileBuild;
  MobileBuildStatus: MobileBuildStatus;
  MobileBuildStatusInput: MobileBuildStatusInput;
  MobileBuilds: MobileBuilds;
  ModifyUserInput: ModifyUserInput;
  Mutation: {};
  NewLottoInput: NewLottoInput;
  NewMessage: NewMessage;
  NewRaffleInput: NewRaffleInput;
  Notification: Notification;
  NotificationBlacklist: NotificationBlacklist;
  NotificationContent: NotificationContent;
  NotificationContentInput: NotificationContentInput;
  NotificationInput: NotificationInput;
  NotificationInstance: NotificationInstance;
  NotificationTiming: NotificationTiming;
  NotificationTrigger: NotificationTrigger;
  OfflineWinnerInput: OfflineWinnerInput;
  Order: Order;
  PaginatedOrderedSearchInputClass: PaginatedOrderedSearchInputClass;
  PaginatedSearchArticleResponse: PaginatedSearchArticleResponse;
  PaginatedSearchClubResponse: PaginatedSearchClubResponse;
  PaginatedSearchContactDetailsBlacklistedResponse: PaginatedSearchContactDetailsBlacklistedResponse;
  PaginatedSearchEventResponse: PaginatedSearchEventResponse;
  PaginatedSearchExtendedMembershipTypeResponse: PaginatedSearchExtendedMembershipTypeResponse;
  PaginatedSearchFundraiserEventResponse: PaginatedSearchFundraiserEventResponse;
  PaginatedSearchFundraiserEventStatisticDetailsResponse: PaginatedSearchFundraiserEventStatisticDetailsResponse;
  PaginatedSearchFundraiserRoundsResponse: PaginatedSearchFundraiserRoundsResponse;
  PaginatedSearchFundraiserTicketDetailsResponse: PaginatedSearchFundraiserTicketDetailsResponse;
  PaginatedSearchFundraiserTicketsResponse: PaginatedSearchFundraiserTicketsResponse;
  PaginatedSearchGroupMessageResponse: PaginatedSearchGroupMessageResponse;
  PaginatedSearchGroupResponse: PaginatedSearchGroupResponse;
  PaginatedSearchInput: PaginatedSearchInput;
  PaginatedSearchMembershipListResponse: PaginatedSearchMembershipListResponse;
  PaginatedSearchMobileBuildStatusResponse: PaginatedSearchMobileBuildStatusResponse;
  PaginatedSearchPaymentResponse: PaginatedSearchPaymentResponse;
  PaginatedSearchPayoutResponseResponse: PaginatedSearchPayoutResponseResponse;
  PaginatedSearchPublicEventResponse: PaginatedSearchPublicEventResponse;
  PaginatedSearchPublicUserTypeResponse: PaginatedSearchPublicUserTypeResponse;
  PaginatedSearchUserDetailsResponse: PaginatedSearchUserDetailsResponse;
  PaginationNext: PaginationNext;
  Payment: Payment;
  PaymentOption: PaymentOption;
  PaymentSession: PaymentSession;
  Payout: Payout;
  PayoutIncomeStream: PayoutIncomeStream;
  PayoutIncomeStreams: PayoutIncomeStreams;
  PayoutIncomeStreamsResponse: PayoutIncomeStreamsResponse;
  PayoutResponse: PayoutResponse;
  PayoutToBankInput: PayoutToBankInput;
  PublicBuyTicketUser: PublicBuyTicketUser;
  PublicClub: PublicClub;
  PublicClubWithSuffix: PublicClubWithSuffix;
  PublicEvent: PublicEvent;
  PublicEventTicketType: PublicEventTicketType;
  PublicForm: PublicForm;
  PublicLotto: PublicLotto;
  PublicLottoRound: PublicLottoRound;
  PublicLottoTicketType: PublicLottoTicketType;
  PublicLottoWithTicketTypes: PublicLottoWithTicketTypes;
  PublicLottoWithoutRounds: PublicLottoWithoutRounds;
  PublicPayment: PublicPayment;
  PublicPurchaseUser: PublicPurchaseUser;
  PublicRaffle: PublicRaffle;
  PublicRaffleRound: PublicRaffleRound;
  PublicRaffleTicketType: PublicRaffleTicketType;
  PublicRaffleWithTicketTypes: PublicRaffleWithTicketTypes;
  PublicRaffleWithoutRounds: PublicRaffleWithoutRounds;
  PublicUser: PublicUser;
  PublicUserType: PublicUserType;
  PublishInputs: PublishInputs;
  PublishWinnerInputs: PublishWinnerInputs;
  PurchaseData: PurchaseData;
  Query: {};
  Raffle: Raffle;
  RaffleParticipant: RaffleParticipant;
  RafflePrize: RafflePrize;
  RafflePrizeInput: RafflePrizeInput;
  RaffleRound: RaffleRound;
  RaffleSales: RaffleSales;
  RaffleTicket: RaffleTicket;
  RaffleTicketInfo: RaffleTicketInfo;
  RaffleTicketType: RaffleTicketType;
  RaffleWinner: RaffleWinner;
  RaffleWinnerInput: RaffleWinnerInput;
  RandomWinnerInput: RandomWinnerInput;
  RecurringPurchase: RecurringPurchase;
  Redirect: Redirect;
  RefundTransactionsInput: RefundTransactionsInput;
  Response: Response;
  RoundToFinish: RoundToFinish;
  SalesData: SalesData;
  Schedule: Schedule;
  ScheduleInput: ScheduleInput;
  SendUnregisteredMembersNotificationResponse: SendUnregisteredMembersNotificationResponse;
  SenderIdInput: SenderIdInput;
  Settings: Settings;
  SmsMessage: SmsMessage;
  SocialMediaArticlePostInput: SocialMediaArticlePostInput;
  SocialMediaAuth: SocialMediaAuth;
  SocialMediaConnection: SocialMediaConnection;
  SocialMediaPostInput: SocialMediaPostInput;
  SocialMediaShareArticleSettings: SocialMediaShareArticleSettings;
  SocialMediaShareSettings: SocialMediaShareSettings;
  Sponsorship: Sponsorship;
  SponsorshipInput: SponsorshipInput;
  String: Scalars['String']['output'];
  StripeAccount: StripeAccount;
  StripeAvailableBalance: StripeAvailableBalance;
  StripeConfig: StripeConfig;
  StripeConfigField: StripeConfigField;
  StripeExternalAccount: StripeExternalAccount;
  SummarizedResponses: SummarizedResponses;
  Team: Team;
  TermsAndConditions: TermsAndConditions;
  TermsAndConditionsData: TermsAndConditionsData;
  TicketTypePublic: TicketTypePublic;
  TicketTypesInput: TicketTypesInput;
  TicketWithLineItems: TicketWithLineItems;
  TimeMetadataPublic: TimeMetadataPublic;
  TimingInput: TimingInput;
  TransactionDetailsResponse: TransactionDetailsResponse;
  TransactionsCountResponse: TransactionsCountResponse;
  TwilioConfig: TwilioConfig;
  UpdateFormInput: UpdateFormInput;
  UpdatePushnotificationInput: UpdatePushnotificationInput;
  UpsertBookmarkInput: UpsertBookmarkInput;
  User: User;
  UserAutocompleteItem: UserAutocompleteItem;
  UserContribution: UserContribution;
  UserDetails: UserDetails;
  UsersNewRole: UsersNewRole;
  ValidMembersFilter: ValidMembersFilter;
  ValidatedMembershipMemberDataInput: ValidatedMembershipMemberDataInput;
  WinnerTicketWithCombination: WinnerTicketWithCombination;
  WinnerType: WinnerType;
};

export type AdditionalInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdditionalInfo'] = ResolversParentTypes['AdditionalInfo']> = {
  memberId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  address1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  county?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  town?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppConfig'] = ResolversParentTypes['AppConfig']> = {
  apnToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apnTokenKeyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appWelcomeText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appleAppId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appleCompanyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appleContentProviderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appleDeveloperTeamId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appleTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appstoreConnectApiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appstoreConnectKeyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fcmId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firebaseAndroidAppId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firebaseIosAppId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firebaseProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firebaseServiceKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gradleTask?: Resolver<Maybe<ResolversTypes['AndroidGradleTasks']>, ParentType, ContextType>;
  legacyFcmId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legacyFirebaseAndroidAppId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legacyFirebaseProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legacyFirebaseServiceKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newKeyStore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  packageIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  packageName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playStoreServiceKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sha256CertFingerprints?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Article'] = ResolversParentTypes['Article']> = {
  banner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coverImageSource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  excerpt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  facebookPostId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  instagramPostId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  publishType?: Resolver<Maybe<ResolversTypes['PublishType']>, ParentType, ContextType>;
  published?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  publishedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  schedules?: Resolver<Array<ResolversTypes['Schedule']>, ParentType, ContextType>;
  socialMediaShareSettings?: Resolver<ResolversTypes['SocialMediaShareArticleSettings'], ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  twitterTweetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  viewedAlready?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleViewResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleView'] = ResolversParentTypes['ArticleView']> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeListResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttendeeList'] = ResolversParentTypes['AttendeeList']> = {
  additionalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  additionalInfoRaw?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  purchaseDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaserEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaserFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaserLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketHolderFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketHolderLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketHolderPhoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudienceOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AudienceOptions'] = ResolversParentTypes['AudienceOptions']> = {
  excludeOneTimePlayers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AutocompleteItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['AutocompleteItem'] = ResolversParentTypes['AutocompleteItem']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BadgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Badge'] = ResolversParentTypes['Badge']> = {
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  route?: Resolver<ResolversTypes['MobileScreens'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlacklistedContactDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlacklistedContactData'] = ResolversParentTypes['BlacklistedContactData']> = {
  contact?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bookmark'] = ResolversParentTypes['Bookmark']> = {
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iconName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Branding'] = ResolversParentTypes['Branding']> = {
  appIconBackgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appScheme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appleAppId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  codeOfConductUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facebookPixelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  features?: Resolver<ResolversTypes['FeaturesWithClosedFundraiser'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lottoRulesUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packageName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  privacyPolicyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suffix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  termsOfServiceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['Timezone'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundledFundraiserResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundledFundraiser'] = ResolversParentTypes['BundledFundraiser']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundledFundraiserDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundledFundraiserData'] = ResolversParentTypes['BundledFundraiserData']> = {
  fundraiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraisingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketTypeId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubResolvers<ContextType = any, ParentType extends ResolversParentTypes['Club'] = ResolversParentTypes['Club']> = {
  appConfig?: Resolver<ResolversTypes['AppConfig'], ParentType, ContextType>;
  articles?: Resolver<Array<ResolversTypes['Article']>, ParentType, ContextType>;
  blacklistedContacts?: Resolver<Array<ResolversTypes['NotificationBlacklist']>, ParentType, ContextType>;
  bookmarks?: Resolver<Maybe<Array<ResolversTypes['Bookmark']>>, ParentType, ContextType>;
  clubType?: Resolver<ResolversTypes['Clubtype'], ParentType, ContextType>;
  codeOfConductUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colors?: Resolver<ResolversTypes['Colors'], ParentType, ContextType>;
  contactEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['Country'], ParentType, ContextType>;
  eventTicketSalesData?: Resolver<ResolversTypes['ClubSalesData'], ParentType, ContextType, Partial<ClubeventTicketSalesDataArgs>>;
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>;
  facebookPixelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  forms?: Resolver<Array<ResolversTypes['Form']>, ParentType, ContextType>;
  fundraisers?: Resolver<Array<ResolversTypes['Fundraiser']>, ParentType, ContextType>;
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>;
  hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastMembershipExportDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastUnregisteredMembersNotificationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  logoSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lottoRulesAppleUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lottoRulesUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lottoSalesData?: Resolver<ResolversTypes['ClubSalesData'], ParentType, ContextType, Partial<ClublottoSalesDataArgs>>;
  memberSiteUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  membershipSalesData?: Resolver<ResolversTypes['ClubSalesData'], ParentType, ContextType, Partial<ClubmembershipSalesDataArgs>>;
  membershipTypes?: Resolver<Array<ResolversTypes['MembershipType']>, ParentType, ContextType>;
  mobileBuilds?: Resolver<Array<ResolversTypes['MobileBuild']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType>;
  payouts?: Resolver<Array<ResolversTypes['Payout']>, ParentType, ContextType>;
  privacyPolicyUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  raffleSalesData?: Resolver<ResolversTypes['ClubSalesData'], ParentType, ContextType, Partial<ClubraffleSalesDataArgs>>;
  schedules?: Resolver<Array<ResolversTypes['Schedule']>, ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['Settings'], ParentType, ContextType>;
  shortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signupDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  smsMessages?: Resolver<Array<ResolversTypes['SmsMessage']>, ParentType, ContextType>;
  socialMediaConnections?: Resolver<Maybe<Array<ResolversTypes['SocialMediaConnection']>>, ParentType, ContextType>;
  sponsorships?: Resolver<Maybe<Array<ResolversTypes['Sponsorship']>>, ParentType, ContextType>;
  statementDescriptor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ClubStatus'], ParentType, ContextType>;
  stripeConfig?: Resolver<ResolversTypes['StripeConfig'], ParentType, ContextType>;
  suffix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  termsOfServiceAppleUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  termsOfServiceUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeZone?: Resolver<ResolversTypes['Timezone'], ParentType, ContextType>;
  tixserveSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tixserveServerKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twilioSubAccountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClubSalesDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubSalesData'] = ResolversParentTypes['ClubSalesData']> = {
  month?: Resolver<Maybe<ResolversTypes['SalesData']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['SalesData']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['SalesData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CodeOfConductDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CodeOfConductData'] = ResolversParentTypes['CodeOfConductData']> = {
  fileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Colors'] = ResolversParentTypes['Colors']> = {
  appIconBackgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheme?: Resolver<Maybe<ResolversTypes['ColorScheme']>, ParentType, ContextType>;
  secondary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactDetails'] = ResolversParentTypes['ContactDetails']> = {
  deviceTokens?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContactType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactDetailsBlacklistedResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactDetailsBlacklisted'] = ResolversParentTypes['ContactDetailsBlacklisted']> = {
  deviceTokens?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['BlacklistedContactData']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['BlacklistedContactData']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContactType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']> = {
  clubs?: Resolver<Array<ResolversTypes['Club']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  twilioConfig?: Resolver<Maybe<ResolversTypes['TwilioConfig']>, ParentType, ContextType>;
  vat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentFundraiserRoundSalesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrentFundraiserRoundSales'] = ResolversParentTypes['CurrentFundraiserRoundSales']> = {
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerCard'] = ResolversParentTypes['CustomerCard']> = {
  brand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  expireMonth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  expireYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  funding?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasSubscribedItems?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastFour?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchases?: Resolver<Maybe<Array<ResolversTypes['RecurringPurchase']>>, ParentType, ContextType>;
  tickets?: Resolver<Maybe<Array<ResolversTypes['FundraiserTicket']>>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeviceIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeviceId'] = ResolversParentTypes['DeviceId']> = {
  deviceToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = {
  additionalInfoEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coverImageSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  eventTicketPurchaseStatus?: Resolver<ResolversTypes['EventTicketPurchaseStatusType'], ParentType, ContextType>;
  eventTicketStatus?: Resolver<ResolversTypes['EventTicketStatusType'], ParentType, ContextType>;
  facebookPostId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['Form']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  inactiveTicketTypes?: Resolver<Array<ResolversTypes['EventTicketType']>, ParentType, ContextType>;
  instagramPostId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxTickets?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  published?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  schedules?: Resolver<Array<ResolversTypes['Schedule']>, ParentType, ContextType>;
  socialMediaShareSettings?: Resolver<ResolversTypes['SocialMediaShareSettings'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  ticketTypes?: Resolver<Array<ResolversTypes['EventTicketType']>, ParentType, ContextType>;
  ticketingType?: Resolver<Maybe<ResolversTypes['TicketingType']>, ParentType, ContextType>;
  tickets?: Resolver<Array<ResolversTypes['EventTicket']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tixserveEventCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitterTweetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventTicketResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventTicket'] = ResolversParentTypes['EventTicket']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  event?: Resolver<ResolversTypes['Event'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formData?: Resolver<Maybe<ResolversTypes['FormData']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketType?: Resolver<ResolversTypes['EventTicketType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userEventTicketStatus?: Resolver<ResolversTypes['EventTicketPurchaseStatusType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventTicketTransactionDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventTicketTransactionDetails'] = ResolversParentTypes['EventTicketTransactionDetails']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventTicketTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventTicketType'] = ResolversParentTypes['EventTicketType']> = {
  color?: Resolver<ResolversTypes['TicketColor'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  event?: Resolver<ResolversTypes['Event'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfTickets?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tickets?: Resolver<ResolversTypes['EventTicket'], ParentType, ContextType>;
  tixserveTicketTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExtendedMembershipTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExtendedMembershipType'] = ResolversParentTypes['ExtendedMembershipType']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  autoRenewMembersCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bundledFundraiser?: Resolver<Maybe<ResolversTypes['BundledFundraiser']>, ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  daysLeft?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enableInstalmentPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableRenewableMembership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['Form']>, ParentType, ContextType>;
  fundraiserTicketTypeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupDescription?: Resolver<Maybe<ResolversTypes['GroupMembershipDescription']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  inactiveSubtypes?: Resolver<Array<ResolversTypes['MembershipGroupSubtype']>, ParentType, ContextType>;
  includeFundraiserTicket?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  interest?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isAboutToExpire?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSingle?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  membershipGroupSubtypes?: Resolver<Array<ResolversTypes['MembershipGroupSubtype']>, ParentType, ContextType>;
  membershipStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  membershipTypeFundraiserTicketTypes?: Resolver<Maybe<Array<ResolversTypes['MembershipTypeFundraiserTicketType']>>, ParentType, ContextType>;
  memberships?: Resolver<Array<ResolversTypes['Membership']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfInstalments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  publishType?: Resolver<ResolversTypes['PublishType'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  purchaseCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  renewableMembershipDefault?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  renewalMembershipType?: Resolver<Maybe<ResolversTypes['MembershipType']>, ParentType, ContextType>;
  renewedMembershipTypes?: Resolver<Maybe<Array<ResolversTypes['MembershipType']>>, ParentType, ContextType>;
  schedules?: Resolver<Array<ResolversTypes['Schedule']>, ParentType, ContextType>;
  typeGAA?: Resolver<Maybe<ResolversTypes['TypeGAA']>, ParentType, ContextType>;
  validFrom?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Features'] = ResolversParentTypes['Features']> = {
  fundraisers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  fundraisersOnMemberSite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  membership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tickets?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturesWithClosedFundraiserResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeaturesWithClosedFundraiser'] = ResolversParentTypes['FeaturesWithClosedFundraiser']> = {
  fundraisers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  fundraisersOnMemberSite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  haveClosedFundraiser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  membership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tickets?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileUploadRequestResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileUploadRequestResponse'] = ResolversParentTypes['FileUploadRequestResponse']> = {
  fields?: Resolver<Array<ResolversTypes['FileUploadRequestResponseField']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileUploadRequestResponseFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileUploadRequestResponseField'] = ResolversParentTypes['FileUploadRequestResponseField']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FirstNameLengthResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirstNameLength'] = ResolversParentTypes['FirstNameLength']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormResolvers<ContextType = any, ParentType extends ResolversParentTypes['Form'] = ResolversParentTypes['Form']> = {
  club?: Resolver<Maybe<ResolversTypes['Club']>, ParentType, ContextType>;
  clubType?: Resolver<Maybe<ResolversTypes['Clubtype']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>;
  formData?: Resolver<Array<ResolversTypes['FormData']>, ParentType, ContextType>;
  fundraisers?: Resolver<Array<ResolversTypes['Fundraiser']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  membershipGroupSubtypes?: Resolver<Array<ResolversTypes['MembershipGroupSubtype']>, ParentType, ContextType>;
  membershipTypes?: Resolver<Array<ResolversTypes['MembershipType']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  schema?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  uiSchema?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormData'] = ResolversParentTypes['FormData']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  eventTickets?: Resolver<ResolversTypes['EventTicket'], ParentType, ContextType>;
  form?: Resolver<ResolversTypes['Form'], ParentType, ContextType>;
  fundraiserTickets?: Resolver<ResolversTypes['FundraiserTicket'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormDataDataArrayResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormDataDataArray'] = ResolversParentTypes['FormDataDataArray']> = {
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  duplicated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  existing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormDataFromFileResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormDataFromFileResponse'] = ResolversParentTypes['FormDataFromFileResponse']> = {
  fileError?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formData?: Resolver<Array<ResolversTypes['FormDataDataArray']>, ParentType, ContextType>;
  schema?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  uiSchema?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserResolvers<ContextType = any, ParentType extends ResolversParentTypes['Fundraiser'] = ResolversParentTypes['Fundraiser']> = {
  additionalInfoEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  clubSuffix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  currentEvent?: Resolver<Maybe<ResolversTypes['FundraiserEvent']>, ParentType, ContextType>;
  currentRoundSales?: Resolver<ResolversTypes['CurrentFundraiserRoundSales'], ParentType, ContextType, RequireFields<FundraisercurrentRoundSalesArgs, 'clubId'>>;
  demo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['Form']>, ParentType, ContextType>;
  fundraiserDescription?: Resolver<ResolversTypes['FundraiserDescription'], ParentType, ContextType>;
  fundraiserEvents?: Resolver<Array<ResolversTypes['FundraiserEvent']>, ParentType, ContextType>;
  fundraiserType?: Resolver<ResolversTypes['FundraiserCategory'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isAssignedToMembership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastEvent?: Resolver<Maybe<ResolversTypes['FundraiserEvent']>, ParentType, ContextType>;
  matchThreeNumbers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationTriggers?: Resolver<Array<ResolversTypes['NotificationTrigger']>, ParentType, ContextType>;
  notifications?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType>;
  prizes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  randomGeneration?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  repetition?: Resolver<ResolversTypes['RepetitionType'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ticketTypes?: Resolver<Array<ResolversTypes['FundraiserTicketType']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FundraiserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserBundleConfirmationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserBundleConfirmationResult'] = ResolversParentTypes['FundraiserBundleConfirmationResult']> = {
  isConfirmationValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserCategory'] = ResolversParentTypes['FundraiserCategory']> = {
  demo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraisers?: Resolver<Array<ResolversTypes['Fundraiser']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['FundraiserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserDescriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserDescription'] = ResolversParentTypes['FundraiserDescription']> = {
  numberOfBalls?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numberRange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ticketStartNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserEvent'] = ResolversParentTypes['FundraiserEvent']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  eventDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  eventNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fundraiser?: Resolver<ResolversTypes['Fundraiser'], ParentType, ContextType>;
  fundraiserDraws?: Resolver<Array<ResolversTypes['FundraiserEventResult']>, ParentType, ContextType>;
  fundraiserEventStatistics?: Resolver<Maybe<ResolversTypes['FundraiserEventStatistics']>, ParentType, ContextType>;
  fundraiserEventTickets?: Resolver<Array<ResolversTypes['FundraiserEventTicket']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationTriggers?: Resolver<Maybe<Array<ResolversTypes['NotificationTrigger']>>, ParentType, ContextType>;
  prizes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  purchases?: Resolver<Maybe<Array<ResolversTypes['RecurringPurchase']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  winningNumbers?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserEventResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserEventResult'] = ResolversParentTypes['FundraiserEventResult']> = {
  drawDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  eventResultTicketLines?: Resolver<Array<ResolversTypes['FundraiserEventResultTicketLine']>, ParentType, ContextType>;
  fundraiserEvent?: Resolver<ResolversTypes['FundraiserEvent'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  winningNumber?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserEventResultTicketLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserEventResultTicketLine'] = ResolversParentTypes['FundraiserEventResultTicketLine']> = {
  fundraiserEventResult?: Resolver<ResolversTypes['FundraiserEventResult'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  prize?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketLine?: Resolver<ResolversTypes['FundraiserTicketLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserEventStatisticDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserEventStatisticDetails'] = ResolversParentTypes['FundraiserEventStatisticDetails']> = {
  clubId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fundraiserEventId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fundraiserEventNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fundraiserId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fundraiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraiserTypeId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  itemsTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  jackpot?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  roundEnd?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  roundStart?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserEventStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserEventStatistics'] = ResolversParentTypes['FundraiserEventStatistics']> = {
  fundraiserEvent?: Resolver<ResolversTypes['FundraiserEvent'], ParentType, ContextType>;
  itemsTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  priceTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserEventTicketResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserEventTicket'] = ResolversParentTypes['FundraiserEventTicket']> = {
  drawsLeft?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  eventTicketPayment?: Resolver<ResolversTypes['FundraiserEventTicketPayment'], ParentType, ContextType>;
  fundraiserEvent?: Resolver<ResolversTypes['FundraiserEvent'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticket?: Resolver<ResolversTypes['FundraiserTicket'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserEventTicketPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserEventTicketPayment'] = ResolversParentTypes['FundraiserEventTicketPayment']> = {
  eventTicket?: Resolver<ResolversTypes['FundraiserEventTicket'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketPayment?: Resolver<ResolversTypes['FundraiserTicketPayment'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserEventWithWinnersResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserEventWithWinners'] = ResolversParentTypes['FundraiserEventWithWinners']> = {
  event?: Resolver<ResolversTypes['FundraiserEvent'], ParentType, ContextType>;
  winners?: Resolver<Array<ResolversTypes['FundraiserWinners']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserNotificationPreviewFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserNotificationPreviewFields'] = ResolversParentTypes['FundraiserNotificationPreviewFields']> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserNotificationPreviewTemplateDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserNotificationPreviewTemplateData'] = ResolversParentTypes['FundraiserNotificationPreviewTemplateData']> = {
  eventDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventPrize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstNameLengths?: Resolver<Array<ResolversTypes['FirstNameLength']>, ParentType, ContextType>;
  fundraiserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fundraiserWebsiteLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserParticipantTicketResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserParticipantTicket'] = ResolversParentTypes['FundraiserParticipantTicket']> = {
  additionalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  additionalInfoRaw?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  drawsLeft?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roundNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserRoundsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserRounds'] = ResolversParentTypes['FundraiserRounds']> = {
  clubId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  combination?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  drawDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  drawNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fundraiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraiserType?: Resolver<ResolversTypes['FundraiserType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jackpot?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  repetition?: Resolver<ResolversTypes['RaffleRepetitionType'], ParentType, ContextType>;
  roundId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  winners?: Resolver<Maybe<Array<ResolversTypes['FundraiserWinner']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserTicketResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserTicket'] = ResolversParentTypes['FundraiserTicket']> = {
  boughtFrom?: Resolver<ResolversTypes['TicketBoughtFrom'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formData?: Resolver<Maybe<ResolversTypes['FormData']>, ParentType, ContextType>;
  fundraiserEventTickets?: Resolver<Array<ResolversTypes['FundraiserEventTicket']>, ParentType, ContextType>;
  fundraiserTicketLines?: Resolver<Array<ResolversTypes['FundraiserTicketLine']>, ParentType, ContextType>;
  fundraiserTicketPayments?: Resolver<Array<ResolversTypes['FundraiserTicketPayment']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  membershipFundraiserTickets?: Resolver<Maybe<ResolversTypes['MembershipFundraiserTicket']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchases?: Resolver<Maybe<Array<ResolversTypes['RecurringPurchase']>>, ParentType, ContextType>;
  recurring?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  recurringCard?: Resolver<Maybe<ResolversTypes['CustomerCard']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ticketType?: Resolver<ResolversTypes['FundraiserTicketType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserTicketDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserTicketDetails'] = ResolversParentTypes['FundraiserTicketDetails']> = {
  boughtFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clubId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  combination?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  draw?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  drawId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraiserId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fundraiserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  itemType?: Resolver<ResolversTypes['FundraiserType'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineOrder?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lottoId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfLines?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  paidAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  paymentId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchaseDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  raffleId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurring?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rowId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketLineId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ticketType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketTypeId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserTicketLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserTicketLine'] = ResolversParentTypes['FundraiserTicketLine']> = {
  eventResultTicketLines?: Resolver<Array<ResolversTypes['FundraiserEventResultTicketLine']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lineNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticket?: Resolver<ResolversTypes['FundraiserTicket'], ParentType, ContextType>;
  ticketLineItems?: Resolver<Array<ResolversTypes['FundraiserTicketLineItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserTicketLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserTicketLineItem'] = ResolversParentTypes['FundraiserTicketLineItem']> = {
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  item?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketLine?: Resolver<ResolversTypes['FundraiserTicketLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserTicketPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserTicketPayment'] = ResolversParentTypes['FundraiserTicketPayment']> = {
  eventTicketPayments?: Resolver<Array<ResolversTypes['FundraiserEventTicketPayment']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  payment?: Resolver<ResolversTypes['Payment'], ParentType, ContextType>;
  ticket?: Resolver<ResolversTypes['FundraiserTicket'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserTicketTransactionDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserTicketTransactionDetails'] = ResolversParentTypes['FundraiserTicketTransactionDetails']> = {
  autoRenew?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserTicketTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserTicketType'] = ResolversParentTypes['FundraiserTicketType']> = {
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fundraiser?: Resolver<ResolversTypes['Fundraiser'], ParentType, ContextType>;
  fundraiserTickets?: Resolver<Array<ResolversTypes['FundraiserTicket']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  membershipFundraiserTickets?: Resolver<Maybe<Array<ResolversTypes['MembershipFundraiserTicket']>>, ParentType, ContextType>;
  membershipTypeFundraiserTicketTypes?: Resolver<Maybe<ResolversTypes['MembershipTypeFundraiserTicketType']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfRounds?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserTicketsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserTickets'] = ResolversParentTypes['FundraiserTickets']> = {
  combination?: Resolver<Maybe<Array<Array<ResolversTypes['Float']>>>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  drawDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  drawNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fundraiserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraiserType?: Resolver<ResolversTypes['FundraiserType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  jackpotAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numberOfRounds?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  paidAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  paymentId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  prizes?: Resolver<Maybe<Array<ResolversTypes['RafflePrize']>>, ParentType, ContextType>;
  recurring?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  remainingDraws?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repetition?: Resolver<ResolversTypes['RaffleRepetitionType'], ParentType, ContextType>;
  roundId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketNumbers?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  ticketType?: Resolver<ResolversTypes['TicketType'], ParentType, ContextType>;
  ticketTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  winners?: Resolver<Maybe<Array<ResolversTypes['FundraiserWinner']>>, ParentType, ContextType>;
  winningNumbers?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserWinnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserWinner'] = ResolversParentTypes['FundraiserWinner']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prize?: Resolver<Maybe<ResolversTypes['RafflePrize']>, ParentType, ContextType>;
  ticketNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserWinnersResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserWinners'] = ResolversParentTypes['FundraiserWinners']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combination?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prize?: Resolver<Maybe<ResolversTypes['RafflePrize']>, ParentType, ContextType>;
  ticketId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketLineId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FundraiserWithTicketTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['FundraiserWithTicketTypes'] = ResolversParentTypes['FundraiserWithTicketTypes']> = {
  __resolveType: TypeResolveFn<'PublicLottoWithTicketTypes' | 'PublicRaffleWithTicketTypes', ParentType, ContextType>;
};

export type GeneratedRaffleWinnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneratedRaffleWinner'] = ResolversParentTypes['GeneratedRaffleWinner']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prize?: Resolver<ResolversTypes['RafflePrize'], ParentType, ContextType>;
  ticketLineId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GradientResolvers<ContextType = any, ParentType extends ResolversParentTypes['Gradient'] = ResolversParentTypes['Gradient']> = {
  end?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GrantRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['GrantRole'] = ResolversParentTypes['GrantRole']> = {
  data?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['GrantRoleError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GrantRoleErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['GrantRoleError'] = ResolversParentTypes['GrantRoleError']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translationKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = {
  club?: Resolver<ResolversTypes['PublicClub'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  messages?: Resolver<Array<ResolversTypes['GroupMessage']>, ParentType, ContextType>;
  myUnrespondedMessagesCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['GroupMember']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupMember'] = ResolversParentTypes['GroupMember']> = {
  group?: Resolver<ResolversTypes['Group'], ParentType, ContextType>;
  manager?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['PublicUserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupMembershipDescriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupMembershipDescription'] = ResolversParentTypes['GroupMembershipDescription']> = {
  maxGroupSize?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minGroupSize?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupMessage'] = ResolversParentTypes['GroupMessage']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['Group'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  membersWithResponse?: Resolver<Maybe<Array<ResolversTypes['MemberWithResponse']>>, ParentType, ContextType>;
  myResponse?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  responses?: Resolver<Array<ResolversTypes['Response']>, ParentType, ContextType>;
  schedules?: Resolver<Array<ResolversTypes['Schedule']>, ParentType, ContextType>;
  sentOn?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  summarizedResponses?: Resolver<ResolversTypes['SummarizedResponses'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstalmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Instalment'] = ResolversParentTypes['Instalment']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  instalmentPayments?: Resolver<Maybe<Array<ResolversTypes['InstalmentPayment']>>, ParentType, ContextType>;
  membershipInstalments?: Resolver<Maybe<Array<ResolversTypes['MembershipInstalment']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstalmentPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstalmentPayment'] = ResolversParentTypes['InstalmentPayment']> = {
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  instalment?: Resolver<ResolversTypes['Instalment'], ParentType, ContextType>;
  payment?: Resolver<ResolversTypes['Payment'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstalmentPaymentStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstalmentPaymentStatus'] = ResolversParentTypes['InstalmentPaymentStatus']> = {
  areInstalmentsConsideredAsFullyPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  numberOfInstalments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberOfInstalmentsBehindOnPayment?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberOfPaidInstalments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstalmentPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstalmentPlan'] = ResolversParentTypes['InstalmentPlan']> = {
  calculatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  monthlyFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberOfMonths?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JSONScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LastUnregisteredMembersNotificationDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['LastUnregisteredMembersNotificationDate'] = ResolversParentTypes['LastUnregisteredMembersNotificationDate']> = {
  isRecent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastUnregisteredMembersNotificationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkStatus'] = ResolversParentTypes['LinkStatus']> = {
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListMobileBuildStatusWithStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListMobileBuildStatusWithStats'] = ResolversParentTypes['ListMobileBuildStatusWithStats']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  latestAppVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  numberOfAbortableBuilds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfUpdatableBuilds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['MobileBuildStatus']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LottoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Lotto'] = ResolversParentTypes['Lotto']> = {
  additionalInfoEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  currentRound?: Resolver<Maybe<ResolversTypes['LottoRound']>, ParentType, ContextType>;
  demo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['Form']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  jackpotAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  matchThreeNumbers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notifications?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType>;
  numberOfBalls?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberRange?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  randomGeneration?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  repeating?: Resolver<ResolversTypes['LottoRepetitionType'], ParentType, ContextType>;
  rounds?: Resolver<Array<ResolversTypes['LottoRound']>, ParentType, ContextType>;
  ticketTypes?: Resolver<Array<ResolversTypes['LottoTicketType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LottoParticipantResolvers<ContextType = any, ParentType extends ResolversParentTypes['LottoParticipant'] = ResolversParentTypes['LottoParticipant']> = {
  additionalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  additionalInfoRaw?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  combination?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LottoRoundResolvers<ContextType = any, ParentType extends ResolversParentTypes['LottoRound'] = ResolversParentTypes['LottoRound']> = {
  combination?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  drawDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  drawNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jackpot?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lotto?: Resolver<ResolversTypes['Lotto'], ParentType, ContextType>;
  tickets?: Resolver<Array<ResolversTypes['LottoTicket']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  winners?: Resolver<Maybe<Array<ResolversTypes['WinnerType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LottoSalesResolvers<ContextType = any, ParentType extends ResolversParentTypes['LottoSales'] = ResolversParentTypes['LottoSales']> = {
  lottoid?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  roundid?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LottoTicketResolvers<ContextType = any, ParentType extends ResolversParentTypes['LottoTicket'] = ResolversParentTypes['LottoTicket']> = {
  boughtFrom?: Resolver<ResolversTypes['TicketBoughtFrom'], ParentType, ContextType>;
  combination?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remainingDraws?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  round?: Resolver<ResolversTypes['LottoRound'], ParentType, ContextType>;
  ticketType?: Resolver<ResolversTypes['LottoTicketType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LottoTicketTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['LottoTicketType'] = ResolversParentTypes['LottoTicketType']> = {
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lotto?: Resolver<ResolversTypes['Lotto'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfRounds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  recurringTickets?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tickets?: Resolver<Array<ResolversTypes['LottoTicket']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TicketType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberFormDataDuplicationsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MemberFormDataDuplicationsResponse'] = ResolversParentTypes['MemberFormDataDuplicationsResponse']> = {
  formData?: Resolver<Array<ResolversTypes['FormDataDataArray']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberWithResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MemberWithResponse'] = ResolversParentTypes['MemberWithResponse']> = {
  birthDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  response?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipResolvers<ContextType = any, ParentType extends ResolversParentTypes['Membership'] = ResolversParentTypes['Membership']> = {
  autoRenew?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  autoRenewDisabledAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  cancelled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isCompleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['MembershipMember']>, ParentType, ContextType>;
  membershipFundraiserTickets?: Resolver<Maybe<Array<ResolversTypes['MembershipFundraiserTicket']>>, ParentType, ContextType>;
  membershipInstalments?: Resolver<Maybe<Array<ResolversTypes['MembershipInstalment']>>, ParentType, ContextType>;
  membershipRenewInDue?: Resolver<Maybe<ResolversTypes['MembershipRenewInDue']>, ParentType, ContextType>;
  membershipType?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  paymentCancelledAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  paymentCancellerUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  renewalMemberships?: Resolver<ResolversTypes['Membership'], ParentType, ContextType>;
  renewedMembership?: Resolver<Maybe<ResolversTypes['Membership']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MembershipStatus'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipFundraiserTicketResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipFundraiserTicket'] = ResolversParentTypes['MembershipFundraiserTicket']> = {
  fundraiserTicket?: Resolver<Maybe<ResolversTypes['FundraiserTicket']>, ParentType, ContextType>;
  fundraiserTicketType?: Resolver<ResolversTypes['FundraiserTicketType'], ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  membership?: Resolver<ResolversTypes['Membership'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipGroupSubtypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipGroupSubtype'] = ResolversParentTypes['MembershipGroupSubtype']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['Form']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maxNumberOfMembers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['MembershipMember']>, ParentType, ContextType>;
  membershipType?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  prices?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  purchaseCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  typeGAA?: Resolver<Maybe<ResolversTypes['TypeGAA']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipInstalmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipInstalment'] = ResolversParentTypes['MembershipInstalment']> = {
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  instalment?: Resolver<ResolversTypes['Instalment'], ParentType, ContextType>;
  membership?: Resolver<ResolversTypes['Membership'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipInstalmentDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipInstalmentDetails'] = ResolversParentTypes['MembershipInstalmentDetails']> = {
  areInstalmentsConsideredAsFullyPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cancelledAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  cancellerUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  numberOfInstalments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberOfInstalmentsBehindOnPayment?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberOfPaidInstalments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipListResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipList'] = ResolversParentTypes['MembershipList']> = {
  clubId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  medicalInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  membershipFormData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  membershipTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipMember'] = ResolversParentTypes['MembershipMember']> = {
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  instalmentPaymentStatus?: Resolver<ResolversTypes['InstalmentPaymentStatus'], ParentType, ContextType>;
  memberData?: Resolver<Maybe<ResolversTypes['MembershipMemberData']>, ParentType, ContextType>;
  membershipFormData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  membershipPayment?: Resolver<ResolversTypes['Membership'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MembershipStatus'], ParentType, ContextType>;
  subtype?: Resolver<Maybe<ResolversTypes['MembershipGroupSubtype']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipMemberDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipMemberData'] = ResolversParentTypes['MembershipMemberData']> = {
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  associatedClub?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  association?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['MemberGender']>, ParentType, ContextType>;
  irishFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  irishLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isAnyMedicalCondition?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPlayer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  medicalInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ngbId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipMemberDetailedResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipMemberDetailed'] = ResolversParentTypes['MembershipMemberDetailed']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  instalmentPaymentStatus?: Resolver<ResolversTypes['InstalmentPaymentStatus'], ParentType, ContextType>;
  memberData?: Resolver<Maybe<ResolversTypes['MembershipMemberData']>, ParentType, ContextType>;
  membershipFormData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  membershipName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  membershipPayment?: Resolver<ResolversTypes['Membership'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MembershipStatus'], ParentType, ContextType>;
  subtype?: Resolver<Maybe<ResolversTypes['MembershipGroupSubtype']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipMemberImportDuplicationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipMemberImportDuplications'] = ResolversParentTypes['MembershipMemberImportDuplications']> = {
  csvDuplicatedRows?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  dbDuplicatedRows?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipRenewInDueResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipRenewInDue'] = ResolversParentTypes['MembershipRenewInDue']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  validFrom?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipTransactionDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipTransactionDetails'] = ResolversParentTypes['MembershipTransactionDetails']> = {
  autoRenew?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cancelled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  instalment?: Resolver<Maybe<ResolversTypes['MembershipInstalmentDetails']>, ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['MembershipMemberDetailed']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MembershipStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipType'] = ResolversParentTypes['MembershipType']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  bundledFundraiser?: Resolver<Maybe<ResolversTypes['BundledFundraiser']>, ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enableInstalmentPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableRenewableMembership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['Form']>, ParentType, ContextType>;
  fundraiserTicketTypeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupDescription?: Resolver<Maybe<ResolversTypes['GroupMembershipDescription']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  inactiveSubtypes?: Resolver<Array<ResolversTypes['MembershipGroupSubtype']>, ParentType, ContextType>;
  includeFundraiserTicket?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  interest?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isSingle?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  membershipGroupSubtypes?: Resolver<Array<ResolversTypes['MembershipGroupSubtype']>, ParentType, ContextType>;
  membershipTypeFundraiserTicketTypes?: Resolver<Maybe<Array<ResolversTypes['MembershipTypeFundraiserTicketType']>>, ParentType, ContextType>;
  memberships?: Resolver<Array<ResolversTypes['Membership']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfInstalments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  publishType?: Resolver<ResolversTypes['PublishType'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  purchaseCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  renewableMembershipDefault?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  renewalMembershipType?: Resolver<Maybe<ResolversTypes['MembershipType']>, ParentType, ContextType>;
  renewedMembershipTypes?: Resolver<Maybe<Array<ResolversTypes['MembershipType']>>, ParentType, ContextType>;
  schedules?: Resolver<Array<ResolversTypes['Schedule']>, ParentType, ContextType>;
  typeGAA?: Resolver<Maybe<ResolversTypes['TypeGAA']>, ParentType, ContextType>;
  validFrom?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipTypeFundraiserTicketTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipTypeFundraiserTicketType'] = ResolversParentTypes['MembershipTypeFundraiserTicketType']> = {
  fundraiserTicketType?: Resolver<Maybe<ResolversTypes['FundraiserTicketType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  membershipType?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MobileBuildResolvers<ContextType = any, ParentType extends ResolversParentTypes['MobileBuild'] = ResolversParentTypes['MobileBuild']> = {
  branch?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  buildNotes?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  buildNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  buildSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  buildVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['MobileAppBuildPlatform'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MobileAppBuildStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MobileBuildStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['MobileBuildStatus'] = ResolversParentTypes['MobileBuildStatus']> = {
  appVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  branch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buildNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buildNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buildSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buildStatus?: Resolver<Maybe<ResolversTypes['MobileAppBuildStatus']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  mobileBuildId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organisationId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['MobileAppBuildPlatform'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MobileBuildsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MobileBuilds'] = ResolversParentTypes['MobileBuilds']> = {
  builds?: Resolver<Array<ResolversTypes['MobileBuild']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  abortBuild?: Resolver<ResolversTypes['MobileBuild'], ParentType, ContextType, RequireFields<MutationabortBuildArgs, 'buildId'>>;
  bulkAction?: Resolver<ResolversTypes['MobileBuilds'], ParentType, ContextType, RequireFields<MutationbulkActionArgs, 'bulkActionInput'>>;
  buyEventTicket?: Resolver<ResolversTypes['PaymentSession'], ParentType, ContextType, RequireFields<MutationbuyEventTicketArgs, 'eventId' | 'eventTicketInput'>>;
  buyMembership?: Resolver<ResolversTypes['PaymentSession'], ParentType, ContextType, RequireFields<MutationbuyMembershipArgs, 'membershipTypeId'>>;
  cancelInstalmentPayment?: Resolver<ResolversTypes['Payment'], ParentType, ContextType, RequireFields<MutationcancelInstalmentPaymentArgs, 'clubId' | 'membersAction' | 'paymentId'>>;
  cancelRecurringTicket?: Resolver<ResolversTypes['FundraiserTicket'], ParentType, ContextType, RequireFields<MutationcancelRecurringTicketArgs, 'ticketId'>>;
  changeMembershipTypeActive?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType, RequireFields<MutationchangeMembershipTypeActiveArgs, 'membershipType' | 'membershipTypeId'>>;
  changePassword?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationchangePasswordArgs, 'newPassword' | 'oldPassword'>>;
  confirmFilledBundledFundraiser?: Resolver<ResolversTypes['FundraiserBundleConfirmationResult'], ParentType, ContextType, RequireFields<MutationconfirmFilledBundledFundraiserArgs, 'bundleHash' | 'cart'>>;
  contactClub?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationcontactClubArgs, 'clubId' | 'message'>>;
  createArticle?: Resolver<ResolversTypes['Article'], ParentType, ContextType, RequireFields<MutationcreateArticleArgs, 'clubId' | 'input' | 'notificationSchedule' | 'publishSchedule' | 'socialMediaArticlePostInput'>>;
  createClub?: Resolver<ResolversTypes['Club'], ParentType, ContextType, RequireFields<MutationcreateClubArgs, 'admin' | 'club'>>;
  createClubForm?: Resolver<ResolversTypes['Form'], ParentType, ContextType, RequireFields<MutationcreateClubFormArgs, 'input'>>;
  createEvent?: Resolver<ResolversTypes['Event'], ParentType, ContextType, RequireFields<MutationcreateEventArgs, 'clubId' | 'input' | 'notificationSchedule' | 'socialMediaPostInput'>>;
  createGroup?: Resolver<ResolversTypes['Group'], ParentType, ContextType, RequireFields<MutationcreateGroupArgs, 'clubId' | 'input'>>;
  createGroupMembership?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType, RequireFields<MutationcreateGroupMembershipArgs, 'clubId' | 'membership' | 'notificationSchedule' | 'publishSchedule'>>;
  createLotto?: Resolver<ResolversTypes['Lotto'], ParentType, ContextType, RequireFields<MutationcreateLottoArgs, 'clubId' | 'input'>>;
  createMembershipType?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType, RequireFields<MutationcreateMembershipTypeArgs, 'clubId' | 'notificationSchedule' | 'publishSchedule'>>;
  createRaffle?: Resolver<ResolversTypes['Raffle'], ParentType, ContextType, RequireFields<MutationcreateRaffleArgs, 'clubId' | 'input'>>;
  deleteArticle?: Resolver<ResolversTypes['Article'], ParentType, ContextType, RequireFields<MutationdeleteArticleArgs, 'articleId'>>;
  deleteClubForm?: Resolver<ResolversTypes['Form'], ParentType, ContextType, RequireFields<MutationdeleteClubFormArgs, 'id'>>;
  deleteEvent?: Resolver<ResolversTypes['Event'], ParentType, ContextType, RequireFields<MutationdeleteEventArgs, 'id'>>;
  deleteGroup?: Resolver<ResolversTypes['Group'], ParentType, ContextType, RequireFields<MutationdeleteGroupArgs, 'id'>>;
  deleteMembershipType?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType, RequireFields<MutationdeleteMembershipTypeArgs, 'membershipTypeId'>>;
  deleteMessage?: Resolver<ResolversTypes['GroupMessage'], ParentType, ContextType, RequireFields<MutationdeleteMessageArgs, 'groupId' | 'messageId'>>;
  editArticle?: Resolver<ResolversTypes['Article'], ParentType, ContextType, RequireFields<MutationeditArticleArgs, 'articleId' | 'input' | 'notificationSchedule' | 'publishSchedule' | 'socialMediaArticlePostInput'>>;
  editEvent?: Resolver<ResolversTypes['Event'], ParentType, ContextType, RequireFields<MutationeditEventArgs, 'id' | 'input' | 'notificationSchedule' | 'socialMediaPostInput'>>;
  editGroup?: Resolver<ResolversTypes['Group'], ParentType, ContextType, RequireFields<MutationeditGroupArgs, 'groupId' | 'input'>>;
  editGroupMembership?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType, RequireFields<MutationeditGroupMembershipArgs, 'membership' | 'membershipTypeId' | 'notificationSchedule' | 'publishSchedule'>>;
  editMembershipType?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType, RequireFields<MutationeditMembershipTypeArgs, 'membershipType' | 'membershipTypeId' | 'notificationSchedule' | 'publishSchedule'>>;
  editSponsorships?: Resolver<Array<ResolversTypes['Sponsorship']>, ParentType, ContextType, RequireFields<MutationeditSponsorshipsArgs, 'clubId' | 'input'>>;
  endFundraiser?: Resolver<ResolversTypes['Fundraiser'], ParentType, ContextType, RequireFields<MutationendFundraiserArgs, 'fundraiserId'>>;
  finishSetup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationfinishSetupArgs, 'clubId'>>;
  grantRoles?: Resolver<ResolversTypes['GrantRole'], ParentType, ContextType, RequireFields<MutationgrantRolesArgs, 'input'>>;
  importMembers?: Resolver<Array<ResolversTypes['MembershipMember']>, ParentType, ContextType, RequireFields<MutationimportMembersArgs, 'members' | 'membershipTypeId'>>;
  makeCardDefault?: Resolver<ResolversTypes['CustomerCard'], ParentType, ContextType, RequireFields<MutationmakeCardDefaultArgs, 'cardId'>>;
  modifyClub?: Resolver<ResolversTypes['Club'], ParentType, ContextType, RequireFields<MutationmodifyClubArgs, 'admin' | 'club' | 'clubId'>>;
  modifyPushnotificationInfo?: Resolver<ResolversTypes['Club'], ParentType, ContextType, RequireFields<MutationmodifyPushnotificationInfoArgs, 'club' | 'clubId'>>;
  modifyUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationmodifyUserArgs, 'id' | 'userInput'>>;
  payoutToBank?: Resolver<ResolversTypes['PayoutResponse'], ParentType, ContextType, RequireFields<MutationpayoutToBankArgs, 'clubId' | 'options'>>;
  pinArticle?: Resolver<ResolversTypes['Article'], ParentType, ContextType, RequireFields<MutationpinArticleArgs, 'articleId'>>;
  pinEvent?: Resolver<ResolversTypes['Event'], ParentType, ContextType, RequireFields<MutationpinEventArgs, 'id'>>;
  publishRaffleWinners?: Resolver<ResolversTypes['FundraiserEvent'], ParentType, ContextType, RequireFields<MutationpublishRaffleWinnersArgs, 'eventId' | 'publishInputs'>>;
  publishWinners?: Resolver<ResolversTypes['FundraiserEvent'], ParentType, ContextType, RequireFields<MutationpublishWinnersArgs, 'eventId' | 'publishInputs'>>;
  purchase?: Resolver<ResolversTypes['Redirect'], ParentType, ContextType, RequireFields<MutationpurchaseArgs, 'cart' | 'option'>>;
  refreshBuild?: Resolver<ResolversTypes['MobileBuild'], ParentType, ContextType, RequireFields<MutationrefreshBuildArgs, 'buildNumber'>>;
  refreshDeviceToken?: Resolver<ResolversTypes['DeviceId'], ParentType, ContextType, RequireFields<MutationrefreshDeviceTokenArgs, 'deviceToken' | 'deviceType' | 'userId'>>;
  refundTransaction?: Resolver<ResolversTypes['Payment'], ParentType, ContextType, RequireFields<MutationrefundTransactionArgs, 'clubId' | 'options'>>;
  registerMember?: Resolver<ResolversTypes['MembershipMember'], ParentType, ContextType, RequireFields<MutationregisterMemberArgs, 'memberData' | 'memberId'>>;
  registerMemberFormData?: Resolver<ResolversTypes['MembershipMember'], ParentType, ContextType, RequireFields<MutationregisterMemberFormDataArgs, 'memberData' | 'memberId'>>;
  removeCard?: Resolver<ResolversTypes['CustomerCard'], ParentType, ContextType, RequireFields<MutationremoveCardArgs, 'cardId'>>;
  resendClubInvitationEmail?: Resolver<ResolversTypes['Club'], ParentType, ContextType, RequireFields<MutationresendClubInvitationEmailArgs, 'clubId'>>;
  revokeRole?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationrevokeRoleArgs, 'userId'>>;
  sendBugReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationsendBugReportArgs, 'message'>>;
  sendMessage?: Resolver<ResolversTypes['GroupMessage'], ParentType, ContextType, RequireFields<MutationsendMessageArgs, 'groupId' | 'message'>>;
  sendResponse?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationsendResponseArgs, 'groupId' | 'messageId' | 'response'>>;
  sendUnregisteredMembersNotification?: Resolver<ResolversTypes['SendUnregisteredMembersNotificationResponse'], ParentType, ContextType, RequireFields<MutationsendUnregisteredMembersNotificationArgs, 'clubId'>>;
  setMembershipAsFullyPaid?: Resolver<ResolversTypes['MembershipMember'], ParentType, ContextType, RequireFields<MutationsetMembershipAsFullyPaidArgs, 'clubId' | 'memberId'>>;
  setMembershipDisableAutoRenew?: Resolver<ResolversTypes['MembershipMember'], ParentType, ContextType, RequireFields<MutationsetMembershipDisableAutoRenewArgs, 'clubId' | 'memberId'>>;
  setUploadedCodeOfConduct?: Resolver<ResolversTypes['CodeOfConductData'], ParentType, ContextType, RequireFields<MutationsetUploadedCodeOfConductArgs, 'clubId' | 'fileUrl'>>;
  setUploadedTermsAndConditions?: Resolver<ResolversTypes['TermsAndConditions'], ParentType, ContextType, RequireFields<MutationsetUploadedTermsAndConditionsArgs, 'clubId' | 'fileUrl' | 'platform'>>;
  setupCard?: Resolver<ResolversTypes['PaymentSession'], ParentType, ContextType>;
  startBuild?: Resolver<ResolversTypes['MobileBuild'], ParentType, ContextType, RequireFields<MutationstartBuildArgs, 'buildDetails'>>;
  startBuilds?: Resolver<ResolversTypes['MobileBuilds'], ParentType, ContextType, RequireFields<MutationstartBuildsArgs, 'buildDetails'>>;
  unpinArticle?: Resolver<ResolversTypes['Article'], ParentType, ContextType, RequireFields<MutationunpinArticleArgs, 'articleId'>>;
  unpinEvent?: Resolver<ResolversTypes['Event'], ParentType, ContextType, RequireFields<MutationunpinEventArgs, 'id'>>;
  updateClubForm?: Resolver<ResolversTypes['Form'], ParentType, ContextType, RequireFields<MutationupdateClubFormArgs, 'id' | 'input'>>;
  updateContactToBlacklist?: Resolver<Array<ResolversTypes['BlacklistedContactData']>, ParentType, ContextType, RequireFields<MutationupdateContactToBlacklistArgs, 'clubId' | 'inputs'>>;
  updateLotto?: Resolver<ResolversTypes['Lotto'], ParentType, ContextType, RequireFields<MutationupdateLottoArgs, 'input' | 'lottoId'>>;
  updateMemberMembershipMemberFormData?: Resolver<ResolversTypes['MembershipMember'], ParentType, ContextType, RequireFields<MutationupdateMemberMembershipMemberFormDataArgs, 'memberDataInput' | 'memberId'>>;
  updateRaffle?: Resolver<ResolversTypes['Raffle'], ParentType, ContextType, RequireFields<MutationupdateRaffleArgs, 'input' | 'raffleId'>>;
  updateSmSSenderId?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationupdateSmSSenderIdArgs, 'clubId' | 'senderId'>>;
  uploadBulkPlayers?: Resolver<Array<ResolversTypes['FundraiserTicket']>, ParentType, ContextType, RequireFields<MutationuploadBulkPlayersArgs, 'data' | 'ticketTypeId'>>;
  uploadRequest?: Resolver<ResolversTypes['FileUploadRequestResponse'], ParentType, ContextType, RequireFields<MutationuploadRequestArgs, 'fileName' | 'type'>>;
  upsertBookmarks?: Resolver<Array<ResolversTypes['Bookmark']>, ParentType, ContextType, RequireFields<MutationupsertBookmarksArgs, 'clubId' | 'input'>>;
  viewArticle?: Resolver<ResolversTypes['Article'], ParentType, ContextType, RequireFields<MutationviewArticleArgs, 'articleId'>>;
};

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  contents?: Resolver<Array<ResolversTypes['NotificationContent']>, ParentType, ContextType>;
  fundraiser?: Resolver<ResolversTypes['Fundraiser'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instances?: Resolver<Array<ResolversTypes['NotificationInstance']>, ParentType, ContextType>;
  preferredChannels?: Resolver<Array<ResolversTypes['Channel']>, ParentType, ContextType>;
  targetGroup?: Resolver<ResolversTypes['AudienceGroup'], ParentType, ContextType>;
  targetGroupOptions?: Resolver<ResolversTypes['AudienceOptions'], ParentType, ContextType>;
  timings?: Resolver<Array<ResolversTypes['NotificationTiming']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationBlacklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationBlacklist'] = ResolversParentTypes['NotificationBlacklist']> = {
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  contact?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationContent'] = ResolversParentTypes['NotificationContent']> = {
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notification?: Resolver<ResolversTypes['Notification'], ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationInstanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationInstance'] = ResolversParentTypes['NotificationInstance']> = {
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notification?: Resolver<ResolversTypes['Notification'], ParentType, ContextType>;
  processedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  smsMessages?: Resolver<Array<ResolversTypes['SmsMessage']>, ParentType, ContextType>;
  trigger?: Resolver<ResolversTypes['NotificationTrigger'], ParentType, ContextType>;
  triggerAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  triggerEvent?: Resolver<ResolversTypes['NotificationTimingType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationTimingResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationTiming'] = ResolversParentTypes['NotificationTiming']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notification?: Resolver<ResolversTypes['Notification'], ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  triggerEvent?: Resolver<ResolversTypes['NotificationTimingType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationTriggerResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationTrigger'] = ResolversParentTypes['NotificationTrigger']> = {
  fundraiser?: Resolver<ResolversTypes['Fundraiser'], ParentType, ContextType>;
  fundraiserEvent?: Resolver<Maybe<ResolversTypes['FundraiserEvent']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instances?: Resolver<Array<ResolversTypes['NotificationInstance']>, ParentType, ContextType>;
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  triggerEvent?: Resolver<ResolversTypes['NotificationTimingType'], ParentType, ContextType>;
  triggeredAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchArticleResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchArticleResponse'] = ResolversParentTypes['PaginatedSearchArticleResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['Article']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchClubResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchClubResponse'] = ResolversParentTypes['PaginatedSearchClubResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['Club']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchContactDetailsBlacklistedResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchContactDetailsBlacklistedResponse'] = ResolversParentTypes['PaginatedSearchContactDetailsBlacklistedResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['ContactDetailsBlacklisted']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchEventResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchEventResponse'] = ResolversParentTypes['PaginatedSearchEventResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchExtendedMembershipTypeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchExtendedMembershipTypeResponse'] = ResolversParentTypes['PaginatedSearchExtendedMembershipTypeResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['ExtendedMembershipType']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchFundraiserEventResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchFundraiserEventResponse'] = ResolversParentTypes['PaginatedSearchFundraiserEventResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['FundraiserEvent']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchFundraiserEventStatisticDetailsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchFundraiserEventStatisticDetailsResponse'] = ResolversParentTypes['PaginatedSearchFundraiserEventStatisticDetailsResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['FundraiserEventStatisticDetails']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchFundraiserRoundsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchFundraiserRoundsResponse'] = ResolversParentTypes['PaginatedSearchFundraiserRoundsResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['FundraiserRounds']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchFundraiserTicketDetailsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchFundraiserTicketDetailsResponse'] = ResolversParentTypes['PaginatedSearchFundraiserTicketDetailsResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['FundraiserTicketDetails']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchFundraiserTicketsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchFundraiserTicketsResponse'] = ResolversParentTypes['PaginatedSearchFundraiserTicketsResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['FundraiserTickets']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchGroupMessageResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchGroupMessageResponse'] = ResolversParentTypes['PaginatedSearchGroupMessageResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['GroupMessage']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchGroupResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchGroupResponse'] = ResolversParentTypes['PaginatedSearchGroupResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchMembershipListResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchMembershipListResponse'] = ResolversParentTypes['PaginatedSearchMembershipListResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['MembershipList']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchMobileBuildStatusResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchMobileBuildStatusResponse'] = ResolversParentTypes['PaginatedSearchMobileBuildStatusResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['MobileBuildStatus']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchPaymentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchPaymentResponse'] = ResolversParentTypes['PaginatedSearchPaymentResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchPayoutResponseResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchPayoutResponseResponse'] = ResolversParentTypes['PaginatedSearchPayoutResponseResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['PayoutResponse']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchPublicEventResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchPublicEventResponse'] = ResolversParentTypes['PaginatedSearchPublicEventResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['PublicEvent']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchPublicUserTypeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchPublicUserTypeResponse'] = ResolversParentTypes['PaginatedSearchPublicUserTypeResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['PublicUserType']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSearchUserDetailsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSearchUserDetailsResponse'] = ResolversParentTypes['PaginatedSearchUserDetailsResponse']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['PaginationNext']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['UserDetails']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginationNextResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginationNext'] = ResolversParentTypes['PaginationNext']> = {
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offset?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  canCancelInstalments?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cardId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventTickets?: Resolver<ResolversTypes['EventTicket'], ParentType, ContextType>;
  failedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  failureDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraiserTicketPayments?: Resolver<Array<ResolversTypes['FundraiserTicketPayment']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  instalmentPayments?: Resolver<Maybe<Array<ResolversTypes['InstalmentPayment']>>, ParentType, ContextType>;
  itemType?: Resolver<ResolversTypes['PaymentItemType'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberships?: Resolver<ResolversTypes['Membership'], ParentType, ContextType>;
  netAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  paidAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  paymentStatusUpdatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  paymentType?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType>;
  payout?: Resolver<Maybe<ResolversTypes['Payout']>, ParentType, ContextType>;
  refundReason?: Resolver<Maybe<ResolversTypes['RefundReason']>, ParentType, ContextType>;
  refundableAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  refunds?: Resolver<Maybe<Array<ResolversTypes['Payment']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PaymentStatus'], ParentType, ContextType>;
  stripeInfoEnteredAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  stripePaymentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripeSessionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentSessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentSession'] = ResolversParentTypes['PaymentSession']> = {
  redirectUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payout'] = ResolversParentTypes['Payout']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  arrivalDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  automatic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  destination?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType>;
  processed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PayoutStatus'], ParentType, ContextType>;
  stripePayoutId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayoutIncomeStreamResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayoutIncomeStream'] = ResolversParentTypes['PayoutIncomeStream']> = {
  grossAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  netAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayoutIncomeStreamsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayoutIncomeStreams'] = ResolversParentTypes['PayoutIncomeStreams']> = {
  event?: Resolver<ResolversTypes['PayoutIncomeStream'], ParentType, ContextType>;
  lotto?: Resolver<ResolversTypes['PayoutIncomeStream'], ParentType, ContextType>;
  membership?: Resolver<ResolversTypes['PayoutIncomeStream'], ParentType, ContextType>;
  raffle?: Resolver<ResolversTypes['PayoutIncomeStream'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['PayoutIncomeStream'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayoutIncomeStreamsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayoutIncomeStreamsResponse'] = ResolversParentTypes['PayoutIncomeStreamsResponse']> = {
  refundFeatureReleaseDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  streams?: Resolver<ResolversTypes['PayoutIncomeStreams'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayoutResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayoutResponse'] = ResolversParentTypes['PayoutResponse']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  arrivalDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  automatic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  destination?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicClubResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicClub'] = ResolversParentTypes['PublicClub']> = {
  colors?: Resolver<ResolversTypes['Colors'], ParentType, ContextType>;
  hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  logoSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  privacyPolicyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['Settings'], ParentType, ContextType>;
  suffix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  termsOfServiceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicClubWithSuffixResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicClubWithSuffix'] = ResolversParentTypes['PublicClubWithSuffix']> = {
  suffix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicEvent'] = ResolversParentTypes['PublicEvent']> = {
  additionalInfoEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  club?: Resolver<ResolversTypes['PublicClubWithSuffix'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coverImageSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['PublicForm']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxTickets?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  ticketSalesStatus?: Resolver<ResolversTypes['EventTicketStatusType'], ParentType, ContextType>;
  ticketTypes?: Resolver<Array<ResolversTypes['PublicEventTicketType']>, ParentType, ContextType>;
  ticketingType?: Resolver<Maybe<ResolversTypes['TicketingType']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicEventTicketTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicEventTicketType'] = ResolversParentTypes['PublicEventTicketType']> = {
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicFormResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicForm'] = ResolversParentTypes['PublicForm']> = {
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  schema?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  uiSchema?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicLottoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicLotto'] = ResolversParentTypes['PublicLotto']> = {
  club?: Resolver<ResolversTypes['PublicClub'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  currentRound?: Resolver<ResolversTypes['PublicLottoRound'], ParentType, ContextType>;
  ended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  jackpotAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfBalls?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberRange?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repeating?: Resolver<ResolversTypes['LottoRepetitionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicLottoRoundResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicLottoRound'] = ResolversParentTypes['PublicLottoRound']> = {
  combination?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  drawDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  drawNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jackpot?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lotto?: Resolver<ResolversTypes['PublicLottoWithoutRounds'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  winners?: Resolver<Maybe<Array<ResolversTypes['WinnerType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicLottoTicketTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicLottoTicketType'] = ResolversParentTypes['PublicLottoTicketType']> = {
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lotto?: Resolver<ResolversTypes['PublicLotto'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfRounds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TicketType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicLottoWithTicketTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicLottoWithTicketTypes'] = ResolversParentTypes['PublicLottoWithTicketTypes']> = {
  club?: Resolver<ResolversTypes['PublicClub'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  currentRound?: Resolver<ResolversTypes['PublicLottoRound'], ParentType, ContextType>;
  ended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  jackpotAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfBalls?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberRange?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repeating?: Resolver<ResolversTypes['LottoRepetitionType'], ParentType, ContextType>;
  ticketTypes?: Resolver<Array<ResolversTypes['PublicLottoTicketType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicLottoWithoutRoundsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicLottoWithoutRounds'] = ResolversParentTypes['PublicLottoWithoutRounds']> = {
  club?: Resolver<ResolversTypes['PublicClub'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  ended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  jackpotAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfBalls?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numberRange?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repeating?: Resolver<ResolversTypes['LottoRepetitionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicPayment'] = ResolversParentTypes['PublicPayment']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  club?: Resolver<ResolversTypes['PublicClub'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  failedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  itemType?: Resolver<ResolversTypes['PaymentItemType'], ParentType, ContextType>;
  paidAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  stripeSessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicRaffleResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicRaffle'] = ResolversParentTypes['PublicRaffle']> = {
  currentRaffleRound?: Resolver<ResolversTypes['PublicRaffleRound'], ParentType, ContextType>;
  ended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prizes?: Resolver<Array<ResolversTypes['RafflePrize']>, ParentType, ContextType>;
  repeating?: Resolver<ResolversTypes['RaffleRepetitionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicRaffleRoundResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicRaffleRound'] = ResolversParentTypes['PublicRaffleRound']> = {
  drawDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  drawNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  raffle?: Resolver<ResolversTypes['PublicRaffleWithoutRounds'], ParentType, ContextType>;
  winners?: Resolver<Maybe<Array<ResolversTypes['RaffleWinner']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicRaffleTicketTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicRaffleTicketType'] = ResolversParentTypes['PublicRaffleTicketType']> = {
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfRounds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  raffle?: Resolver<ResolversTypes['PublicRaffle'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TicketType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicRaffleWithTicketTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicRaffleWithTicketTypes'] = ResolversParentTypes['PublicRaffleWithTicketTypes']> = {
  currentRound?: Resolver<ResolversTypes['PublicRaffleRound'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prizes?: Resolver<Array<ResolversTypes['RafflePrize']>, ParentType, ContextType>;
  repeating?: Resolver<ResolversTypes['RaffleRepetitionType'], ParentType, ContextType>;
  ticketTypes?: Resolver<Array<ResolversTypes['PublicRaffleTicketType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicRaffleWithoutRoundsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicRaffleWithoutRounds'] = ResolversParentTypes['PublicRaffleWithoutRounds']> = {
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prizes?: Resolver<Array<ResolversTypes['RafflePrize']>, ParentType, ContextType>;
  repeating?: Resolver<ResolversTypes['RaffleRepetitionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicUserTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicUserType'] = ResolversParentTypes['PublicUserType']> = {
  birthDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseData'] = ResolversParentTypes['PurchaseData']> = {
  failedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paidAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PaymentItemType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  activeMembershipFormTypes?: Resolver<Array<ResolversTypes['MembershipType']>, ParentType, ContextType, RequireFields<QueryactiveMembershipFormTypesArgs, 'clubId'>>;
  activeMembershipTypes?: Resolver<Array<ResolversTypes['MembershipType']>, ParentType, ContextType, RequireFields<QueryactiveMembershipTypesArgs, 'clubId'>>;
  article?: Resolver<ResolversTypes['Article'], ParentType, ContextType, RequireFields<QueryarticleArgs, 'id'>>;
  articles?: Resolver<ResolversTypes['PaginatedSearchArticleResponse'], ParentType, ContextType, Partial<QueryarticlesArgs>>;
  calculatePrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QuerycalculatePriceArgs, 'cart' | 'membershipTypeId'>>;
  checkStripeConnectAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QuerycheckStripeConnectAccountArgs, 'clubId'>>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType, RequireFields<QueryclubArgs, 'id'>>;
  clubBranding?: Resolver<Maybe<ResolversTypes['Branding']>, ParentType, ContextType, RequireFields<QueryclubBrandingArgs, 'clubId'>>;
  clubBrandingSuffix?: Resolver<Maybe<ResolversTypes['Branding']>, ParentType, ContextType, RequireFields<QueryclubBrandingSuffixArgs, 'suffix'>>;
  countTransactions?: Resolver<ResolversTypes['TransactionsCountResponse'], ParentType, ContextType, RequireFields<QuerycountTransactionsArgs, 'clubId' | 'filter'>>;
  createLoginLink?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QuerycreateLoginLinkArgs, 'clubId'>>;
  createOnboardingLink?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QuerycreateOnboardingLinkArgs, 'clubId'>>;
  createStripeConnectAccount?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QuerycreateStripeConnectAccountArgs, 'clubId'>>;
  event?: Resolver<ResolversTypes['Event'], ParentType, ContextType, RequireFields<QueryeventArgs, 'id'>>;
  events?: Resolver<ResolversTypes['PaginatedSearchEventResponse'], ParentType, ContextType, Partial<QueryeventsArgs>>;
  findEvents?: Resolver<Array<ResolversTypes['FundraiserEvent']>, ParentType, ContextType, RequireFields<QueryfindEventsArgs, 'clubId'>>;
  findLotto?: Resolver<ResolversTypes['Lotto'], ParentType, ContextType, RequireFields<QueryfindLottoArgs, 'lottoId'>>;
  findTicketTypes?: Resolver<Array<ResolversTypes['FundraiserTicketType']>, ParentType, ContextType, RequireFields<QueryfindTicketTypesArgs, 'clubId'>>;
  getActiveFundraisersWithTicketTypes?: Resolver<Array<ResolversTypes['FundraiserWithTicketTypes']>, ParentType, ContextType, RequireFields<QuerygetActiveFundraisersWithTicketTypesArgs, 'clubId'>>;
  getAvailableCountries?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  getBookmarks?: Resolver<Maybe<Array<ResolversTypes['Bookmark']>>, ParentType, ContextType, RequireFields<QuerygetBookmarksArgs, 'clubId'>>;
  getBundledFundraiserData?: Resolver<ResolversTypes['BundledFundraiserData'], ParentType, ContextType, RequireFields<QuerygetBundledFundraiserDataArgs, 'bundleHash'>>;
  getClubForms?: Resolver<Array<ResolversTypes['Form']>, ParentType, ContextType>;
  getClubOwner?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QuerygetClubOwnerArgs, 'clubId'>>;
  getClubUsersWithRole?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QuerygetClubUsersWithRoleArgs, 'clubId'>>;
  getCodeOfConductFile?: Resolver<ResolversTypes['CodeOfConductData'], ParentType, ContextType, RequireFields<QuerygetCodeOfConductFileArgs, 'clubId'>>;
  getDetailsOfMember?: Resolver<ResolversTypes['MembershipMember'], ParentType, ContextType, RequireFields<QuerygetDetailsOfMemberArgs, 'memberId'>>;
  getEventTicketTypes?: Resolver<Array<ResolversTypes['EventTicketType']>, ParentType, ContextType, RequireFields<QuerygetEventTicketTypesArgs, 'eventId'>>;
  getEventWithWinners?: Resolver<ResolversTypes['FundraiserEventWithWinners'], ParentType, ContextType, RequireFields<QuerygetEventWithWinnersArgs, 'eventId'>>;
  getForm?: Resolver<ResolversTypes['Form'], ParentType, ContextType, RequireFields<QuerygetFormArgs, 'id'>>;
  getFundraiserEvent?: Resolver<ResolversTypes['FundraiserEvent'], ParentType, ContextType, RequireFields<QuerygetFundraiserEventArgs, 'eventId'>>;
  getFundraiserNotificationPreviewTemplateData?: Resolver<ResolversTypes['FundraiserNotificationPreviewTemplateData'], ParentType, ContextType, Partial<QuerygetFundraiserNotificationPreviewTemplateDataArgs>>;
  getFundraisersWithOpenEvent?: Resolver<Array<ResolversTypes['Fundraiser']>, ParentType, ContextType, RequireFields<QuerygetFundraisersWithOpenEventArgs, 'clubId'>>;
  getGroupMembershipMembers?: Resolver<Array<ResolversTypes['MembershipMember']>, ParentType, ContextType, RequireFields<QuerygetGroupMembershipMembersArgs, 'membershipId'>>;
  getInstalmentPlan?: Resolver<Maybe<ResolversTypes['InstalmentPlan']>, ParentType, ContextType, RequireFields<QuerygetInstalmentPlanArgs, 'cart'>>;
  getLastUnregisteredMembersNotificationDate?: Resolver<ResolversTypes['LastUnregisteredMembersNotificationDate'], ParentType, ContextType, RequireFields<QuerygetLastUnregisteredMembersNotificationDateArgs, 'clubId'>>;
  getMember?: Resolver<ResolversTypes['MembershipMember'], ParentType, ContextType, RequireFields<QuerygetMemberArgs, 'memberId'>>;
  getMemberDataFromFile?: Resolver<ResolversTypes['FormDataFromFileResponse'], ParentType, ContextType, RequireFields<QuerygetMemberDataFromFileArgs, 'file' | 'membershipTypeId'>>;
  getMemberFormDataDuplications?: Resolver<ResolversTypes['MemberFormDataDuplicationsResponse'], ParentType, ContextType, RequireFields<QuerygetMemberFormDataDuplicationsArgs, 'members'>>;
  getMembershipsFormFundraiserTicketsOfUser?: Resolver<Array<ResolversTypes['Membership']>, ParentType, ContextType, RequireFields<QuerygetMembershipsFormFundraiserTicketsOfUserArgs, 'clubId' | 'userId'>>;
  getMembershipsFundraiserTicketsOfUser?: Resolver<Array<ResolversTypes['Membership']>, ParentType, ContextType, RequireFields<QuerygetMembershipsFundraiserTicketsOfUserArgs, 'clubId' | 'userId'>>;
  getMessage?: Resolver<ResolversTypes['GroupMessage'], ParentType, ContextType, RequireFields<QuerygetMessageArgs, 'groupId' | 'messageId'>>;
  getNextEvent?: Resolver<Maybe<ResolversTypes['FundraiserEvent']>, ParentType, ContextType, RequireFields<QuerygetNextEventArgs, 'eventId'>>;
  getNotificationBadges?: Resolver<Array<ResolversTypes['Badge']>, ParentType, ContextType>;
  getPayoutIncomeStreams?: Resolver<ResolversTypes['PayoutIncomeStreamsResponse'], ParentType, ContextType, RequireFields<QuerygetPayoutIncomeStreamsArgs, 'clubId' | 'payoutId'>>;
  getPurchaseData?: Resolver<ResolversTypes['PurchaseData'], ParentType, ContextType, RequireFields<QuerygetPurchaseDataArgs, 'clubId' | 'sessionId'>>;
  getRaffle?: Resolver<ResolversTypes['Raffle'], ParentType, ContextType, RequireFields<QuerygetRaffleArgs, 'raffleId'>>;
  getRaffleTicketTypePublic?: Resolver<ResolversTypes['PublicRaffleTicketType'], ParentType, ContextType, RequireFields<QuerygetRaffleTicketTypePublicArgs, 'id'>>;
  getRoundToFinish?: Resolver<ResolversTypes['RoundToFinish'], ParentType, ContextType, RequireFields<QuerygetRoundToFinishArgs, 'combinationInput' | 'roundId'>>;
  getSavedCards?: Resolver<Array<ResolversTypes['CustomerCard']>, ParentType, ContextType>;
  getSelectableForms?: Resolver<Array<ResolversTypes['Form']>, ParentType, ContextType>;
  getSmSSenderId?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QuerygetSmSSenderIdArgs, 'clubId'>>;
  getSponsorshipData?: Resolver<Maybe<ResolversTypes['Sponsorship']>, ParentType, ContextType, RequireFields<QuerygetSponsorshipDataArgs, 'clubId'>>;
  getSponsorshipsData?: Resolver<Maybe<Array<ResolversTypes['Sponsorship']>>, ParentType, ContextType, RequireFields<QuerygetSponsorshipsDataArgs, 'clubId'>>;
  getSponsorshipsDataAdmin?: Resolver<Maybe<Array<ResolversTypes['Sponsorship']>>, ParentType, ContextType, RequireFields<QuerygetSponsorshipsDataAdminArgs, 'clubId'>>;
  getStripeAccountDetails?: Resolver<ResolversTypes['StripeAccount'], ParentType, ContextType, RequireFields<QuerygetStripeAccountDetailsArgs, 'clubId'>>;
  getSystemForms?: Resolver<Array<ResolversTypes['Form']>, ParentType, ContextType>;
  getTermsAndConditionsFiles?: Resolver<ResolversTypes['TermsAndConditions'], ParentType, ContextType, RequireFields<QuerygetTermsAndConditionsFilesArgs, 'clubId'>>;
  getTicketType?: Resolver<ResolversTypes['LottoTicketType'], ParentType, ContextType, RequireFields<QuerygetTicketTypeArgs, 'id'>>;
  getTicketTypePublic?: Resolver<ResolversTypes['TicketTypePublic'], ParentType, ContextType, RequireFields<QuerygetTicketTypePublicArgs, 'id'>>;
  getTicketsSold?: Resolver<ResolversTypes['PaginatedSearchFundraiserTicketDetailsResponse'], ParentType, ContextType, RequireFields<QuerygetTicketsSoldArgs, 'clubId' | 'filters' | 'pagination'>>;
  getTransactionAutoCompleteOptions?: Resolver<Array<ResolversTypes['AutocompleteItem']>, ParentType, ContextType, RequireFields<QuerygetTransactionAutoCompleteOptionsArgs, 'clubId' | 'searchTerm'>>;
  getUserAutoCompleteOptions?: Resolver<Array<ResolversTypes['UserAutocompleteItem']>, ParentType, ContextType, RequireFields<QuerygetUserAutoCompleteOptionsArgs, 'clubId' | 'searchTerm'>>;
  group?: Resolver<ResolversTypes['Group'], ParentType, ContextType, RequireFields<QuerygroupArgs, 'id'>>;
  isDefaultCardRemovable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLinkExpired?: Resolver<ResolversTypes['LinkStatus'], ParentType, ContextType, RequireFields<QueryisLinkExpiredArgs, 'clubId' | 'sessionId'>>;
  listActiveUnfinishedFundraisers?: Resolver<Array<ResolversTypes['Fundraiser']>, ParentType, ContextType, RequireFields<QuerylistActiveUnfinishedFundraisersArgs, 'clubId'>>;
  listBuilds?: Resolver<ResolversTypes['ListMobileBuildStatusWithStats'], ParentType, ContextType, Partial<QuerylistBuildsArgs>>;
  listClosedFundraiserEvents?: Resolver<ResolversTypes['PaginatedSearchFundraiserEventResponse'], ParentType, ContextType, RequireFields<QuerylistClosedFundraiserEventsArgs, 'clubId' | 'input'>>;
  listClubs?: Resolver<ResolversTypes['PaginatedSearchClubResponse'], ParentType, ContextType, RequireFields<QuerylistClubsArgs, 'pagination'>>;
  listContactDetails?: Resolver<ResolversTypes['PaginatedSearchContactDetailsBlacklistedResponse'], ParentType, ContextType, RequireFields<QuerylistContactDetailsArgs, 'clubId' | 'inputs'>>;
  listFundraiserClosedRounds?: Resolver<ResolversTypes['PaginatedSearchFundraiserRoundsResponse'], ParentType, ContextType, RequireFields<QuerylistFundraiserClosedRoundsArgs, 'clubId' | 'input'>>;
  listFundraiserEventStatistics?: Resolver<ResolversTypes['PaginatedSearchFundraiserEventStatisticDetailsResponse'], ParentType, ContextType, RequireFields<QuerylistFundraiserEventStatisticsArgs, 'clubId' | 'filters' | 'pagination'>>;
  listGroups?: Resolver<ResolversTypes['PaginatedSearchGroupResponse'], ParentType, ContextType, RequireFields<QuerylistGroupsArgs, 'clubId' | 'pagination'>>;
  listMessages?: Resolver<ResolversTypes['PaginatedSearchGroupMessageResponse'], ParentType, ContextType, RequireFields<QuerylistMessagesArgs, 'groupId' | 'pagination'>>;
  listPayouts?: Resolver<ResolversTypes['PaginatedSearchPayoutResponseResponse'], ParentType, ContextType, RequireFields<QuerylistPayoutsArgs, 'clubId' | 'filter' | 'input'>>;
  listTransactions?: Resolver<ResolversTypes['PaginatedSearchPaymentResponse'], ParentType, ContextType, RequireFields<QuerylistTransactionsArgs, 'clubId' | 'filter' | 'input'>>;
  listUserEventTickets?: Resolver<Array<ResolversTypes['EventTicket']>, ParentType, ContextType, RequireFields<QuerylistUserEventTicketsArgs, 'eventId'>>;
  listUserFundraiserTickets?: Resolver<ResolversTypes['PaginatedSearchFundraiserTicketsResponse'], ParentType, ContextType, RequireFields<QuerylistUserFundraiserTicketsArgs, 'clubId' | 'input'>>;
  listUsers?: Resolver<ResolversTypes['PaginatedSearchPublicUserTypeResponse'], ParentType, ContextType, Partial<QuerylistUsersArgs>>;
  matchThreeOnlineWinnerTickets?: Resolver<Array<ResolversTypes['FundraiserTicketDetails']>, ParentType, ContextType, RequireFields<QuerymatchThreeOnlineWinnerTicketsArgs, 'clubId' | 'combinationInput' | 'eventId' | 'numberOfWinners'>>;
  members?: Resolver<ResolversTypes['PaginatedSearchUserDetailsResponse'], ParentType, ContextType, Partial<QuerymembersArgs>>;
  membershipMembers?: Resolver<ResolversTypes['PaginatedSearchMembershipListResponse'], ParentType, ContextType, Partial<QuerymembershipMembersArgs>>;
  membershipType?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType, RequireFields<QuerymembershipTypeArgs, 'membershipTypeId'>>;
  membershipTypes?: Resolver<ResolversTypes['PaginatedSearchExtendedMembershipTypeResponse'], ParentType, ContextType, RequireFields<QuerymembershipTypesArgs, 'clubId'>>;
  myGroups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>;
  notHiddenClubs?: Resolver<Array<ResolversTypes['PublicClub']>, ParentType, ContextType>;
  paymentStatus?: Resolver<ResolversTypes['PublicPayment'], ParentType, ContextType, RequireFields<QuerypaymentStatusArgs, 'clubId' | 'stripeSessionId'>>;
  publishedArticle?: Resolver<ResolversTypes['Article'], ParentType, ContextType, RequireFields<QuerypublishedArticleArgs, 'id'>>;
  publishedArticles?: Resolver<ResolversTypes['PaginatedSearchArticleResponse'], ParentType, ContextType, Partial<QuerypublishedArticlesArgs>>;
  publishedEvent?: Resolver<ResolversTypes['Event'], ParentType, ContextType, RequireFields<QuerypublishedEventArgs, 'id'>>;
  publishedEventPublic?: Resolver<ResolversTypes['PublicEvent'], ParentType, ContextType, RequireFields<QuerypublishedEventPublicArgs, 'id'>>;
  publishedEvents?: Resolver<ResolversTypes['PaginatedSearchEventResponse'], ParentType, ContextType, Partial<QuerypublishedEventsArgs>>;
  publishedEventsPublic?: Resolver<ResolversTypes['PaginatedSearchPublicEventResponse'], ParentType, ContextType, RequireFields<QuerypublishedEventsPublicArgs, 'clubId'>>;
  raffleTicketInfo?: Resolver<ResolversTypes['RaffleTicketInfo'], ParentType, ContextType, RequireFields<QueryraffleTicketInfoArgs, 'eventId' | 'ticketNumber'>>;
  randomOnlineWinnerTickets?: Resolver<Array<ResolversTypes['FundraiserTicketDetails']>, ParentType, ContextType, RequireFields<QueryrandomOnlineWinnerTicketsArgs, 'clubId' | 'eventId' | 'matchThreeWinnerTicketIds' | 'numberOfWinners'>>;
  randomRaffleWinner?: Resolver<Array<ResolversTypes['GeneratedRaffleWinner']>, ParentType, ContextType, RequireFields<QueryrandomRaffleWinnerArgs, 'eventId'>>;
  transactionDetails?: Resolver<ResolversTypes['TransactionDetailsResponse'], ParentType, ContextType, RequireFields<QuerytransactionDetailsArgs, 'clubId' | 'paymentId'>>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryuserArgs, 'id'>>;
  users?: Resolver<Maybe<Array<ResolversTypes['UserDetails']>>, ParentType, ContextType>;
  validMembers?: Resolver<Array<ResolversTypes['MembershipMember']>, ParentType, ContextType, RequireFields<QueryvalidMembersArgs, 'filter'>>;
};

export type RaffleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Raffle'] = ResolversParentTypes['Raffle']> = {
  activeTicketTypes?: Resolver<Array<ResolversTypes['RaffleTicketType']>, ParentType, ContextType>;
  additionalInfoEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  demo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['Form']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notifications?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType>;
  prizes?: Resolver<Array<ResolversTypes['RafflePrize']>, ParentType, ContextType>;
  repeating?: Resolver<ResolversTypes['RaffleRepetitionType'], ParentType, ContextType>;
  rounds?: Resolver<Array<ResolversTypes['RaffleRound']>, ParentType, ContextType>;
  ticketStartNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketTypes?: Resolver<Array<ResolversTypes['RaffleTicketType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RaffleParticipantResolvers<ContextType = any, ParentType extends ResolversParentTypes['RaffleParticipant'] = ResolversParentTypes['RaffleParticipant']> = {
  additionalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  additionalInfoRaw?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticketNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RafflePrizeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RafflePrize'] = ResolversParentTypes['RafflePrize']> = {
  lineNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  prizeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RaffleRoundResolvers<ContextType = any, ParentType extends ResolversParentTypes['RaffleRound'] = ResolversParentTypes['RaffleRound']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  drawDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  drawNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  raffle?: Resolver<ResolversTypes['Raffle'], ParentType, ContextType>;
  tickets?: Resolver<Array<ResolversTypes['RaffleTicket']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  winners?: Resolver<Maybe<Array<ResolversTypes['RaffleWinner']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RaffleSalesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RaffleSales'] = ResolversParentTypes['RaffleSales']> = {
  raffleid?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  roundid?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RaffleTicketResolvers<ContextType = any, ParentType extends ResolversParentTypes['RaffleTicket'] = ResolversParentTypes['RaffleTicket']> = {
  boughtFrom?: Resolver<ResolversTypes['TicketBoughtFrom'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remainingDraws?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  round?: Resolver<ResolversTypes['RaffleRound'], ParentType, ContextType>;
  ticketNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketType?: Resolver<ResolversTypes['RaffleTicketType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RaffleTicketInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['RaffleTicketInfo'] = ResolversParentTypes['RaffleTicketInfo']> = {
  boughtfrom?: Resolver<Maybe<ResolversTypes['TicketBoughtFrom']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paidat?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ticketNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RaffleTicketTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RaffleTicketType'] = ResolversParentTypes['RaffleTicketType']> = {
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfRounds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  recurringTickets?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tickets?: Resolver<ResolversTypes['RaffleTicket'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TicketType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RaffleWinnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['RaffleWinner'] = ResolversParentTypes['RaffleWinner']> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prize?: Resolver<ResolversTypes['RafflePrize'], ParentType, ContextType>;
  ticketNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringPurchaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringPurchase'] = ResolversParentTypes['RecurringPurchase']> = {
  card?: Resolver<ResolversTypes['CustomerCard'], ParentType, ContextType>;
  chargeRequestedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event?: Resolver<ResolversTypes['FundraiserEvent'], ParentType, ContextType>;
  failedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  paymentIntentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  succeededAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ticket?: Resolver<ResolversTypes['FundraiserTicket'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RedirectResolvers<ContextType = any, ParentType extends ResolversParentTypes['Redirect'] = ResolversParentTypes['Redirect']> = {
  additionalInfo?: Resolver<Maybe<ResolversTypes['AdditionalInfo']>, ParentType, ContextType>;
  redirectUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Response'] = ResolversParentTypes['Response']> = {
  message?: Resolver<ResolversTypes['GroupMessage'], ParentType, ContextType>;
  response?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['PublicUserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundToFinishResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundToFinish'] = ResolversParentTypes['RoundToFinish']> = {
  event?: Resolver<ResolversTypes['FundraiserEvent'], ParentType, ContextType>;
  matchThreeTicketsAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  soldTicketsAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  winnerTickets?: Resolver<Array<ResolversTypes['WinnerTicketWithCombination']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesData'] = ResolversParentTypes['SalesData']> = {
  numberSold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sumValues?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  timeRange?: Resolver<ResolversTypes['TimeRange'], ParentType, ContextType>;
  valueSold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Schedule'] = ResolversParentTypes['Schedule']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  article?: Resolver<ResolversTypes['Article'], ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  event?: Resolver<ResolversTypes['Event'], ParentType, ContextType>;
  firedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  itemType?: Resolver<ResolversTypes['ScheduleItemType'], ParentType, ContextType>;
  membershipType?: Resolver<ResolversTypes['MembershipType'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['GroupMessage'], ParentType, ContextType>;
  notificationType?: Resolver<Maybe<ResolversTypes['ScheduleNotificationType']>, ParentType, ContextType>;
  scheduleDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ScheduleType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendUnregisteredMembersNotificationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendUnregisteredMembersNotificationResponse'] = ResolversParentTypes['SendUnregisteredMembersNotificationResponse']> = {
  lastUnregisteredMembersNotificationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  notifiedUsersCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Settings'] = ResolversParentTypes['Settings']> = {
  features?: Resolver<ResolversTypes['Features'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SmsMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['SmsMessage'] = ResolversParentTypes['SmsMessage']> = {
  club?: Resolver<Maybe<ResolversTypes['Club']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationInstance?: Resolver<Maybe<ResolversTypes['NotificationInstance']>, ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sendOn?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  sentOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twilioMessageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twilioNotificationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialMediaAuthResolvers<ContextType = any, ParentType extends ResolversParentTypes['SocialMediaAuth'] = ResolversParentTypes['SocialMediaAuth']> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facebookUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagramUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pageAccessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialMediaConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SocialMediaConnection'] = ResolversParentTypes['SocialMediaConnection']> = {
  auth?: Resolver<Maybe<ResolversTypes['SocialMediaAuth']>, ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastUsedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['SocialMediaPlatform'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialMediaShareArticleSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SocialMediaShareArticleSettings'] = ResolversParentTypes['SocialMediaShareArticleSettings']> = {
  facebook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialMediaShareSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SocialMediaShareSettings'] = ResolversParentTypes['SocialMediaShareSettings']> = {
  facebook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SponsorshipResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sponsorship'] = ResolversParentTypes['Sponsorship']> = {
  bannerImageSource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bannerUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  club?: Resolver<ResolversTypes['Club'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  visible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeAccount'] = ResolversParentTypes['StripeAccount']> = {
  availableBalance?: Resolver<ResolversTypes['StripeAvailableBalance'], ParentType, ContextType>;
  currentlyDue?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  detailsSubmitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  disabledReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  externalAccounts?: Resolver<Array<ResolversTypes['StripeExternalAccount']>, ParentType, ContextType>;
  isPayoutManual?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  payoutsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pendingVerification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeAvailableBalanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeAvailableBalance'] = ResolversParentTypes['StripeAvailableBalance']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeConfig'] = ResolversParentTypes['StripeConfig']> = {
  clubs?: Resolver<Array<ResolversTypes['Club']>, ParentType, ContextType>;
  config?: Resolver<ResolversTypes['StripeConfigField'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['Country'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  stripeFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  stripeFeeVAT?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  stripeWebhookIdentifier?: Resolver<ResolversTypes['StripeWebhookIdentifier'], ParentType, ContextType>;
  useForClubCreation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeConfigFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeConfigField'] = ResolversParentTypes['StripeConfigField']> = {
  publicKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  secretKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  webhookSignature?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeExternalAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeExternalAccount'] = ResolversParentTypes['StripeExternalAccount']> = {
  bankName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  defaultForCurrency?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SummarizedResponsesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SummarizedResponses'] = ResolversParentTypes['SummarizedResponses']> = {
  awaiting?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  no?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  yes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['Team'] = ResolversParentTypes['Team']> = {
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TermsAndConditionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TermsAndConditions'] = ResolversParentTypes['TermsAndConditions']> = {
  apple?: Resolver<ResolversTypes['TermsAndConditionsData'], ParentType, ContextType>;
  google?: Resolver<ResolversTypes['TermsAndConditionsData'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TermsAndConditionsDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['TermsAndConditionsData'] = ResolversParentTypes['TermsAndConditionsData']> = {
  fileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketTypePublicResolvers<ContextType = any, ParentType extends ResolversParentTypes['TicketTypePublic'] = ResolversParentTypes['TicketTypePublic']> = {
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fundraiser?: Resolver<ResolversTypes['Fundraiser'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfRounds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketWithLineItemsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TicketWithLineItems'] = ResolversParentTypes['TicketWithLineItems']> = {
  ticketId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketLineId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketLineItems?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  ticketTypeId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeMetadataPublicResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeMetadataPublic'] = ResolversParentTypes['TimeMetadataPublic']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionDetailsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionDetailsResponse'] = ResolversParentTypes['TransactionDetailsResponse']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventTickets?: Resolver<Array<ResolversTypes['EventTicketTransactionDetails']>, ParentType, ContextType>;
  failureDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraiserTickets?: Resolver<Array<ResolversTypes['FundraiserTicketTransactionDetails']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberships?: Resolver<Array<ResolversTypes['MembershipTransactionDetails']>, ParentType, ContextType>;
  netAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ordinalNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  originalTransaction?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  paymentItemType?: Resolver<ResolversTypes['PaymentItemType'], ParentType, ContextType>;
  paymentStatusUpdatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  paymentType?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType>;
  refundReason?: Resolver<Maybe<ResolversTypes['RefundReason']>, ParentType, ContextType>;
  refundableAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  refunds?: Resolver<Maybe<Array<ResolversTypes['Payment']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PaymentStatus'], ParentType, ContextType>;
  userName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsCountResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionsCountResponse'] = ResolversParentTypes['TransactionsCountResponse']> = {
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TwilioConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['TwilioConfig'] = ResolversParentTypes['TwilioConfig']> = {
  accountSID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  appUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  cancelledMemberships?: Resolver<Array<ResolversTypes['Membership']>, ParentType, ContextType>;
  cards?: Resolver<Array<ResolversTypes['CustomerCard']>, ParentType, ContextType>;
  club?: Resolver<Maybe<ResolversTypes['Club']>, ParentType, ContextType>;
  deviceTokens?: Resolver<Array<ResolversTypes['DeviceId']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventTickets?: Resolver<Array<ResolversTypes['EventTicket']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fundraiserTickets?: Resolver<Array<ResolversTypes['FundraiserTicket']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  groups?: Resolver<Array<ResolversTypes['GroupMember']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberships?: Resolver<Array<ResolversTypes['Membership']>, ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  responses?: Resolver<Array<ResolversTypes['Response']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAutocompleteItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAutocompleteItem'] = ResolversParentTypes['UserAutocompleteItem']> = {
  birthDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserContributionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserContribution'] = ResolversParentTypes['UserContribution']> = {
  contribution?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDetails'] = ResolversParentTypes['UserDetails']> = {
  appUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  clubId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  contribution?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WinnerTicketWithCombinationResolvers<ContextType = any, ParentType extends ResolversParentTypes['WinnerTicketWithCombination'] = ResolversParentTypes['WinnerTicketWithCombination']> = {
  combination?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ticketLineId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WinnerTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WinnerType'] = ResolversParentTypes['WinnerType']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prize?: Resolver<Maybe<ResolversTypes['RafflePrize']>, ParentType, ContextType>;
  ticketNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AdditionalInfo?: AdditionalInfoResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  AppConfig?: AppConfigResolvers<ContextType>;
  Article?: ArticleResolvers<ContextType>;
  ArticleView?: ArticleViewResolvers<ContextType>;
  AttendeeList?: AttendeeListResolvers<ContextType>;
  AudienceOptions?: AudienceOptionsResolvers<ContextType>;
  AutocompleteItem?: AutocompleteItemResolvers<ContextType>;
  Badge?: BadgeResolvers<ContextType>;
  BlacklistedContactData?: BlacklistedContactDataResolvers<ContextType>;
  Bookmark?: BookmarkResolvers<ContextType>;
  Branding?: BrandingResolvers<ContextType>;
  BundledFundraiser?: BundledFundraiserResolvers<ContextType>;
  BundledFundraiserData?: BundledFundraiserDataResolvers<ContextType>;
  Club?: ClubResolvers<ContextType>;
  ClubSalesData?: ClubSalesDataResolvers<ContextType>;
  CodeOfConductData?: CodeOfConductDataResolvers<ContextType>;
  Colors?: ColorsResolvers<ContextType>;
  ContactDetails?: ContactDetailsResolvers<ContextType>;
  ContactDetailsBlacklisted?: ContactDetailsBlacklistedResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  CurrentFundraiserRoundSales?: CurrentFundraiserRoundSalesResolvers<ContextType>;
  CustomerCard?: CustomerCardResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeviceId?: DeviceIdResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  EventTicket?: EventTicketResolvers<ContextType>;
  EventTicketTransactionDetails?: EventTicketTransactionDetailsResolvers<ContextType>;
  EventTicketType?: EventTicketTypeResolvers<ContextType>;
  ExtendedMembershipType?: ExtendedMembershipTypeResolvers<ContextType>;
  Features?: FeaturesResolvers<ContextType>;
  FeaturesWithClosedFundraiser?: FeaturesWithClosedFundraiserResolvers<ContextType>;
  FileUploadRequestResponse?: FileUploadRequestResponseResolvers<ContextType>;
  FileUploadRequestResponseField?: FileUploadRequestResponseFieldResolvers<ContextType>;
  FirstNameLength?: FirstNameLengthResolvers<ContextType>;
  Form?: FormResolvers<ContextType>;
  FormData?: FormDataResolvers<ContextType>;
  FormDataDataArray?: FormDataDataArrayResolvers<ContextType>;
  FormDataFromFileResponse?: FormDataFromFileResponseResolvers<ContextType>;
  Fundraiser?: FundraiserResolvers<ContextType>;
  FundraiserBundleConfirmationResult?: FundraiserBundleConfirmationResultResolvers<ContextType>;
  FundraiserCategory?: FundraiserCategoryResolvers<ContextType>;
  FundraiserDescription?: FundraiserDescriptionResolvers<ContextType>;
  FundraiserEvent?: FundraiserEventResolvers<ContextType>;
  FundraiserEventResult?: FundraiserEventResultResolvers<ContextType>;
  FundraiserEventResultTicketLine?: FundraiserEventResultTicketLineResolvers<ContextType>;
  FundraiserEventStatisticDetails?: FundraiserEventStatisticDetailsResolvers<ContextType>;
  FundraiserEventStatistics?: FundraiserEventStatisticsResolvers<ContextType>;
  FundraiserEventTicket?: FundraiserEventTicketResolvers<ContextType>;
  FundraiserEventTicketPayment?: FundraiserEventTicketPaymentResolvers<ContextType>;
  FundraiserEventWithWinners?: FundraiserEventWithWinnersResolvers<ContextType>;
  FundraiserNotificationPreviewFields?: FundraiserNotificationPreviewFieldsResolvers<ContextType>;
  FundraiserNotificationPreviewTemplateData?: FundraiserNotificationPreviewTemplateDataResolvers<ContextType>;
  FundraiserParticipantTicket?: FundraiserParticipantTicketResolvers<ContextType>;
  FundraiserRounds?: FundraiserRoundsResolvers<ContextType>;
  FundraiserTicket?: FundraiserTicketResolvers<ContextType>;
  FundraiserTicketDetails?: FundraiserTicketDetailsResolvers<ContextType>;
  FundraiserTicketLine?: FundraiserTicketLineResolvers<ContextType>;
  FundraiserTicketLineItem?: FundraiserTicketLineItemResolvers<ContextType>;
  FundraiserTicketPayment?: FundraiserTicketPaymentResolvers<ContextType>;
  FundraiserTicketTransactionDetails?: FundraiserTicketTransactionDetailsResolvers<ContextType>;
  FundraiserTicketType?: FundraiserTicketTypeResolvers<ContextType>;
  FundraiserTickets?: FundraiserTicketsResolvers<ContextType>;
  FundraiserWinner?: FundraiserWinnerResolvers<ContextType>;
  FundraiserWinners?: FundraiserWinnersResolvers<ContextType>;
  FundraiserWithTicketTypes?: FundraiserWithTicketTypesResolvers<ContextType>;
  GeneratedRaffleWinner?: GeneratedRaffleWinnerResolvers<ContextType>;
  Gradient?: GradientResolvers<ContextType>;
  GrantRole?: GrantRoleResolvers<ContextType>;
  GrantRoleError?: GrantRoleErrorResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  GroupMember?: GroupMemberResolvers<ContextType>;
  GroupMembershipDescription?: GroupMembershipDescriptionResolvers<ContextType>;
  GroupMessage?: GroupMessageResolvers<ContextType>;
  Instalment?: InstalmentResolvers<ContextType>;
  InstalmentPayment?: InstalmentPaymentResolvers<ContextType>;
  InstalmentPaymentStatus?: InstalmentPaymentStatusResolvers<ContextType>;
  InstalmentPlan?: InstalmentPlanResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  LastUnregisteredMembersNotificationDate?: LastUnregisteredMembersNotificationDateResolvers<ContextType>;
  LinkStatus?: LinkStatusResolvers<ContextType>;
  ListMobileBuildStatusWithStats?: ListMobileBuildStatusWithStatsResolvers<ContextType>;
  Lotto?: LottoResolvers<ContextType>;
  LottoParticipant?: LottoParticipantResolvers<ContextType>;
  LottoRound?: LottoRoundResolvers<ContextType>;
  LottoSales?: LottoSalesResolvers<ContextType>;
  LottoTicket?: LottoTicketResolvers<ContextType>;
  LottoTicketType?: LottoTicketTypeResolvers<ContextType>;
  MemberFormDataDuplicationsResponse?: MemberFormDataDuplicationsResponseResolvers<ContextType>;
  MemberWithResponse?: MemberWithResponseResolvers<ContextType>;
  Membership?: MembershipResolvers<ContextType>;
  MembershipFundraiserTicket?: MembershipFundraiserTicketResolvers<ContextType>;
  MembershipGroupSubtype?: MembershipGroupSubtypeResolvers<ContextType>;
  MembershipInstalment?: MembershipInstalmentResolvers<ContextType>;
  MembershipInstalmentDetails?: MembershipInstalmentDetailsResolvers<ContextType>;
  MembershipList?: MembershipListResolvers<ContextType>;
  MembershipMember?: MembershipMemberResolvers<ContextType>;
  MembershipMemberData?: MembershipMemberDataResolvers<ContextType>;
  MembershipMemberDetailed?: MembershipMemberDetailedResolvers<ContextType>;
  MembershipMemberImportDuplications?: MembershipMemberImportDuplicationsResolvers<ContextType>;
  MembershipRenewInDue?: MembershipRenewInDueResolvers<ContextType>;
  MembershipTransactionDetails?: MembershipTransactionDetailsResolvers<ContextType>;
  MembershipType?: MembershipTypeResolvers<ContextType>;
  MembershipTypeFundraiserTicketType?: MembershipTypeFundraiserTicketTypeResolvers<ContextType>;
  MobileBuild?: MobileBuildResolvers<ContextType>;
  MobileBuildStatus?: MobileBuildStatusResolvers<ContextType>;
  MobileBuilds?: MobileBuildsResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationBlacklist?: NotificationBlacklistResolvers<ContextType>;
  NotificationContent?: NotificationContentResolvers<ContextType>;
  NotificationInstance?: NotificationInstanceResolvers<ContextType>;
  NotificationTiming?: NotificationTimingResolvers<ContextType>;
  NotificationTrigger?: NotificationTriggerResolvers<ContextType>;
  PaginatedSearchArticleResponse?: PaginatedSearchArticleResponseResolvers<ContextType>;
  PaginatedSearchClubResponse?: PaginatedSearchClubResponseResolvers<ContextType>;
  PaginatedSearchContactDetailsBlacklistedResponse?: PaginatedSearchContactDetailsBlacklistedResponseResolvers<ContextType>;
  PaginatedSearchEventResponse?: PaginatedSearchEventResponseResolvers<ContextType>;
  PaginatedSearchExtendedMembershipTypeResponse?: PaginatedSearchExtendedMembershipTypeResponseResolvers<ContextType>;
  PaginatedSearchFundraiserEventResponse?: PaginatedSearchFundraiserEventResponseResolvers<ContextType>;
  PaginatedSearchFundraiserEventStatisticDetailsResponse?: PaginatedSearchFundraiserEventStatisticDetailsResponseResolvers<ContextType>;
  PaginatedSearchFundraiserRoundsResponse?: PaginatedSearchFundraiserRoundsResponseResolvers<ContextType>;
  PaginatedSearchFundraiserTicketDetailsResponse?: PaginatedSearchFundraiserTicketDetailsResponseResolvers<ContextType>;
  PaginatedSearchFundraiserTicketsResponse?: PaginatedSearchFundraiserTicketsResponseResolvers<ContextType>;
  PaginatedSearchGroupMessageResponse?: PaginatedSearchGroupMessageResponseResolvers<ContextType>;
  PaginatedSearchGroupResponse?: PaginatedSearchGroupResponseResolvers<ContextType>;
  PaginatedSearchMembershipListResponse?: PaginatedSearchMembershipListResponseResolvers<ContextType>;
  PaginatedSearchMobileBuildStatusResponse?: PaginatedSearchMobileBuildStatusResponseResolvers<ContextType>;
  PaginatedSearchPaymentResponse?: PaginatedSearchPaymentResponseResolvers<ContextType>;
  PaginatedSearchPayoutResponseResponse?: PaginatedSearchPayoutResponseResponseResolvers<ContextType>;
  PaginatedSearchPublicEventResponse?: PaginatedSearchPublicEventResponseResolvers<ContextType>;
  PaginatedSearchPublicUserTypeResponse?: PaginatedSearchPublicUserTypeResponseResolvers<ContextType>;
  PaginatedSearchUserDetailsResponse?: PaginatedSearchUserDetailsResponseResolvers<ContextType>;
  PaginationNext?: PaginationNextResolvers<ContextType>;
  Payment?: PaymentResolvers<ContextType>;
  PaymentSession?: PaymentSessionResolvers<ContextType>;
  Payout?: PayoutResolvers<ContextType>;
  PayoutIncomeStream?: PayoutIncomeStreamResolvers<ContextType>;
  PayoutIncomeStreams?: PayoutIncomeStreamsResolvers<ContextType>;
  PayoutIncomeStreamsResponse?: PayoutIncomeStreamsResponseResolvers<ContextType>;
  PayoutResponse?: PayoutResponseResolvers<ContextType>;
  PublicClub?: PublicClubResolvers<ContextType>;
  PublicClubWithSuffix?: PublicClubWithSuffixResolvers<ContextType>;
  PublicEvent?: PublicEventResolvers<ContextType>;
  PublicEventTicketType?: PublicEventTicketTypeResolvers<ContextType>;
  PublicForm?: PublicFormResolvers<ContextType>;
  PublicLotto?: PublicLottoResolvers<ContextType>;
  PublicLottoRound?: PublicLottoRoundResolvers<ContextType>;
  PublicLottoTicketType?: PublicLottoTicketTypeResolvers<ContextType>;
  PublicLottoWithTicketTypes?: PublicLottoWithTicketTypesResolvers<ContextType>;
  PublicLottoWithoutRounds?: PublicLottoWithoutRoundsResolvers<ContextType>;
  PublicPayment?: PublicPaymentResolvers<ContextType>;
  PublicRaffle?: PublicRaffleResolvers<ContextType>;
  PublicRaffleRound?: PublicRaffleRoundResolvers<ContextType>;
  PublicRaffleTicketType?: PublicRaffleTicketTypeResolvers<ContextType>;
  PublicRaffleWithTicketTypes?: PublicRaffleWithTicketTypesResolvers<ContextType>;
  PublicRaffleWithoutRounds?: PublicRaffleWithoutRoundsResolvers<ContextType>;
  PublicUserType?: PublicUserTypeResolvers<ContextType>;
  PurchaseData?: PurchaseDataResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Raffle?: RaffleResolvers<ContextType>;
  RaffleParticipant?: RaffleParticipantResolvers<ContextType>;
  RafflePrize?: RafflePrizeResolvers<ContextType>;
  RaffleRound?: RaffleRoundResolvers<ContextType>;
  RaffleSales?: RaffleSalesResolvers<ContextType>;
  RaffleTicket?: RaffleTicketResolvers<ContextType>;
  RaffleTicketInfo?: RaffleTicketInfoResolvers<ContextType>;
  RaffleTicketType?: RaffleTicketTypeResolvers<ContextType>;
  RaffleWinner?: RaffleWinnerResolvers<ContextType>;
  RecurringPurchase?: RecurringPurchaseResolvers<ContextType>;
  Redirect?: RedirectResolvers<ContextType>;
  Response?: ResponseResolvers<ContextType>;
  RoundToFinish?: RoundToFinishResolvers<ContextType>;
  SalesData?: SalesDataResolvers<ContextType>;
  Schedule?: ScheduleResolvers<ContextType>;
  SendUnregisteredMembersNotificationResponse?: SendUnregisteredMembersNotificationResponseResolvers<ContextType>;
  Settings?: SettingsResolvers<ContextType>;
  SmsMessage?: SmsMessageResolvers<ContextType>;
  SocialMediaAuth?: SocialMediaAuthResolvers<ContextType>;
  SocialMediaConnection?: SocialMediaConnectionResolvers<ContextType>;
  SocialMediaShareArticleSettings?: SocialMediaShareArticleSettingsResolvers<ContextType>;
  SocialMediaShareSettings?: SocialMediaShareSettingsResolvers<ContextType>;
  Sponsorship?: SponsorshipResolvers<ContextType>;
  StripeAccount?: StripeAccountResolvers<ContextType>;
  StripeAvailableBalance?: StripeAvailableBalanceResolvers<ContextType>;
  StripeConfig?: StripeConfigResolvers<ContextType>;
  StripeConfigField?: StripeConfigFieldResolvers<ContextType>;
  StripeExternalAccount?: StripeExternalAccountResolvers<ContextType>;
  SummarizedResponses?: SummarizedResponsesResolvers<ContextType>;
  Team?: TeamResolvers<ContextType>;
  TermsAndConditions?: TermsAndConditionsResolvers<ContextType>;
  TermsAndConditionsData?: TermsAndConditionsDataResolvers<ContextType>;
  TicketTypePublic?: TicketTypePublicResolvers<ContextType>;
  TicketWithLineItems?: TicketWithLineItemsResolvers<ContextType>;
  TimeMetadataPublic?: TimeMetadataPublicResolvers<ContextType>;
  TransactionDetailsResponse?: TransactionDetailsResponseResolvers<ContextType>;
  TransactionsCountResponse?: TransactionsCountResponseResolvers<ContextType>;
  TwilioConfig?: TwilioConfigResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserAutocompleteItem?: UserAutocompleteItemResolvers<ContextType>;
  UserContribution?: UserContributionResolvers<ContextType>;
  UserDetails?: UserDetailsResolvers<ContextType>;
  WinnerTicketWithCombination?: WinnerTicketWithCombinationResolvers<ContextType>;
  WinnerType?: WinnerTypeResolvers<ContextType>;
};

